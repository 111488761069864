import React, { useEffect, useRef, useState } from "react";
import Api from "../../Api";
import Header from "../header/Header";
import DashboardControlBar from "./DashboardControlBar";
import { useTranslation } from "react-i18next";
import DashboardPercentage from "./stats/DashboardPercentage";
import CalculateChartData from "./line_chart/CalculateChartData";
import DashboardBars from "./stats/DashboardBars";
import DashboardTops from "./stats/DashboardTops";
import LoadingComponent from "../default_components/LoadingComponent";
import { DateTime } from "luxon";
import lsKeys from "../default_values/defaultKeys";
import GetActiveUsersRequest from "../planner/requests/GetActiveUsersRequest";
import GetActiveProjects from "../planner/requests/GetActiveProjects";
import GetTagsRequest from "../planner/requests/GetTagsRequest";
import { useTeambookFilter } from "../../stores/planner";
import GetClientsRequest from "../planner/requests/GetClientsRequest";
import { ThemeContext } from "../../contexts/ThemeContext";
import { useDashboardStore } from "../../stores/dashboard";
import GraphRangeSwitch from "./line_chart/GraphRangeSwitch";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../stores/accountStore";
import { useProfileStore } from "../../stores/profileStore";
import DidYouKnow from "../planner/default_components/DidYouKnow";

/*
RANGE:
1 - 1 month
2 - 3 month
3 - 1 year
*/

const calculateFromDate = () => {
  const isDateValid = DateTime.fromISO(localStorage.getItem(lsKeys.DASHBOARD_FROM)).isValid;

  if (isDateValid) {
    return {
      start: DateTime.fromISO(localStorage.getItem(lsKeys.DASHBOARD_FROM))?.startOf("month"),
      end: DateTime.fromISO(localStorage.getItem(lsKeys.DASHBOARD_FROM))?.endOf("month"),
    };
  } else {
    localStorage.setItem(lsKeys.DASHBOARD_FROM, DateTime.now().toISODate());

    return {
      start: DateTime.now().startOf("month"),
      end: DateTime.now().endOf("month"),
    };
  }
};

export default () => {
  const { t } = useTranslation();
  const dashboardViews = [
    { value: "default", name: t("header.planning") },
    { value: "capacity", name: t("header.capacity") },
    { value: "actuals", name: t("header.actuals") },
    { value: "compare", name: t("dashboard.compare") },
  ];

  const [stats, setStats] = useState();
  const [dateRange, setDateRange] = useState(calculateFromDate());
  const [type, setType] = useState(
    localStorage.getItem(lsKeys.LAST_OPENED_DASHBOARD) || useDashboardStore.getState().dashboardType || "default"
  );
  const [zoom, setZoom] = useState();
  const [selectedTeamsIds, setSelectedTeamsIds] = useState();
  const [allTeams, setAllTeams] = useState();
  const [loading, setLoading] = useState(false);
  const [clientWidth, setDocumentWidth] = useState(document.body.clientWidth);
  const [version, setVersion] = useState(
    clientWidth < 640 ? "mobile" : (clientWidth >= 640 && clientWidth) < 1023 ? "tablet" : "desktop"
  );
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [view, setView] = useState(
    dashboardViews.find((dashboard_view) => dashboard_view.value === type).value || "default"
  );
  const [graphRange, setGraphRange] = useState(localStorage.getItem(lsKeys.DASHBOARD_GRAPH_ZOOM) || "daily");

  const { filterValues, filterType } = useTeambookFilter();
  let chartRef = React.createRef();
  const didYouKnowRef = useRef({});
  const dashboardDateKey = view === "default" ? lsKeys.DASHBOARD_FROM : lsKeys.LAST_ACTUAL_DASHBOARD_DATE;

  window.addEventListener("resize", () => setDocumentWidth(window.innerWidth));

  const [account] = useAccountStore((state) => [state.account], shallow);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  useEffect(() => {
    document.body.clientWidth < 640
      ? setVersion("mobile")
      : (document.body.clientWidth >= 640 && document.body.clientWidth) <= 1100
      ? setVersion("tablet")
      : setVersion("desktop");
  }, [clientWidth]);

  useEffect(() => {
    GetActiveUsersRequest(setUsers);
    GetActiveProjects(setProjects);
    GetTagsRequest(setTags);
    GetClientsRequest(setClients);
  }, []);

  useEffect(() => {
    let newRange = localStorage.getItem(lsKeys.DASHBOARD_RANGE) || 1;
    let fromDate = DateTime.now().startOf("month");

    if (type === "actuals") {
      fromDate = DateTime.now().startOf("month").minus({ month: 1 });
    }

    if (localStorage.getItem(dashboardDateKey) === "null") {
      localStorage.setItem(dashboardDateKey, DateTime.now().toISODate());
    }

    if (localStorage.getItem(dashboardDateKey)) {
      fromDate = DateTime.fromISO(localStorage.getItem(dashboardDateKey)).startOf("month");
    }

    if (type !== "actuals") {
      localStorage.setItem(dashboardDateKey, fromDate.toISODate());
    }
    localStorage.setItem(lsKeys.DASHBOARD_RANGE, newRange);
    setZoom(newRange);

    setDateRange({
      start: fromDate,
      end: dateRangeForType(fromDate, zoom || newRange).end,
    });
  }, []);

  useEffect(() => {
    if (dateRange && selectedTeamsIds) {
      setLoading(true);

      let fromDate = dateRange.start;
      let toDate = dateRange.end;

      if (+zoom !== 4) {
        fromDate = dateRangeForType(dateRange.start, zoom).start;
        toDate = dateRangeForType(dateRange.start, zoom).end;
      }

      if (view === "compare") {
        Promise.all([
          Api.Dashboard.stats(
            fromDate.toISODate(),
            toDate.toISODate(),
            selectedTeamsIds,
            filterValues,
            "default",
            graphRange
          ),
          Api.Dashboard.stats(
            fromDate.toISODate(),
            toDate.toISODate(),
            selectedTeamsIds,
            filterValues,
            "actuals",
            graphRange
          ),
        ]).then((response) => {
          setLoading(false);
          setStats([response[0].data, response[1].data]);
        });
      } else {
        Api.Dashboard.stats(fromDate.toISODate(), toDate.toISODate(), selectedTeamsIds, filterValues, view, graphRange)
          .then((res) => {
            setStats([res.data]);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }

      localStorage.setItem(lsKeys.DASHBOARD_RANGE, zoom);

      if (view !== "actuals") {
        localStorage.setItem(dashboardDateKey, fromDate.toISODate());
      }
    }
  }, [dateRange, zoom, selectedTeamsIds, filterValues, graphRange, view]);

  useEffect(() => {
    Api.Teams.all().then((res) => {
      setAllTeams(res.data);
      setSelectedTeamsIds(res.data.map(({ id }) => id));
    });
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      CalculateChartData(chartRef, stats, dateRange.start, view, t, graphRange);
    }
  }, [chartRef]);

  useEffect(() => {
    localStorage.setItem(lsKeys.LAST_OPENED_DASHBOARD, view);
  }, [view]);

  useEffect(() => {
    if (view !== "capacity") {
      localStorage.setItem(lsKeys.DASHBOARD_GRAPH_ZOOM, graphRange);
    }
  }, [graphRange]);

  useEffect(() => {
    if (view === "capacity") {
      setGraphRange("man_days");
    } else {
      setGraphRange(localStorage.getItem(lsKeys.DASHBOARD_GRAPH_ZOOM) || "daily");
    }
  }, [view]);

  const dateRangeForType = (fromDate, range) => {
    switch (+range) {
      case 1:
        return {
          start: fromDate.startOf("month"),
          end: fromDate.endOf("month"),
        };
      case 2:
        return {
          start: fromDate.startOf("month"),
          end: fromDate.plus({ months: 2 }).endOf("month"),
        };
      case 3:
        return {
          start: fromDate.startOf("month"),
          end: fromDate.plus({ months: 11 }).endOf("month"),
        };
      case 4:
        return {
          start: fromDate.startOf("month"),
          end: fromDate.endOf("month"),
        };
      case 5:
        return {
          start: fromDate.startOf("month"),
          end: fromDate.plus({ months: 23 }).endOf("month"),
        };
      case 6:
        return {
          start: fromDate.startOf("month"),
          end: fromDate.plus({ months: 5 }).endOf("month"),
        };
      default:
        return {
          start: fromDate.startOf("month"),
          end: fromDate.endOf("month"),
        };
    }
  };

  const isLoaded = stats && dateRange && zoom;

  return (
    <>
      <Header didYouKnowRef={didYouKnowRef} />

      <>
        {dateRange && (
          <DashboardControlBar
            dateRange={dateRange}
            setDateRange={setDateRange}
            zoom={zoom}
            setZoom={setZoom}
            allTeams={allTeams}
            version={version}
            mobileMenuIsOpen={mobileMenuIsOpen}
            setMobileMenuIsOpen={setMobileMenuIsOpen}
            isLoaded={isLoaded && !loading}
            users={users}
            projects={projects}
            tags={tags}
            clients={clients}
            dashboardViews={dashboardViews}
            setView={setView}
            view={view}
            dateRangeForType={dateRangeForType}
          />
        )}

        {isLoaded && !loading ? (
          <>
            {view === "compare" && (
              <div className="compare-colors">
                <div className="compare-colors__color-wrapper">
                  <div
                    style={{
                      background: "var(--accent-dark)",
                      width: 12,
                      height: 12,
                      borderRadius: "50%",
                    }}
                  />
                  <p>{t("dashboard.planned")}</p>
                </div>
                <div className="compare-colors__color-wrapper">
                  <div
                    style={{
                      background: "var(--accent-light)",
                      width: 12,
                      height: 12,
                      borderRadius: "50%",
                    }}
                  />
                  <p>{t("dashboard.logged")}</p>
                </div>
              </div>
            )}

            <div
              style={{ top: view === "compare" ? 150 : 120 }}
              className={`dashboard-component container-fluid dashboard-type-${type}`}
            >
              <div className="dashboard__first-line">
                <DashboardPercentage version={version} stats={stats} type={view} />

                <DashboardTops type={view} stats={stats} />
              </div>

              <div className="dashboard__second-line">
                <div
                  className="dashboard__chart"
                  style={{
                    width: "calc(100% - 480px - 20px)",
                    position: "relative",
                    padding: "50px 20px 20px 20px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: 20,
                      top: 20,
                    }}
                  >
                    <p style={{ color: "var(--text-2)", fontWeight: 500 }}>{t("dashboard.utilization")}</p>
                  </div>

                  <div
                    className="chart__range-switcher"
                    style={{
                      position: "absolute",
                      display: "flex",
                      top: 27,
                    }}
                  >
                    <div id="legend-container" />

                    <div
                      style={{
                        width: 1,
                        height: 20,
                        background: "var(--stroke)",
                      }}
                    />

                    <GraphRangeSwitch graphRange={graphRange} setGraphRange={setGraphRange} type={view} />
                  </div>

                  <canvas id="myChart" ref={chartRef} />
                </div>

                {profile && <DidYouKnow ref={didYouKnowRef} />}

                <div className="dashboard-bars-container">
                  <DashboardBars stats={stats} type={view} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </>
    </>
  );
};
