import React from "react";
import Button from "@mui/material/Button";
import "./default_styles.scss";

const TeambookBlueButton = ({
  htmlText,
  text,
  onClick,
  style = {},
  className,
  id,
  disabled,
  borderRadius,
  textId,
  disabledAnimations,
}) => {
  //TODO: WTF is that??
  const inlineStyles = {
    height: style.height || "40px",
    minWidth: style.minWidth || "90px",
    width: style.width,
    borderRadius: borderRadius || "7px",
    color: style.color || "#FFF",
    position: style.position,
    bottom: style.bottom,
    right: style.right,
    top: style.top,
    left: style.left,
    zIndex: style.zIndex,
    marginTop: style.marginTop,
    flex: style.flex,
  };

  return (
    <Button
      disabled={disabled}
      className={`teambook-blue-button  ${className} ${disabledAnimations ? "no-animations" : ""}`}
      onClick={onClick}
      style={inlineStyles}
      id={id}
    >
      {htmlText && <>{htmlText}</>}
      {text && <p id={textId ? textId : ""}>{text}</p>}
    </Button>
  );
};

export default TeambookBlueButton;
