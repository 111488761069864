import React from "react";
import "../../../capacity_planning/capacity_planning.scss";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import { DateTime } from "luxon";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import TeambookSortingTypes from "../../../default_components/TeambookSortingTypes";
import { getWorkingDays } from "../../../../lib/DateUtils";

const DatesRow = ({
  date,
  showTimeOffs,
  setShowTimeOffs,
  exportData,
  copyPlanner,
  selectedViewType,
  windowWidth,
  showPercentage,
  setShowPercentage,
  percentageFormat,
  setPercentageFormat,
  splitBy,
  type = "planning",
  sortedDate = DateTime.now(),
  setSortedDate = () => {},
  sortType = "alphabetical",
  setSortType = () => {},
  setSortOrder = () => {},
  sortOrder = "a",
  sortData = false,
  setSplitBy = () => {},
}) => {
  const { t } = useTranslation();

  const percentageFormats = [
    {
      value: "all",
      label: t("planning.capacity.all_percentage"),
    },
    {
      value: "billable",
      label: t("planning.capacity.billable_capacity"),
    },
    {
      value: "non_billable",
      label: t("planning.capacity.non_billable_capacity"),
    },
    {
      value: "time_off",
      label: t("planning.capacity.time_off_capacity"),
    },
  ];

  const sameMonth = (index) => {
    return (
      date.plus({ months: index }).month === DateTime.now().month &&
      date.plus({ months: index }).year === DateTime.now().year
    );
  };

  const sortedMonth = (index) => {
    return (
      date.plus({ months: index }).month === sortedDate.month && date.plus({ months: index }).year === sortedDate.year
    );
  };

  const sortByMonth = (selectedDate, i) => {
    if (sortType === "by_month") {
      setSortedDate(selectedDate);

      if (sortedMonth(i)) {
        setSortOrder((pr) => (pr === "a" ? "z" : "a"));
      } else {
        setSortOrder("z");
      }
    } else {
      setSortType("by_month");
      setSortOrder("z");
      setSortedDate(selectedDate);
    }
  };

  return (
    <>
      <div className="reporting-block__dates-row" style={{ width: `${windowWidth}px` }}>
        <div
          style={{
            background: "var(--background-1)",
          }}
          className="left-side"
        >
          {sortData && (
            <TeambookSortingTypes
              setFilterOrder={setSortOrder}
              filterOrder={sortOrder}
              filterOptions={["alphabetical"]}
              style={{
                justifyContent: "flex-start",
                width: "fit-content",
                marginRight: 10,
              }}
              onClick={() => setSortType("alphabetical")}
            />
          )}

          {showTimeOffs !== undefined && (
            <div
              className="dashboard__tb-button"
              onClick={() => setShowTimeOffs(!showTimeOffs)}
              style={{ marginRight: "15px" }}
            >
              {showTimeOffs ? t("planning.capacity.show_time_off") : t("planning.capacity.hide_time_off")}
            </div>
          )}

          {copyPlanner && (
            <div className="dashboard__tb-button" onClick={copyPlanner}>
              {t("planning.copy_op_planning")}
            </div>
          )}

          {showPercentage !== undefined && (
            <>
              <WhiteTooltip
                title={showPercentage ? t("planning.capacity.show_man_days") : t("planning.capacity.show_percentage")}
              >
                <div className="dashboard__tb-button percentage" onClick={() => setShowPercentage(!showPercentage)}>
                  <p>{showPercentage ? "123" : "%"}</p>
                </div>
              </WhiteTooltip>
            </>
          )}

          {showPercentage && (
            <div
              style={{
                marginLeft: "20px",
                paddingRight: "16px",
                backgroundColor: "var(--background-1)",
              }}
            >
              <TeambookReactSelect
                defaultValue={percentageFormats.find((o) => o.value === percentageFormat)}
                options={percentageFormats}
                onChange={(value) => {
                  setPercentageFormat(value.value);
                }}
                height={40}
                width={180}
                maxMenuHeight={190}
                styles={{
                  singleValue: (base) => ({ ...base, color: "var(--text-2) !important" }),
                }}
              />
            </div>
          )}
        </div>

        <div className="flex right-side">
          {[...Array(selectedViewType)].map((x, i) => (
            <div
              className="table-cell"
              style={{
                height: 60,
                padding: 0,
              }}
            >
              <div className={`table-cell__content`}>
                {(date.plus({ months: i }).month === 1 ||
                  date.plus({ months: i }).month === 5 ||
                  date.plus({ months: i }).month === 9) && (
                  <p className="year">{date.plus({ months: i }).toFormat("yyyy")}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className="reporting-block__dates-row drilled-down"
        style={{
          top: "60px",
          width: `${windowWidth}px`,
          height: 36,
        }}
      >
        <div className="left-side" style={{ borderRight: "1px solid var(--stroke)" }}>
          <div />
        </div>

        <div
          style={{
            height: 36,
          }}
          className="flex right-side"
        >
          {[...Array(selectedViewType)].map((x, i) => (
            <div
              style={{
                height: 36,
                cursor: "pointer",
                borderRight: date.plus({ months: i }).month === 12 && "1px solid var(--stroke)",
              }}
              onClick={() => sortByMonth(date.plus({ month: i }), i)}
              onMouseEnter={(e) => e.target.classList.add("table-cell__hovered")}
              onMouseLeave={(e) => e.target.classList.remove("table-cell__hovered")}
              className={`table-cell ${sortedMonth(i) && sortType === "by_month" ? "sorted-month" : ""} ${
                sameMonth(i) ? "current-month" : ""
              }`}
            >
              <div>
                <p>{date.plus({ months: i }).toFormat("LLL")}</p>
                <p style={{ fontSize: 11 }}>
                  ({getWorkingDays(date.year, date.plus({ months: i }).month)}
                  d)
                </p>
              </div>

              <TeambookIcon
                className="reporting__sorting-arrow"
                style={{
                  transform:
                    sortOrder === "a" && sortType === "by_month" && sortedMonth(i) ? "rotate(180deg)" : "unset",
                  display: sortedMonth(i) && sortType === "by_month" ? "block" : "none",
                }}
                name={icons.LONG_ARROW}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DatesRow;
