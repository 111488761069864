import lsKeys from "../components/default_values/defaultKeys";

const siteOffsetX = () => {
  return localStorage.getItem(lsKeys.LAST_PLANNER_TYPE) === "grouped_planners" ? 155 : 150;
};

const siteOffsetY = (zoom) => {
  return 188;
};

const getPlannerOffsetX = (e) => {
  return e.clientX - siteOffsetX() + document.getElementById("planner-grid")?.scrollLeft || 0;
};

const getPlannerOffsetY = (e, zoom) => {
  return e.clientY - siteOffsetY(zoom) + document.getElementById("planner-grid")?.scrollTop || 0;
};

const updateValueWithId = (id, array, newValue) => {
  let newArray = new Array(...array);
  let objIndex = array.findIndex((obj) => obj.id === id);

  newArray[objIndex] = newValue;

  return newArray;
};

const usersMaxScheduleTime = (user) => {
  const userSchedule = user?.schedule.map((schedule) => schedule[0] + schedule[2]);

  return Math.max(...userSchedule);
};

const usersWorkingScheduleSum = (user) => {
  if (!user) {
    return 0;
  }

  const userSchedule = user?.schedule.map((schedule) => schedule[0] + schedule[2]);

  return userSchedule.reduce((pr, cv) => pr + cv, 0);
};

const DAY_VIEW_HEIGHT = 80;
const DAY_VIEW_WIDTH = 100;
const DAY_VIEW_START_HOUR = 7;
const PLANNER_USER_WIDTH = 120;
const GROUPED_PLANNER_USER_WIDTH = 160;

export {
  getPlannerOffsetX,
  getPlannerOffsetY,
  updateValueWithId,
  DAY_VIEW_HEIGHT,
  DAY_VIEW_WIDTH,
  DAY_VIEW_START_HOUR,
  PLANNER_USER_WIDTH,
  GROUPED_PLANNER_USER_WIDTH,
  usersMaxScheduleTime,
  usersWorkingScheduleSum,
};
