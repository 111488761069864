import React, { useState } from "react";
import { useNotificationStore } from "../../stores/notificationStore";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";
import TeambookOutlinedButton from "./TeambookOutlinedButton";
import TeambookBlueButton from "./TeambookBlueButton";
import lsKeys from "../default_values/defaultKeys";

//TODO: Finish Update Teambook work.
const UpdateNotification = () => {
  const [inAppNotification] = useNotificationStore((state) => [state.inAppNotification], shallow);
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();

  const closePopup = () => {
    setIsOpen(false);
  };

  const onRefreshClickHandler = () => {
    window.location.reload();
  };

  return (
    !isOpen && (
      <div
        lang={localStorage.getItem(lsKeys.LANGUAGE)}
        style={{ bottom: inAppNotification ? "17.5%" : "5%" }}
        className="mobile_hidden notification-container update-notification"
      >
        <p className="notification-container__header">{t("profile.notifications.update_notification_header")}</p>
        <p className="notification-container__message">{t("profile.notifications.update_notification_message")}</p>

        <div className="notification-container__actions">
          <TeambookOutlinedButton
            style={{ color: "var(--text-2) !important" }}
            onClick={closePopup}
            text={t("dismiss")}
          />
          <TeambookBlueButton onClick={onRefreshClickHandler} text={t("refresh")} />
        </div>
      </div>
    )
  );
};

export default UpdateNotification;
