import { utils, write } from "xlsx/xlsx.mjs";
import * as FileSaver from "file-saver";
import Api from "../Api";
import moment from "moment";
import { DateTime } from "luxon";

const removeHTMLfromStr = (str) => {
  if (str === null || str === "") return null;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, " ");
};

const XLSExport = (fileName, exportingData) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = utils.aoa_to_sheet(exportingData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName + fileExtension);
};

const XLSExportObject = (fileName, exportingData) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = utils.json_to_sheet(exportingData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName + fileExtension);
};

const XLSExportMultipleObject = (fileName, exportDataArray) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const wb = utils.book_new();

  exportDataArray.forEach((exportData) => {
    utils.book_append_sheet(wb, utils.json_to_sheet(exportData.data), exportData.sheetName);
  });

  const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName + fileExtension);
};

const ExportUsers = (selectedUsers) => {
  Api.Users.export(selectedUsers.map(({ id }) => id)).then((response) => {
    XLSExportObject("[TB] Users export", response.data);
  });
};

const ExportProjects = (selectedProjects) => {
  Api.Projects.export(selectedProjects.map(({ id }) => id)).then((response) => {
    XLSExportObject("[TB] Projects export", response.data);
  });
};

const ExportVat = (data) => {
  XLSExportObject("[TB] Vat export", data);
};

const ExportBookings = (params) => {
  Api.Bookings.export(params).then((response) => {
    if (response.data.length) {
      response.data.forEach((el) => {
        if (el["Booking Notes"]) {
          el["Booking Notes"] = removeHTMLfromStr(el["Booking Notes"]);
        }
      });
      XLSExportObject("[TB] Bookings export", response.data);
    } else {
      params.setEmptyOpened(true);
    }
  });
};

const ExportActuals = (params) => {
  Api.ActualLogs.export(params).then((response) => {
    if (response.data.length) {
      XLSExportObject("[TB] Actual logs export", response.data);
    } else {
      params.setEmptyOpened(true);
    }
  });
};

const ExportBookingsAndLogs = (params) => {
  Promise.all([Api.Bookings.export(params), Api.ActualLogs.export(params)]).then((response) => {
    if (response[0].data.length || response[1].data.length) {
      response[0].data.forEach((el) => {
        if (el["Booking Notes"]) {
          el["Booking Notes"] = removeHTMLfromStr(el["Booking Notes"]);
        }
      });

      const exportData = [
        {
          sheetName: "Bookings",
          data: response[0].data,
        },
        {
          sheetName: "Actual Logs",
          data: response[1].data,
        },
      ];

      XLSExportMultipleObject("[TB] Bookings & Logs export", exportData);
    } else {
      params.setEmptyOpened(true);
    }
  });
};

const ExportCapacity = (params) => {
  Api.CapacityReservations.export_data(params).then((res) => {
    const exportData = [
      {
        sheetName: "Projects Capacity",
        data: res.data.project_capacity,
      },
      {
        sheetName: "Users Capacity",
        data: res.data.users_capacity,
      },
    ];

    XLSExportMultipleObject("[TB] Capacity Export", exportData);
  });
};

const ExportUploadingProjectsFile = () => {
  const defaultExcelData = [
    {
      "Project Name": "Project 1",
      "Project Code": "PROJ1",
      "Project Type": "Billable",
      "Client Name": "Client 1",
      "Start Date (DD/MM/YYYY)": DateTime.now().startOf("month").toFormat("dd/MM/yyyy"),
      "End Date (DD/MM/YYYY)": DateTime.now().endOf("month").toFormat("dd/MM/yyyy"),
      Estimated: "1000",
    },
    {
      "Project Name": "Project 2",
      "Project Code": "PROJ2",
      "Project Type": "Non-billable",
      "Client Name": "Client 2",
      "Start Date (DD/MM/YYYY)": DateTime.now().endOf("month").toFormat("dd/MM/yyyy"),
      "End Date (DD/MM/YYYY)": DateTime.now().endOf("month").plus({ days: 56 }).toFormat("dd/MM/yyyy"),
    },
    {
      "Project Name": "Project 3",
      "Project Code": "PROJ3",
      "Project Type": "Non-billable",
      "Client Name": "",
    },
    {
      "Project Name": "Project 4",
      "Project Code": "PROJ4",
      "Project Type": "Time-off",
      "Client Name": "",
    },
  ];

  XLSExportObject("[TB] Projects upload", defaultExcelData);
};

const ExportUploadingUsersFile = () => {
  const defaultExcelData = [
    {
      "First Name": "Adam",
      "Last Name": "Gregory",
      Email: `gregory${Math.floor(Math.random() * 999999)}@teambook.com`,
      Role: "admin",
      Analytics: "Billable",
      Phone: "+4332256",
      "Start Date": "25-08-2024",
      "End Date": "13-02-2025",
    },
    {
      "First Name": "James",
      "Last Name": "Davidson",
      Email: `james${Math.floor(Math.random() * 999999)}@teambook.com`,
      Role: "planner",
      Analytics: "Non-billable",
    },
    {
      "First Name": "Kate",
      "Last Name": "Jefferson",
      Email: `kate${Math.floor(Math.random() * 999999)}@teambook.com`,
      Analytics: "Non-billable",
    },
  ];

  XLSExportObject("[TB] Users upload", defaultExcelData);
};

//TODO: make dates floating
const exportUploadingActualsFile = () => {
  const defaultExcelData = [
    {
      "User Email": "jim.halpert@teambookapp.com",
      "Project Name": "Advisory QB",
      Duration: 3.5,
      Date: "22-12-2023",
      "Service Name": "Performance Testing",
      "Payroll Item": "Senior System Architect",
    },
    {
      "User Email": "michael.scott@teambookapp.com",
      "Project Name": "Microsoft Support",
      Duration: 8,
      Date: "29-12-2023",
      "Service Name": "",
      "Payroll Item": "",
    },
    {
      "User Email": "dwight.schrute@teambookapp.com",
      "Project Name": "Microsoft Support",
      Duration: 2,
      Date: "09-12-2023",
      "Service Name": "",
      "Payroll Item": "",
    },
  ];

  XLSExportObject("[TB] Actual logs upload", defaultExcelData);
};

const exportReporting = (kind, startDate, endDate, data) => {
  const KIND_MAPPING = {
    user: "Users Planning",
    project: "Projects Planning",
    actuals_user: "Users Actuals",
    actuals_project: "Projects Actuals",
    task: "Tasks Planning",
    capacity_user: "User Capacity",
    capacity_project: "Project Capacity",
    capacity_tag: "Tag Capacity",
    comparison_user: "Comparison Users",
    comparison_project: "Comparison Projects",
    comparison_task: "Comparison Tasks",
    overview: "Overview",
    sat_overview: "Satriun Overview",
  };

  const exportData = data.map((pieceOfData) => ({
    sheetName: pieceOfData.sheet_title,
    data: pieceOfData.xls_data,
  }));

  XLSExportMultipleObject(
    `[TB] Reporting ${KIND_MAPPING[kind]} ${startDate.toFormat("dd.MM.yy")} - ${endDate.toFormat("dd.MM.yy")}`,
    exportData
  );
};

const exportLogs = (data) => {
  XLSExportObject(`[TB] Actual logs export ${moment(new Date()).format("DD.MM.YY")}`, data);
};

const exportCapacityReporting = (type, data) => {
  XLSExport(`[TB] Capacity Reporting ${type} ${moment(new Date()).format("DD.MM.YY")}`, data);
};

export default {
  ExportUsers,
  ExportProjects,
  ExportBookings,
  ExportActuals,
  ExportBookingsAndLogs,
  ExportCapacity,
  ExportVat,
  ExportUploadingProjectsFile,
  ExportUploadingUsersFile,
  exportReporting,
  exportLogs,
  exportCapacityReporting,
  exportUploadingActualsFile,
};
