import React, { useState } from "react";
import NewProject from "./NewProject/NewProject";
import ShowProject from "./ShowProject/ShowProject";
import EditProject from "./EditProject/EditProject";
import { useTranslation } from "react-i18next";

const ProjectForm = ({
  page,
  project,
  projects,
  onEdit,
  activeClients,
  closeEditing,
  closeCreation,
  getProjects,
  getActiveProjects,
  closeShow,
  setChosenProject,
  deleteProject,
  setAllClients,
  users,
  copyCalendarURL,
  reactivateProject,
  archivedSelect,
  setProjectsFormState,
}) => {
  const { t } = useTranslation();

  const projectTabs = [{ value: "general" }, { value: "tasks" }];

  const [tab, setTab] = useState(projectTabs[0]);

  if (page === "create" || page === "time_off_create") {
    return (
      <NewProject
        clients={activeClients}
        closeCreation={closeCreation}
        projects={projects}
        getActiveProjects={getActiveProjects}
        page={page}
        setAllClients={setAllClients}
        users={users}
        tab={tab}
        setTab={setTab}
        setPage={setProjectsFormState}
      />
    );
  } else if (page === "show") {
    return (
      <ShowProject
        project={project}
        onEdit={onEdit}
        closeShow={closeShow}
        page={page}
        deleteProject={deleteProject}
        users={users}
        copyCalendarURL={copyCalendarURL}
        reactivateProject={reactivateProject}
        archivedSelect={archivedSelect}
        tab={tab}
        setTab={setTab}
        setPage={setProjectsFormState}
        getProjects={getProjects}
      />
    );
  } else if (page === "edit") {
    return (
      <EditProject
        project={project}
        clients={activeClients}
        closeEditing={closeEditing}
        getProjects={getProjects}
        setChosenProject={setChosenProject}
        closeShow={closeShow}
        page={page}
        setAllClients={setAllClients}
        users={users}
        tab={tab}
        setTab={setTab}
        setPage={setProjectsFormState}
      />
    );
  }

  return <div />;
};

export default ProjectForm;
