import React from "react";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

const AddProjectPlusButton = ({ handleClickOpen, disabled, className }) => {
  const { t } = useTranslation();

  const disableButton = () => {
    return {
      opacity: disabled && "0.4",
      pointerEvents: disabled && "none",
    };
  };

  return (
    <WhiteTooltip title={t("planning.add_projects")}>
      <div
        className={`add-user-row ${className}`}
        style={{
          border: disabled ? "1px solid var(--stroke-transparent)" : "1px solid var(--stroke)",
          pointerEvents: disabled && "none",
        }}
        onClick={handleClickOpen}
      >
        <div className="add-user-row__add-button">
          <TeambookIcon
            style={disableButton()}
            className="add-user-row__add-icon"
            alt={t("planning.add_projects")}
            name={icons.ADD_PLUS}
            color="darkblue_new"
          />
        </div>
      </div>
    </WhiteTooltip>
  );
};

export default AddProjectPlusButton;
