import React, { useEffect, useState } from "react";
import "./header_modals.scss";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import { DateTime } from "luxon";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const WhatsNewModal = ({ closeWindow }) => {
  const { t } = useTranslation();
  const language = localStorage.getItem("tb-lang") || "en";
  const [whatsNew, setWhatsNew] = useState([]);

  useEffect(() => {
    Api.Administrators.get_whats_new(language).then((response) => {
      setWhatsNew(response.data);
    });
  }, []);

  return (
    <Dialog className="tb-whats-new-dialog" onClose={closeWindow} open>
      <DialogTitle>
        <p className="whats-new__header">{t("header.whats_new")}</p>
        <TeambookIcon name={icons.CROSS} onClick={closeWindow} />
      </DialogTitle>

      <DialogContent className="planner-settings-dialog__content">
        {whatsNew.map((whatsNewElement) => (
          <div className="whats-new-container">
            <div className="whats-new-element">
              <p className="title">{whatsNewElement.title}</p>
              <p className="date">{DateTime.fromISO(whatsNewElement.created_at).toFormat("d MMM yyyy")}</p>
            </div>
            <p className="description">{whatsNewElement.description}</p>
          </div>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default WhatsNewModal;
