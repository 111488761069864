import React, { useEffect, useState } from "react";
import WeekRow from "./WeekRow";
import DayRow from "./DayRow";
import DataRows from "./DataRows";
import BottomTotal from "./BottomTotal";
import { useTranslation } from "react-i18next";
import Api from "../../../../../Api";
import LoadingComponent from "../../../../default_components/LoadingComponent";
import { sortDataBySurname } from "../../lib/sortDataBySurname";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const splitTypeMapping = {
  comparison_project: "project",
  comparison_user: "user",
  comparison_task: "task",
};

const CompareReporting = ({ from, sortOrder, setSortOrder, splitBy, selectedTeams, alphabeticalSort, isTimeOff }) => {
  const { t } = useTranslation();
  const [stats, setStats] = useState({
    planningData: [],
    actualsData: [],
  });
  const [loading, setLoading] = useState(true);
  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    if (!loading && stats.actualsData.data.length && stats.planningData.data.length) {
      setStats((prev) => ({
        planningData: sortData(prev.planningData),
        actualsData: sortData(prev.actualsData),
      }));
    }
  }, [splitBy, sortOrder, loading]);

  useEffect(() => {
    if (selectedTeams) {
      const compareDataRequests = [
        Api.Dashboard.split(
          from.start.toISODate(),
          from.end.toISODate(),
          selectedTeams.map(({ id }) => id),
          splitTypeMapping[splitBy],
          "default",
          "default"
        ),
        Api.Dashboard.split(
          from.start.toISODate(),
          from.end.toISODate(),
          selectedTeams.map(({ id }) => id),
          splitTypeMapping[splitBy],
          "actuals",
          "default"
        ),
      ];

      Promise.all(compareDataRequests).then((response) => {
        setStats({
          planningData: sortData(response[0].data),
          actualsData: sortData(response[1].data),
        });
        setLoading(false);
      });
    }
  }, [from, selectedTeams, splitBy]);

  const sortData = (statsData) => {
    if (statsData.users) {
      return;
    }

    switch (splitBy) {
      case "comparison_project":
        return sortProjectData(statsData);
      case "comparison_user":
        return sortUserData(statsData);
      case "comparison_task":
        return sortProjectData(statsData);
      default:
        return statsData;
    }
  };

  const sortProjectData = (statsData) => {
    let time_off_projects = [];
    let non_time_off_projects = [];

    statsData.data.forEach((project) => {
      isTimeOff(project) ? time_off_projects.push(project) : non_time_off_projects.push(project);
    });

    return {
      ...statsData,
      data: [
        ...alphabeticalSort([...non_time_off_projects], sortOrder),
        ...alphabeticalSort([...time_off_projects], sortOrder),
      ].map((project) => {
        return {
          ...project,
          daily_entities: sortDataBySurname(project.daily_entities),
        };
      }),
    };
  };

  const sortUserData = (statsData) => {
    return {
      ...statsData,
      data: sortDataBySurname(
        [...statsData.data].map((project) => {
          return {
            ...project,
            daily_entities: alphabeticalSort(project.daily_entities, "a"),
          };
        }),
        sortOrder
      ),
    };
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="reporting__table-wrapper">
      <div className="reporting__table-content">
        <table className="compare-reporting-table">
          <WeekRow setSortOrder={setSortOrder} sortOrder={sortOrder} from={from} stats={stats} />

          <DayRow stats={stats} />

          <DataRows stats={stats.planningData} splitBy={splitBy} additionalData={stats.actualsData} />

          <BottomTotal stats={stats.planningData} additionalData={stats.actualsData} />
        </table>
      </div>
    </div>
  );
};

export default CompareReporting;
