import React from "react";
import "./projection.scss";
import logo from "./projection_logo/logo.svg";
import textLogo from "./projection_logo/textLogo.svg";

const ProjectionHeader = ({ item, isClient, isProject }) => {
  return (
    <div className="projection__header">
      <a
        className="mobile_hidden projection__logo"
        href="https://teambookapp.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="logo" src={textLogo} />
        <img alt="logo" className="" src={logo} />
      </a>

      <div className="projection__header-title">
        {isClient && item && <p>Client - {item?.client_name}</p>}

        {isProject && (
          <>
            <div className="projection-header__circle" style={{ backgroundColor: item.color }} />
            <p>
              Project - [{item.code}] {item.name}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectionHeader;
