import { useEffect, useRef, useState } from "react";
import { icons } from "../default_images/IconsList";
import { TeambookIcon } from "../default_images/TeambookIcon";
import lsKeys from "../default_values/defaultKeys";
import { ClickAwayListener } from "@mui/material";
import { DateTime, Info } from "luxon";

export const TeambookMonthPicker = ({ currentDate, setCurrentDate, width }) => {
  const lang = localStorage.getItem(lsKeys.LANGUAGE);
  const containerRef = useRef();
  const currentDateRef = useRef();

  const [displayedDate, setDisplayedDate] = useState(currentDate.startOf("month"));
  const [pickerOpened, setMonthPickerOpened] = useState(false);
  const [yearSelectOpened, setYearSelectOpened] = useState(false);

  const openMonthPicker = () => setMonthPickerOpened(true);

  const closeMonthPicker = () => setMonthPickerOpened(false);

  const closeYearPicker = () => {
    setYearSelectOpened(false);
  };

  const selectMonth = (date) => {
    setCurrentDate(date);
  };

  const months = Info.months("short", { locale: lang });

  const currentYear = DateTime.now().year;

  const years = [currentYear - 1, currentYear, currentYear + 1, currentYear + 2];

  const openYearSelect = () => {
    setYearSelectOpened(true);
  };

  useEffect(() => {
    setDisplayedDate(currentDate.startOf("month"));
  }, [currentDate]);

  const calculatePopupPos = () => {
    const { x, y } = containerRef.current.getBoundingClientRect();
    return {
      top: y - 15,
      left: x - 160,
    };
  };

  const calculateYearPickerPos = () => {
    const { x, y } = currentDateRef.current.getBoundingClientRect();
    return {
      top: y - 40,
      left: x + 25,
    };
  };

  const selectYear = (year) => {
    setDisplayedDate(displayedDate.set({ year: year }));
  };

  return (
    <ClickAwayListener onClickAway={closeMonthPicker}>
      <div style={{ width: width }} ref={containerRef} className="date-picker-container month-picker">
        <div ref={containerRef} onClick={openMonthPicker} className="arrow-block">
          <TeambookIcon
            alt="arrow-left"
            className="arrow-block__arrow-left"
            color="darkblue_new"
            style={{ padding: "6px 9px" }}
            name={icons.SMALL_ARROW_LEFT}
            onClick={(e) => {
              setCurrentDate(currentDate.minus({ month: 1 }));
              e.stopPropagation();
            }}
          />

          <p className="month-picker__current-date">{currentDate.setLocale(lang).toFormat("MMM y")}</p>

          {yearSelectOpened && (
            <ClickAwayListener onClickAway={closeYearPicker}>
              <div style={calculateYearPickerPos()} className="month-picker__year-selector">
                {years.map((y) => {
                  const isYearSelected = displayedDate.year === y;
                  return (
                    <p
                      onClick={() => selectYear(y)}
                      className={`month-picker__year ${isYearSelected ? "selected" : ""}`}
                    >
                      {y}
                    </p>
                  );
                })}
              </div>
            </ClickAwayListener>
          )}

          {pickerOpened && (
            <div style={calculatePopupPos()} className="month-picker__picker-container">
              <div ref={currentDateRef} onClick={openYearSelect} className="month-picker__picker-header">
                <p>{displayedDate.setLocale(lang).toFormat("MMMM y")}</p>
              </div>

              <div className="month-picker__picker-months">
                {months.map((month, i) => {
                  const currentMonth = displayedDate.startOf("year").plus({
                    month: i,
                  });

                  return (
                    <div
                      onClick={() => selectMonth(currentMonth)}
                      className={`month-picker__picker-month ${
                        currentDate.startOf("month").toISODate() === currentMonth.startOf("month").toISODate()
                          ? "selected"
                          : ""
                      }`}
                    >
                      <p>{month}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <TeambookIcon
            className="arrow-block__arrow-right"
            alt="arrow-right"
            color="darkblue_new"
            name={icons.SMALL_ARROW_LEFT}
            style={{ transform: "rotate(-180deg)", padding: "6px 9px" }}
            onClick={(e) => {
              setCurrentDate(currentDate.plus({ month: 1 }));
              e.stopPropagation();
            }}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};
