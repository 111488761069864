import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import UploadUsersDialog from "./UploadUsersDialog";
import AddUser from "../../default_images/add_plus.svg";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ArrowDown from "../../default_images/white_arrow_bottom.svg";
import { ClickAwayListener } from "@mui/base";
import { shallow } from "zustand/shallow";
import { useUsersVisibilityColumnsStore } from "../../../stores/users";
import lsKeys from "../../default_values/defaultKeys";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import TeamSelect from "../../planner/default_components/TeamSelect";
import TeambookFilterComponent from "../../default_components/teambook_filter/TeambookFilterComponent";
import { defaultProjectIcons } from "../../default_values/DefaultProjectIcons";

const UsersFilterRow = ({
  users,
  setDeactivatedSelectUsers,
  openCreation,
  getAllUsers,
  openTagsWindow,
  deactivatedSelectUsers,
  teams,
  setTeams,
  tags = [],
}) => {
  const { t } = useTranslation();
  const [uploadDialogOpened, setUploadDialogOpened] = useState(false);
  const [uploadDropdownShow, setUploadDropdownShow] = useState(false);
  const [columnsSettingsOpened, setColumnsSettingsOpened] = useState(false);
  const [isTeamsDialogOpened, setIsTeamsDialogOpened] = useState(false);

  const columnsAnchorRef = useRef(null);

  const [columnsVisibility, setColumnsVisibility] = useUsersVisibilityColumnsStore(
    (state) => [state.columnsVisibility, state.setColumnsVisibility],
    shallow
  );

  useEffect(() => {
    if (localStorage.getItem(lsKeys.USERS_COLUMNS_DISPLAY)) {
      setColumnsVisibility(JSON.parse(localStorage.getItem(lsKeys.USERS_COLUMNS_DISPLAY)));
    } else {
      const defaultData = {
        email: true,
        role: true,
        billable: true,
        teams: true,
        tags: true,
        start_date: false,
        end_date: false,
      };
      setColumnsVisibility(defaultData);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(lsKeys.USERS_COLUMNS_DISPLAY, JSON.stringify(columnsVisibility));
  }, [columnsVisibility]);

  const switchDropdownShow = () => {
    setUploadDropdownShow(!uploadDropdownShow);
  };

  const selectOptions = [
    { value: "Active", label: t("users.active") },
    { value: "Deactivated", label: t("users.deactivated") },
  ];

  const handleChangeDeactivate = (newValue) => {
    setDeactivatedSelectUsers(newValue.value);
  };

  const switchColumnsVisibility = (field) => {
    let oldObject = { ...columnsVisibility };
    oldObject[field] = !columnsVisibility[field];

    setColumnsVisibility(oldObject);
  };

  const openTeamsDialog = () => {
    setIsTeamsDialogOpened(true);
  };

  const closeDropdowns = () => {
    setUploadDropdownShow(false);
    setColumnsSettingsOpened(false);
  };

  return (
    <div className="filter-row">
      <div className="filter-row__left-side">
        <div className="planner-control__filter-text-field users-page-filter mobile_hidden">
          <TeambookFilterComponent filterPage="usersPage" users={users} teams={teams} tags={tags} projects={[]} />
        </div>

        <div className="deactivate-switch">
          {selectOptions.map((value) => (
            <div
              onClick={() => handleChangeDeactivate(value)}
              className={`${
                value.value === deactivatedSelectUsers ? "active" : ""
              } active-entity-switch__option pointer`}
            >
              <p>{value.label}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="filter-row__right-side">
        <div
          className="manage-clients-button mobile_hidden"
          onClick={openTeamsDialog}
          title={t("planning.manage_teams")}
        >
          <div className="filter-row__button-caption">
            <TeambookIcon
              name={icons.TEAM_USERS}
              tooltipTitle={t("users.selected_fields.teams")}
              color="darkblue_new"
            />
          </div>
        </div>

        <div className="manage-clients-button mobile_hidden" onClick={openTagsWindow} title={t("users.manage_tags")}>
          <div className="filter-row__button-caption">
            <TeambookIcon tooltipTitle={t("users.selected_fields.tags")} name={icons.TAGS} color="darkblue_new" />
          </div>
        </div>

        <div
          className="columns-settings-button mobile_hidden"
          onClick={() => {
            setColumnsSettingsOpened(true);
          }}
          style={{ marginRight: 28 }}
        >
          <div ref={columnsAnchorRef} className="planner-control-panel__setting-icon">
            <TeambookIcon
              id="settings"
              name={icons.EYE_OPENED}
              tooltipTitle={t("planning.control_panel.visual_settings")}
              color="darkblue_new"
            />
          </div>

          {columnsSettingsOpened && (
            <ClickAwayListener onClickAway={closeDropdowns}>
              <div
                style={{
                  left: columnsAnchorRef.current.getBoundingClientRect().x - 50,
                  top: columnsAnchorRef.current.getBoundingClientRect().y - 25,
                }}
                className="control-bar__dropdown-fields"
              >
                {Object.keys(columnsVisibility).map((key) => (
                  <div className="columns-selection-option" key={`columns-setting-${key}`}>
                    <TeambookCheckbox checked={columnsVisibility[key]} onChange={() => switchColumnsVisibility(key)} />

                    <p onClick={() => switchColumnsVisibility(key)}>{t(`users.selected_fields.${key}`)}</p>
                  </div>
                ))}
              </div>
            </ClickAwayListener>
          )}
        </div>

        <div style={{ display: "flex", position: "relative" }}>
          <TeambookBlueButton
            className="mobile_hidden filter-row__add-user-button"
            onClick={openCreation}
            variant="contained"
            borderRadius="7px 0px 0px 7px !important"
            disabledAnimations
            htmlText={
              <div className="filter-row__add-user-button_text">
                <TeambookIcon name={icons.USER_PLUS} color="white" />
                <p> {t("users.create_user")}</p>
              </div>
            }
          />
          <div onClick={switchDropdownShow} className="create-dropdown-trigger mobile_hidden">
            <span className="button-separator" />
            <img style={{ rotate: uploadDropdownShow ? "180deg" : "0deg" }} src={ArrowDown} />
          </div>

          {uploadDropdownShow && (
            <ClickAwayListener onClickAway={closeDropdowns}>
              <div className="control-bar__dropdown">
                <div className="control-bar__dropdown__option" onClick={() => setUploadDialogOpened(true)}>
                  <TeambookIcon name={icons.UPLOAD} color="alternative_default" />
                  <p>{t("users.upload_users")}</p>
                </div>
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>

      <div className="desktop_hidden filter-row__mobile-creation-button">
        <img alt="add-user" className="desktop_hidden" onClick={openCreation} src={AddUser} />
      </div>

      <UploadUsersDialog
        open={uploadDialogOpened}
        handleClose={() => {
          setUploadDialogOpened(false);
        }}
        getAllUsers={getAllUsers}
      />

      <TeamSelect
        teams={teams}
        setTeams={setTeams}
        teamId={null}
        dialogOpened={isTeamsDialogOpened}
        setDialogOpened={setIsTeamsDialogOpened}
      />
    </div>
  );
};

export default UsersFilterRow;
