export default function YearSeparator() {
  return (
    <div
      style={{
        position: "absolute",
        right: "-1px",
        zIndex: 1,
        width: 1,
        background: "var(--text-12)",
        height: "100%",
      }}
    />
  );
}
