import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TeambookRadioButton from "../default_components/TeambookRadioButton";
import TeambookBlueButton from "../default_components/TeambookBlueButton";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../stores/notificationStore";

const ENTER_KEY = 13;

const RegistrationModal = ({ register }) => {
  const { t } = useTranslation();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [accountProjects, setAccountProjects] = useState([
    { name: "", client_name: "" },
    { name: "", client_name: "" },
  ]);

  const [accountUsers, setAccountUsers] = useState([
    {
      first_name: "",
      last_name: "",
    },
    {
      first_name: "",
      last_name: "",
    },
  ]);

  const [plan, setPlan] = useState("by_user");
  const [defaultDuration, setDefaultDuration] = useState(4);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

  const [userErrors, setUserErrors] = useState([]);
  const [projectErrors, setProjectErrors] = useState([]);

  const checkError = (value, field) => {
    let fields = {
      first_name: false,
      last_name: false,
      name: false,
      client_name: false,
    };

    if (value.length !== 0) {
      if (field === "first_name" && (value.length < 2 || value.length > 30)) {
        fields.first_name = true;
      }
    }

    if (value.length !== 0) {
      if (field === "last_name" && (value.length < 2 || value.length > 30)) {
        fields.last_name = true;
      }
    }

    if (value.length !== 0) {
      if (field === "name" && (value.length < 2 || value.length > 30)) {
        fields.name = true;
      }
    }

    if (field === "client_name" && value.length > 0 && (value.length < 2 || value.length > 30)) {
      fields.client_name = true;
    }

    if (fields[field]) {
      return true;
    } else {
      return false;
    }
  };

  const changeProject = (target, index, field) => {
    setAccountProjects((prev) => {
      let arrayProjects = [...prev];
      arrayProjects[index][field] = target.value;
      return arrayProjects;
    });

    checkError(target.value, field)
      ? (target.style.boxShadow = "0px 0px 4px 2px rgba(255, 0, 0, 0.3)")
      : (target.style.boxShadow = "unset");
  };

  const changeUser = (target, index, field) => {
    setAccountUsers((prev) => {
      let arrayUsers = [...prev];
      arrayUsers[index][field] = target.value;
      return arrayUsers;
    });

    checkError(target.value, field)
      ? (target.style.boxShadow = "0px 0px 4px 2px rgba(255, 0, 0, 0.3)")
      : (target.style.boxShadow = "unset");
  };

  useEffect(() => {
    setAccountUsers(accountUsers);
  }, []);

  const continueRegister = () => {
    setIsButtonsDisabled(true);
    let userErrorFields = [];
    let projectErrorFields = [];

    accountUsers.forEach((obj, i) => {
      let objErrors = {
        index: i,
        first_name: false,
        last_name: false,
      };

      if (
        `${accountUsers[0].first_name} ${accountUsers[0].last_name}`.replace(/\s+/g, "") ===
          `${accountUsers[1].first_name} ${accountUsers[1].last_name}`.replace(/\s+/g, "") &&
        `${accountUsers[0].first_name} ${accountUsers[0].last_name}`.replace(/\s+/g, "").length > 0 &&
        `${accountUsers[1].first_name} ${accountUsers[1].last_name}`.replace(/\s+/g, "").length > 0
      ) {
        objErrors.first_name = true;
        objErrors.last_name = true;
      }

      if (checkError(obj.first_name, "first_name")) {
        objErrors.first_name = true;
      }

      if (checkError(obj.last_name, "last_name")) {
        objErrors.last_name = true;
      }

      if (objErrors.first_name || objErrors.last_name) {
        userErrorFields.push(objErrors);
        setInAppNotification(t("auth.register_continue_error"));
        setIsButtonsDisabled(false);
      }
    });

    let creationProjects = [...accountProjects];

    creationProjects.forEach((obj, i) => {
      let objErrors = {
        index: i,
        name: false,
        client_name: false,
      };

      if (obj.name.length === 0) {
        creationProjects[i] = null;
        return;
      }

      if (
        creationProjects[0].name === creationProjects[1].name &&
        creationProjects[0].name.length > 0 &&
        creationProjects[1].name.length > 0
      ) {
        objErrors.name = true;
      }

      if (checkError(obj.name, "name") !== false) {
        objErrors.name = true;
      }

      if (checkError(obj.client_name, "client_name") !== false) {
        objErrors.client_name = true;
      }

      if (objErrors.name || objErrors.client_name) {
        projectErrorFields.push(objErrors);
        setInAppNotification(t("auth.register_continue_error"));
        setIsButtonsDisabled(false);
      }
    });

    if (userErrorFields.length || projectErrorFields.length) {
      setUserErrors(userErrorFields);
      setProjectErrors(projectErrorFields);
      setIsButtonsDisabled(false);
    } else {
      register({
        account_projects: creationProjects,
        account_users: accountUsers,
        plan: plan,
        default_booking_duration: defaultDuration * 60,
        time_zone: timezone,
      }).then(() => {
        setIsButtonsDisabled(false);
      });
    }
  };

  const handleEnterPress = (e) => {
    if (e.keyCode === ENTER_KEY && !isButtonsDisabled) {
      continueRegister();
    }
  };

  return (
    <Dialog onKeyDown={handleEnterPress} className="register-pop-up__container" fullWidth={true} open>
      <DialogTitle>
        <div style={{ position: "relative" }}>
          <p>{t("auth.pop_up_header_1")}</p>
        </div>
        <div>
          <div
            className="register-pop-up__container-header_second-row"
            style={{ fontSize: 16, color: "var(--text-2)" }}
          >
            <p>{t("or")}</p>
            <p
              className="register-pop-up__skip"
              onClick={() => {
                if (!isButtonsDisabled) {
                  setIsButtonsDisabled(true);
                  register(null).then(() => {
                    setIsButtonsDisabled(false);
                  });
                }
              }}
            >
              {t("auth.skip")}
            </p>
            <p>{t("auth.pop_up_header_2")}</p>
          </div>
        </div>
      </DialogTitle>

      <DialogContent className="register-pop-up__content">
        <TeambookIcon name={icons.NEW_REGISTER_POPUP_BG} color="blue" className="register-pop-up__image" />
        <div className="register-pop-up__fields">
          <div className="register-pop-up__field">
            <div className="register-pop-up__field-header">
              <p className="register-pop-up__field-name">{t("header.projects")}</p>
            </div>

            <div className="register-pop-up__list">
              {accountProjects.map((project, index) => {
                return (
                  <div key={"project-" + index} className="register-pop-up__list-element">
                    <div style={{ width: "50%" }}>
                      <input
                        style={{
                          width: "100%",
                          boxShadow: projectErrors.some((err) => err.index === index && err.name === true)
                            ? "0px 0px 4px 2px rgba(255, 0, 0, 0.3)"
                            : "unset",
                        }}
                        placeholder={t("auth.project_name", { index: " " })}
                        value={project.name}
                        onChange={(e) => changeProject(e.target, index, "name")}
                      />
                    </div>

                    <div style={{ width: "50%" }}>
                      <input
                        style={{
                          width: "100%",
                          boxShadow: projectErrors.some((err) => err.index === index && err.client_name === true)
                            ? "0px 0px 4px 2px rgba(255, 0, 0, 0.3)"
                            : "unset",
                        }}
                        placeholder={t("auth.client_name_placeholder")}
                        value={project.client_name}
                        onChange={(e) => changeProject(e.target, index, "client_name")}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="register-pop-up__field-header">
              <p style={{ marginTop: 30 }} className="register-pop-up__field-name">
                {t("header.users")}
              </p>
            </div>

            <div className="register-pop-up__list">
              {accountUsers.map((user, index) => {
                return (
                  <div key={"user-" + index} className="register-pop-up__list-element">
                    <div style={{ width: "50%" }}>
                      <input
                        style={{
                          width: "100%",
                          boxShadow: userErrors.some((err) => err.index === index && err.first_name === true)
                            ? "0px 0px 4px 2px rgba(255, 0, 0, 0.3)"
                            : "unset",
                        }}
                        placeholder={t("auth.firstname")}
                        value={user.first_name}
                        onChange={(e) => changeUser(e.target, index, "first_name")}
                      />
                    </div>

                    <div style={{ width: "50%" }}>
                      <input
                        style={{
                          width: "100%",
                          boxShadow: userErrors.some((err) => err.index === index && err.last_name === true)
                            ? "0px 0px 4px 2px rgba(255, 0, 0, 0.3)"
                            : "unset",
                        }}
                        placeholder={t("auth.lastname")}
                        value={user.last_name}
                        onChange={(e) => changeUser(e.target, index, "last_name")}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="register-pop-up__settings">
            <div className="register-pop-up__plan">
              <p className="register-pop-up__setting-header">{t("auth.you_plan")}</p>

              <div>
                <TeambookRadioButton checked={plan === "by_user"} onCheck={() => setPlan("by_user")} />

                <p onClick={() => setPlan("by_user")}>
                  {t("auth.plan_by_user")}{" "}
                  <a href={t("auth.plan_by_user_url")} target="_blank">
                    {t("auth.plan_by_user_url_text")}
                  </a>
                </p>
              </div>

              <div>
                <TeambookRadioButton checked={plan === "capacity"} onCheck={() => setPlan("capacity")} />

                <p onClick={() => setPlan("capacity")}>
                  {t("auth.plan_by_capacity")}{" "}
                  <a href={t("auth.plan_by_capacity_url")} target="_blank">
                    {t("auth.plan_by_capacity_url_text")}
                  </a>
                </p>
              </div>

              <div>
                <TeambookRadioButton checked={plan === "actuals"} onCheck={() => setPlan("actuals")} />

                <p onClick={() => setPlan("actuals")}>
                  {t("auth.plan_by_actuals")}{" "}
                  <a href={t("auth.plan_by_actuals_url")} target="_blank">
                    {t("auth.plan_by_actuals_url_text")}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton disabled={isButtonsDisabled} onClick={continueRegister} text={t("auth.next")} />
      </DialogActions>
    </Dialog>
  );
};

export default RegistrationModal;
