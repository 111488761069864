import React, { useEffect, useState } from "react";
import ActualDay from "./ActualDay";
import WeekTotal from "./WeekTotal";
import { DateTime } from "luxon";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const ActualWeek = ({
  date,
  beginningDate,
  index,
  projects,
  actualLogs,
  user,
  updateActuals,
  occupations,
  activities,
  createActivity,
  createOccupation,
  changeContextMenu,
  changeTooltip,
  copyEnabled,
  copyLogRequest,
  showWeekendLogs,
  weeksCount,
}) => {
  const [weeklyLogs, setWeeklyLogs] = useState([]);
  const [days, setDays] = useState([]);
  const week = index % 7;
  const daysInWeek = showWeekendLogs ? 7 : 5;

  useEffect(() => {
    let updatedWeeklyLogs = [];
    let localDays = [];

    for (let i = 0; i < daysInWeek; i++) {
      let dailyLogs = actualLogs.filter(
        (log) =>
          DateTime.fromISO(log.date).startOf("day").toISODate() ===
          beginningDate
            .plus({ days: i + week * 7 })
            .startOf("day")
            .toISODate()
      );

      localDays.push(
        <ActualDay
          changeContextMenu={changeContextMenu}
          projects={projects}
          beginningDate={date}
          date={beginningDate.plus({ days: i + week * 7 })}
          logs={dailyLogs}
          user={user}
          updateActuals={updateActuals}
          occupations={occupations}
          activities={activities}
          createActivity={createActivity}
          createOccupation={createOccupation}
          changeTooltip={changeTooltip}
          copyEnabled={copyEnabled}
          copyLogRequest={copyLogRequest}
          showWeekendLogs={showWeekendLogs}
          index={i}
          isLastWeek={weeksCount - 1 === index}
          isLast={i === daysInWeek - 1 && weeksCount - 1 === index}
        />
      );

      updatedWeeklyLogs.push(...dailyLogs);
    }

    setWeeklyLogs(updatedWeeklyLogs);
    setDays(localDays);
  }, [date, actualLogs, activities, occupations, copyEnabled, daysInWeek]);

  return (
    <div key={"week-" + index} className="week-row">
      <WeekTotal isLast={weeksCount - 1 === index} weeklyLogs={weeklyLogs} />
      <div
        style={{
          borderBottom: weeksCount - 1 === index && "1px solid var(--stroke)",
          borderBottomRightRadius: weeksCount - 1 === index && 7,
        }}
        className="week-row__week-days"
      >
        {days}
      </div>
    </div>
  );
};

export default ActualWeek;
