import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import XLSExport from "../../../../lib/XLSExport";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";

const UploadProjectsDialog = ({ open, handleClose, getActiveProjects, getAllClients }) => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState("");
  const [errors, setErrors] = useState();

  const uploadProjects = () => {
    Api.Projects.upload({
      projects_string: projects,
    }).then((response) => {
      if (response.data.errors.length > 0) {
        // alert(response.data.errors.join("\n"))
        setProjects("");
        setErrors(response.data.errors);
      } else {
        setProjects("");
        getActiveProjects();
        getAllClients();
        handleClose();
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="upload-projects-dialog" className="tb-default-dialog s">
      <DialogTitle id="add-projects-dialog">
        <p>{t("projects.upload_projects")}</p>
        <TeambookIcon name={icons.NEW_CROSS} onClick={handleClose} />
      </DialogTitle>
      <DialogContent style={{ margin: "20px 0px", width: 490, scrollbarGutter: "unset" }}>
        <div className="projects__import-defaul-text flex">
          <p>{t("projects.integrations_projects")}</p>
          &nbsp;
          <NavLink
            to={{
              pathname: "/settings",
              search: "?page=integration",
            }}
          >
            <p style={{ textDecoration: "underline", color: "var(--header-nav-color)" }}>
              {t("users.integrations_users_link")}
            </p>
          </NavLink>
        </div>

        <div className="projects__import-or-line">
          <div className="line" />
          <p>{t("or").toLowerCase()}</p>
          <div className="line" />
        </div>

        <div className="users__import-inline-text">
          <p>{t("projects.upload_first")}</p>
          &nbsp;
          <p className="users__import-link-text" onClick={XLSExport.ExportUploadingProjectsFile}>
            {t("projects.upload_first_file")}
          </p>
        </div>
        <div className="users__import-defaul-text">
          <p style={{ marginTop: 10 }}>{t("projects.upload_second")}</p>
          <p style={{ margin: "10px 0 20px 0" }}>{t("projects.upload_third")}</p>
        </div>

        <TextField
          id="outlined-multiline-static"
          className="text-field tb__bg__white full-width actuals-text-field"
          label={t("header.projects")}
          placeholder={"Project 1\tPROJ1\tBillable\tTeambook\nProject 2\tPROJ2\tNon-billable\n"}
          multiline
          rows="4"
          variant="outlined"
          onChange={(event) => setProjects(event.target.value)}
          value={projects}
        />

        {errors && (
          <div style={{ marginTop: "10px", color: "var(--error)" }}>
            <p>Some records were not uploaded:</p>
            {errors.map((error) => (
              <p>{error}</p>
            ))}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <TeambookOutlinedButton
          onClick={() => {
            handleClose();
            setErrors();
          }}
          text={t("cancel")}
        />
        <TeambookBlueButton onClick={uploadProjects} text={t("upload")} />
      </DialogActions>
    </Dialog>
  );
};

export default UploadProjectsDialog;
