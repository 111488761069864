import { getNumberOfDays, GetStartDateForPlannerDate, OffsetDays, ToDateString } from "../../../lib/DateUtils";

class PlannerDispatcher {
  constructor(usersRef, filteredUsers) {
    this.usersRef = usersRef;
    this.filteredUsers = filteredUsers;
  }

  async closeCreation(bookingCreationData, usersRef, date, zoom, teams, teamId) {
    let func = function (usersRef, bookingCreationData, i, dateIndex) {
      if (usersRef.current[i]) {
        usersRef.current[i].closeCreation(dateIndex);
      }
    };

    this.iterateThroughCreationBookings(
      bookingCreationData,
      func.bind(null, this.usersRef, bookingCreationData),
      date,
      zoom,
      teams,
      teamId
    );
  }

  updateBookingDuration(duration, creationRef) {
    creationRef.current[0].changeDuration(duration);
  }

  updateProjectId(bookingCreationData, newProjectId, date, zoom, usersRef, teams, teamId) {
    bookingCreationData["project_id"] = newProjectId;

    let func = function (usersRef, bookingCreationData, i, dateIndex) {
      if (usersRef.current[i]) {
        usersRef.current[i].updateCellField("project_id", bookingCreationData["project_id"], dateIndex);
      }
    };

    this.iterateThroughCreationBookings(
      bookingCreationData,
      func.bind(null, this.usersRef, bookingCreationData),
      date,
      zoom,
      teams,
      teamId
    );

    return bookingCreationData;
  }

  iterateThroughCreationBookings(bookingCreationData, func, date, zoom, teams, teamId) {
    if (bookingCreationData["creation_type"] === "click") {
      let userIds = Object.keys(bookingCreationData["creation_hash"]);

      for (let i = 0; i < userIds.length; i++) {
        let user = this.filteredUsers.find((user) => user.id === parseInt(userIds[i]));
        let userOrder = this.filteredUsers.indexOf(user);

        let dates = bookingCreationData["creation_hash"][userIds[i]];

        for (let j = 0; j < dates.length; j++) {
          let dateIndex = getNumberOfDays(GetStartDateForPlannerDate(date, zoom), dates[j]);

          func(userOrder, dateIndex);
        }
      }
    } else {
      let datesAmount = getNumberOfDays(bookingCreationData["date_start"], bookingCreationData["date_end"]);

      for (let i = bookingCreationData["order_start"]; i <= bookingCreationData["order_end"]; i++) {
        for (let j = 0; j <= datesAmount; j++) {
          let dateIndex = getNumberOfDays(
            GetStartDateForPlannerDate(date, zoom),
            OffsetDays(bookingCreationData["date_start"], j)
          );

          func(i, dateIndex);
        }
      }
    }
  }

  afterCreationBookingAssignment(userIndexes) {
    userIndexes = Array.from(new Set(userIndexes));
    userIndexes.forEach((userIndex) => {
      if (this.filteredUsers[userIndex]) {
        this.usersRef.current[userIndex]?.updateBookings();
      }
    });
  }
}

export default PlannerDispatcher;
