import React from "react";
import "./projection.scss";
import { useTranslation } from "react-i18next";
import { MonthsOfTheYear } from "../../lib/DateUtils";
import { DateTime } from "luxon";

const weekDays = (t) => [
  t("planning.monday"),
  t("planning.tuesday"),
  t("planning.wednesday"),
  t("planning.thursday"),
  t("planning.friday"),
  t("planning.saturday"),
  t("planning.sunday"),
];

const DatesRow = ({ date }) => {
  const { t } = useTranslation();

  let monthsArray = MonthsOfTheYear(t);
  let monthColumns = [];
  let weekColumns = [];

  for (let i = 0; i < 12; i++) {
    let dayColumns = [];

    for (let j = 0; j < 7; j++) {
      let relativeDate = date.plus({ day: i * 7 + j });

      const isLessToday = relativeDate < DateTime.now();
      const isToday = relativeDate.toLocaleString() === DateTime.now().toLocaleString();
      const isWeekends = relativeDate.weekday > 5;
      const dayColumnText = `${weekDays(t)[relativeDate.weekday - 1]} ${relativeDate.day}`;

      dayColumns.push(
        <div
          className={`projection__day-column
            ${isLessToday && "less-today"}
            ${isWeekends && "weekends"}
            ${isToday && "today"}`}
        >
          {dayColumnText}
        </div>
      );

      monthColumns.push(
        <div
          className={`projection__month-column ${relativeDate.day === relativeDate.endOf("month").day && "divider"}`}
        >
          {[4, 20].includes(relativeDate.day) && <p>{monthsArray[relativeDate.month - 1]}</p>}
        </div>
      );
    }

    weekColumns.push(<div className="projection__week-column">{dayColumns}</div>);
  }

  return (
    <>
      <div className="projection__months-row">{monthColumns}</div>

      <div className="projection__hide" />

      <div className="projection__weeks-row">{weekColumns}</div>
    </>
  );
};

export default DatesRow;
