import React from "react";
import "./header_menu.scss";
import Switch from "@mui/material/Switch";
import { ThemeContext, themes } from "../../../contexts/ThemeContext";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import Api from "../../../Api";
import { DateTime } from "luxon";
import { useBookingsCreatingStore } from "../../../stores/planner";

const DarkModeButton = () => {
  return (
    <ThemeContext.Consumer>
      {({ theme, setTheme }) => (
        <div className="header-menu__item-content toggle-dark-mode">
          <div className="icon-container">
            <TeambookIcon name={icons.MOON} />
            <p>Dark mode</p>
          </div>

          <Switch
            checked={theme === themes.dark}
            onChange={() => {
              Api.Users.post_settings({
                dark_theme: theme === themes.light,
              }).then(async (response) => {
                await new Promise((resolve) => {
                  setTheme(theme === themes.light ? themes.dark : themes.light);

                  resolve();
                }).then(() => {
                  window.location.reload();
                });
              });
            }}
            sx={{ height: "42px" }}
          />
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default DarkModeButton;
