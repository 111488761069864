import DeleteBookingsRequest from "../requests/DeleteBookingsRequest";
import TooltipChanger from "./TooltipChanger";
import $ from "jquery";
import { DateTime } from "luxon";

const _deleteBookingsRequest = (
  deletionBookings,
  setBookingsEditing,
  setRepetitionQuestionDialogOpened,
  date,
  zoom,
  setBookings,
  closeCreationWindow,
  bookings,
  setEditWindowOpened,
  closeEditWindow,
  teams,
  teamId,
  usersRef,
  filteredUsers,
  setProjects,
  setActiveProjects,
  projects,
  activeProjects,
  tooltipRef
) => {
  let skipQuestion = true;
  setBookingsEditing(deletionBookings);

  deletionBookings.forEach((booking) => {
    if (booking.repetition && skipQuestion) {
      setRepetitionQuestionDialogOpened(true);
      skipQuestion = false;
    }
  });

  if (skipQuestion) {
    //TODO: fix booking deletion hashes shit
    DeleteBookingsRequest(
      deletionBookings,
      date,
      zoom,
      setBookings,
      closeCreationWindow,
      bookings,
      setEditWindowOpened,
      false,
      closeEditWindow,
      teams,
      teamId,
      usersRef,
      filteredUsers,
      setProjects,
      setActiveProjects,
      projects,
      activeProjects
    );
  }
  TooltipChanger(tooltipRef.current[0]);
};

// WEEKENDS AMOUNT * WIDTH OPF WEEKENDS + DAYS AMOUNT * DAYS WIDTH
const _scrollPlannerToDate = (zoom, weekendsHidden) => {
  let weekends_width = 10;

  if (!weekendsHidden) {
    switch (zoom) {
      case 14:
        weekends_width = 140;
        break;
      case 28:
        weekends_width = 72;
        break;
      case 90:
        weekends_width = 20;
        break;
      default:
        break;
    }
  }

  if ($("#planner-scroll-list")?.scrollLeft() === 0) {
    switch (zoom) {
      case 14:
        $("#planner-scroll-list").animate(
          {
            scrollLeft: 4 * weekends_width + 10 * 140 + 1,
          },
          500
        );
        return;
      case 28:
        $("#planner-scroll-list").animate(
          {
            scrollLeft: 4 * weekends_width + 10 * 72 + 1,
          },
          500
        );
        return;
      case 90:
        $("#planner-scroll-list").animate(
          {
            scrollLeft: 4 * weekends_width + 10 * 20 + 1,
          },
          500
        );
        return;
      default:
        return;
    }
  }
};

const copyBookingAvailable = (bookings) => {
  if (bookings.length === 0) return true;

  const userId = bookings[0].user_id;
  const weekStartDate = DateTime.fromISO(bookings[0].date).startOf("week").toISODate();

  return bookings.every(
    (booking) =>
      booking.user_id === userId && DateTime.fromISO(booking.date).startOf("week").toISODate() === weekStartDate
  );
};

export { _deleteBookingsRequest, _scrollPlannerToDate, copyBookingAvailable };
