import React, { useEffect } from "react";
import ScheduleDay from "./ScheduleDay";
import { useTranslation } from "react-i18next";

const TeambookScheduleComponent = ({ schedule = [], setSchedule, kind }) => {
  const { t } = useTranslation();

  return (
    <div className="schedule__component">
      <div className="schedule__header">
        <div className="schedule__header-checkbox"></div>
        <p className="schedule__header-start">{t("settings.schedule.start_time")}</p>
        <p className="schedule__header-lunch">{t("settings.schedule.lunch_break")}</p>
        <p className="schedule__header-end">{t("settings.schedule.end_time")}</p>
        <p className="schedule__header-duration">{t("settings.schedule.duration")}</p>
      </div>

      {schedule.map((day, index) => (
        <ScheduleDay index={(index + 1) % 7} schedule={schedule} setSchedule={setSchedule} kind={kind} />
      ))}
    </div>
  );
};

export default TeambookScheduleComponent;
