import React, { useState } from "react";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";
import Api from "../../../../Api";
import TeambookWhiteButton from "../../../default_components/TeambookWhiteButton";

const EntraSyncBlock = () => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [entraConnected, setEntraConnected] = useState(!!account.entra_id_sync_integration);

  const entraSyncUrl = () => {
    switch (process.env.REACT_APP_ENV) {
      case "production":
        return "https://web.teambookapp.com/api/auth/entra_sync_integration";
      case "staging":
        return "https://web.teambooktest.com/api/auth/entra_sync_integration";
      default:
        return "http://localhost:3001/api/auth/entra_sync_integration";
    }
  };

  return (
    <div className="integration-block">
      <div className="integration-name">
        <TeambookIcon name={icons.ENTRA_ID_ICON} alt="entra id logo" />
      </div>

      <div className="integration-status">
        <p className={entraConnected ? "active" : "not_active"}>{entraConnected ? "Active" : "Not connected"}</p>
      </div>

      {entraConnected ? (
        <TeambookWhiteButton
          text={t("disconnect")}
          onClick={() => {
            Api.Integrations.Outlook.deleteEntraSync().then(() => {
              setEntraConnected(false);
            });
          }}
          style={{
            margin: "13px 0 0 0",
            width: "171px",
          }}
          className="disconnect-button"
        />
      ) : (
        <TeambookOutlinedButton
          onClick={() => window.open(entraSyncUrl(), "_blank")}
          text={t("settings.integration.connect")}
          style={{
            margin: "13px 0 0 0",
            width: "171px",
          }}
        />
      )}
    </div>
  );
};

export default EntraSyncBlock;
