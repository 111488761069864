import React from "react";
import { DateTime } from "luxon";
import Api from "../../../Api";
import lsKeys from "../../default_values/defaultKeys";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import UsersProfileImage from "../../default_components/UsersProfileImage";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../../stores/profileStore";
import YearSeparator from "../default_components/YearSeparator";

const usersWithData = (users, reservations) => {
  return users.filter((u) =>
    reservations
      .filter((ur) => ur.days_reserved > 0)
      .map((ur) => ur.user_id)
      .includes(u.id)
  );
};

const StaffingRow = ({
  date,
  userReservations,
  users,
  jumpToUser,
  project,
  setCapacityReservations,
  selectedViewType,
  addedUsers,
}) => {
  const { t } = useTranslation();
  const fullAccessRoles = ["admin", "planner"];
  const role = localStorage.getItem(lsKeys.ROLE);
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const calculateUserDays = (i, user) => {
    const calculatedDate = date.plus({ months: i }).toISODate();

    return userReservations
      .filter((ur) => ur.project_id === project.id)
      .filter((cr) => DateTime.fromISO(cr.date).toISODate() === calculatedDate)
      .filter((ur) => user.id === ur.user_id)
      .map((cr) => cr.days_reserved)
      .reduce((ps, a) => ps + a, 0);
  };

  const updateUserReservation = (user, newValue, index) => {
    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      return;
    }

    Api.CapacityReservations.create({
      user_id: user.id,
      project_id: project.id,
      days_reserved: newValue,
      date: date.plus({ months: index }).toISODate(),
    }).then(() => {
      Api.CapacityReservations.get({
        user_ids: [user.id],
        start_date: date.toISODate(),
        end_date: date.plus({ years: 2 }).toISODate(),
      }).then((res) => {
        setCapacityReservations([...userReservations.filter((ur) => ur.user_id !== user.id), ...res.data]);
      });
    });
  };

  const handleFocus = (event) => event.target.select();

  const shownUsers = () => {
    const usersInCapacity = usersWithData(
      users,
      userReservations.filter((ur) => ur.project_id === project.id)
    );

    return [...usersInCapacity.filter((u) => !addedUsers.map((_u) => _u.id).includes(u.id)), ...addedUsers];
  };

  return (
    <>
      {shownUsers().map((user) => (
        <div className="flex project-row" key={`user-row-${user.id}-date-${date}`}>
          <div
            className="users-block__project-info users-block__projects-capacity greyed"
            onClick={() => jumpToUser(user.id)}
            title={t("planning.capacity.go_to_user")}
          >
            <UsersProfileImage size={40} user={user} className="avatar" userImage={user.avatars["50"]} />
            <p className="user-name font-italic">{user.name}</p>

            <TeambookIcon className="cursor-pointer" name={icons.ARROW_DOWN} style={{ transform: "rotate(-90deg)" }} />
          </div>

          {[...Array(selectedViewType)].map((x, i, arr) => (
            <div className="table-cell font-italic greyed">
              {fullAccessRoles.includes(role) || (user.id === profile.id && profile.role === "self_planner") ? (
                <input
                  onChange={(event) =>
                    updateUserReservation(user, event.target.value >= 0 ? event.target.value : 0, i, event)
                  }
                  onFocus={handleFocus}
                  onWheel={(e) => e.target.blur()}
                  defaultValue={parseInt(calculateUserDays(i, user)) || 0}
                  style={{ width: "100%" }}
                  type={"number"}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => e.preventDefault()}
                />
              ) : (
                <p>{parseInt(calculateUserDays(i, user))}</p>
              )}

              {date.plus({ months: i }).month === 12 && i !== selectedViewType - 1 && <YearSeparator />}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default StaffingRow;
