import { useBookingsStore } from "../../../../stores/planner";

const socketHandler = (params) => {
  switch (params.socketData.type) {
    case "create":
      handleCreateBookings(params);
      return;
    case "delete":
      handleDeleteBookings(params);
      return;
    case "edit":
      handleEditBookings(params);
      return;
  }
};

const handleCreateBookings = (props) => {
  if (props.socketData.bookings.length === 0) {
    return;
  }

  if (props.socketData.bookings[0].created_by === props.userId) {
    return;
  }

  let new_bookings =
    [...props.bookings].filter((b) => !props.socketData.bookings.map((b) => b.id).includes(b.id)) || [];

  props.setBookings([...new_bookings, ...props.socketData.bookings]);

  props.plannerDispatcher.afterCreationBookingAssignment([
    ...new Set(props.socketData.bookings.map((booking) => props.getUserIndex(booking.user_id)).flat()),
  ]);
};

const handleDeleteBookings = (props) => {
  if (props.socketData.bookings.length === 0) {
    return;
  }

  let new_bookings =
    [...useBookingsStore.getState().bookings].filter(
      (b) => !props.socketData.bookings.map((b) => b.id).includes(b.id)
    ) || [];

  props.setBookings([...new_bookings]);

  props.plannerDispatcher.afterCreationBookingAssignment([
    ...new Set(props.socketData.bookings.map((booking) => props.getUserIndex(booking.user_id)).flat()),
  ]);
};

const handleEditBookings = (props) => {
  if (props.socketData.bookings.length === 0) {
    return;
  }

  if (props.socketData.bookings[0].updated_by === props.userId) {
    return;
  }

  let new_bookings =
    [...props.bookings].filter((b) => !props.socketData.bookings.map((b) => b.id).includes(b.id)) || [];

  props.setBookings([...new_bookings, ...props.socketData.bookings]);

  const usersToUpdate = [
    ...props.bookings.filter((b) => props.socketData.bookings.map((b) => b.id).includes(b.id)).map((b) => b.user_id),
    ...props.socketData.bookings.map((b) => b.user_id),
  ];

  props.plannerDispatcher.afterCreationBookingAssignment([
    ...new Set(usersToUpdate.map((userId) => props.getUserIndex(userId)).flat()),
  ]);
};

export default socketHandler;
