import React, { useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import ControlPanel from "./ControlPanel";
import PlannerToExport from "./PlannerToExport";
import { useReactToPrint } from "react-to-print";
import "./pdf_export.scss";
import { ThemeContext } from "../../../contexts/ThemeContext";

const PlannerToPdf = () => {
  const { date, teamId } = useParams();
  const navigate = useNavigate();
  const plannerRef = useRef();

  const { setIsAdminTheme } = useContext(ThemeContext);

  useEffect(() => {
    setIsAdminTheme(true);
  }, []);

  useEffect(() => {
    if (!date) {
      changeDate(DateTime.now().startOf("week").toISODate());
    }
  }, []);

  const changeDate = (newDate) => {
    navigate(`/planner_to_pdf/${teamId}/${newDate}`, {
      replace: true,
    });
  };

  const printPlanner = useReactToPrint({
    content: () => plannerRef.current,
  });

  let processedDate = DateTime.fromISO(date);

  return (
    <div className="pdf-export-page">
      <ControlPanel date={processedDate} changeDate={changeDate} printPlanner={printPlanner} />

      <div ref={plannerRef}>
        <style type="text/css" media="print">
          {
            "\
           @page { size: landscape; }\
        "
          }
        </style>

        <PlannerToExport date={processedDate} teamId={teamId} />
      </div>
    </div>
  );
};

export default PlannerToPdf;
