import React from "react";
import "../capacity_planning.scss";
import { DateTime } from "luxon";
import { getWorkingDays, monthsInDateRanges } from "../../../lib/DateUtils";
import YearSeparator from "../default_components/YearSeparator";

const DatesRow = ({ date, selectedViewType, type = "planning" }) => {
  const monthsInYear = monthsInDateRanges(date, date.plus({ months: selectedViewType - 1 }));

  return (
    <>
      <div
        className="users-block__dates-row first-row"
        style={{
          borderBottom: "none",
        }}
      >
        <div className="left-side" />

        <div style={{ minWidth: monthsInYear.reduce((pr, cur) => pr + cur, 0) * 80 }} className="flex right-side">
          <div className="date-row__year-row">
            <div style={{ width: 400, minWidth: 400, maxWidth: 400 }} />
            {Array.from({ length: date.plus({ months: selectedViewType }).year - date.year + 1 }).map(
              (_, index, yearsArray) => {
                const year = date.plus({ year: index }).year;
                const isLastYear = index === yearsArray.length - 1;

                return Array.from({ length: monthsInYear[index] }).map((_, j, arr) => {
                  const isEven = (num) => num % 2 === 0;
                  const isLastMonth = j == arr.length - 1;

                  return (
                    <div
                      style={{
                        borderRight:
                          j === arr.length - 1 && index !== yearsArray.length - 1
                            ? "1px solid var(--text-12)"
                            : isLastMonth && isLastYear
                            ? "1px solid var(--stroke)"
                            : index !== 2
                            ? "1px solid transparent"
                            : "unset",
                        borderBottom: "1px solid transparent",
                        justifyContent: isEven(arr.length) ? "unset" : "center",
                        textAlign: isEven(arr.length) ? "unset" : "center",
                        borderTopRightRadius: isLastMonth && isLastYear && 8,
                      }}
                      className="table-cell"
                    >
                      <p style={{ marginLeft: isEven(arr.length) ? "-17px" : "0px" }}>
                        {j == Math.floor(arr.length / 2) && year}
                      </p>
                    </div>
                  );
                });
              }
            )}
          </div>
        </div>
      </div>

      {type === "reporting" ? (
        <div
          className="users-block__dates-row"
          style={{
            top: "60px",
            paddingleft: 30,
            height: 31,
          }}
        >
          <div
            style={{
              borderTop: type === "reporting" && "1px solid #c8d2e6",
              height: type === "reporting" ? 31 : 60,
              background: "#fff",
            }}
            className="left-side"
          >
            <div />
          </div>
          <div
            style={{
              paddingLeft: 30,
              height: type === "reporting" ? 31 : 60,
            }}
            className="flex right-side"
          >
            {[...Array(selectedViewType)].map((x, i) => (
              <div
                style={{
                  height: 31,
                  background: "red",
                }}
                className="table-cell"
              >
                <p>{date.plus({ months: i }).toFormat("LLL").toUpperCase()}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="users-block__dates-row is-capacity-months"
          style={{
            top: 34,
          }}
        >
          <div
            className="left-side second-row"
            style={{
              height: 36,
            }}
          >
            <div />
          </div>
          <div style={{ height: 36 }} className="flex right-side">
            {[...Array(selectedViewType)].map((x, i, arr) => (
              <div
                className={`table-cell ${
                  date.plus({ months: i }).startOf("month").ts === DateTime.now().startOf("month").ts && "current-month"
                }`}
                style={{
                  height: 36,
                  borderRight: "1px solid var(--stroke)",
                  borderTop: "1px solid var(--stroke)",
                  position: "relative",
                }}
              >
                {date.plus({ months: i }).month === 12 && i !== selectedViewType - 1 && <YearSeparator />}
                <div>
                  <p>{date.plus({ months: i }).toFormat("LLL")}</p>
                  <p style={{ fontSize: 11 }}>
                    ({getWorkingDays(date.year, date.plus({ months: i }).month)}
                    d)
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default DatesRow;
