import { memo } from "react";
import User from "./User";
import Delta from "../../default_images/delta.svg";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";
import Project from "./Project";
import BlueArrowDown from "../../default_images/darkblue_arrow_down.svg";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import { sortEntityColumn } from "../lib/sortEntityColumn";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const UsersColumn = ({
  users,
  selectedUser,
  setSelectedUser,
  sortMode,
  allUserLogs,
  date,
  setSortMode,
  setSplitBy,
  splitBy,
  splitOptions,
  projects,
}) => {
  const { t } = useTranslation();

  return (
    <div className="users-column">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          gap: 40,
          paddingRight: 35,
        }}
      >
        <WhiteTooltip title={t("planning.alphabetical_order")} placement={"bottom"}>
          <div
            className="users-column__sort-icon"
            onClick={() => {
              if (sortMode === "z") {
                setSortMode("a");
              } else {
                setSortMode("z");
              }
            }}
          >
            <TeambookIcon name={sortMode === "z" ? icons.SORT_Z : icons.SORT_A} color="darkblue_new" />
          </div>
        </WhiteTooltip>

        <div className="users-column__split-switcher" style={{ width: 210 }}>
          <TeambookReactSelect
            height={40}
            onChange={(option) => setSplitBy(option.value)}
            value={splitOptions.find((val) => val.value === splitBy)}
            options={splitOptions}
            dropdownArrow={BlueArrowDown}
          />
        </div>
        <WhiteTooltip placement="bottom" title={t("actuals.delta_sort")}>
          <div
            onClick={() => {
              if (sortMode === "delta_hours_default") {
                setSortMode("delta_hours_reverse");
              } else {
                setSortMode("delta_hours_default");
              }
            }}
            className="users-column__sort-icon"
          >
            <TeambookIcon style={{ width: 18, height: 18 }} name={icons.DELTA} color="darkblue_new" />
          </div>
        </WhiteTooltip>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 30,
          overflowY: "auto",
          height: "100%",
          paddingTop: 20,
        }}
      >
        {sortEntityColumn(splitBy, users, projects, sortMode, date, allUserLogs).map((entity) =>
          splitBy === "by_user" ? (
            <User
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              user={entity}
              allUserLogs={allUserLogs.filter((log) => log.user_id === entity.id)}
              date={date}
            />
          ) : (
            <Project
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              project={entity}
              allUserLogs={allUserLogs.filter((log) => log.project_id === entity.id)}
              date={date}
            />
          )
        )}
      </div>
    </div>
  );
};

export default memo(UsersColumn);
