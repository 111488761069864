import { Dialog, DialogTitle, DialogContent } from "@mui/material/";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { DialogActions } from "@mui/material";
import Api from "../../../Api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookRadioButton from "../../default_components/TeambookRadioButton";
import { TeambookTeamsPicker, TeambookUsersPicker } from "../../default_components/TeambookDefaultPickers";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import lsKeys from "../../default_values/defaultKeys";
import MuiDatePicker from "../../default_components/MuiDatePicker";

const BulkApproveWindow = ({ teams, users, date, setBulkApproveOpen, updateActuals }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(date.startOf("month"));
  const [endDate, setEndDate] = useState(date.endOf("month"));
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [approveType, setApproveType] = useState("users");

  const lang = localStorage.getItem(lsKeys.LANGUAGE);

  const closeWindow = () => {
    setBulkApproveOpen(false);
  };

  const bulkApprove = () => {
    Api.ActualLogs.bulk_approve({
      from: startDate.toISODate(),
      to: endDate.toISODate(),
      userIds: selectedUsers,
      teamIds: selectedTeams,
    }).then(() => {
      updateActuals();
      closeWindow();
    });
  };

  return (
    <Dialog className="tb-default-dialog" open={true} onClose={() => closeWindow()} id="bulkApproveWindow">
      <DialogTitle>
        <p style={{ margin: "auto 0" }}>{t("actuals.bulk_approve")}</p>
        <TeambookIcon name={icons.CROSS} onClick={() => closeWindow()} />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        <div className="copy-planner-dialog__date-row">
          <p language={lang}>{`${t("projects.start_date")}`}</p>
          <div className="copy-planner-dialog__date-picker-container">
            <MuiDatePicker
              variant="inline"
              format={"d MMM, yyyy"}
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
                setEndDate(date.endOf("month"));
              }}
              sx={{ width: "150px", input: { padding: "0px" } }}
              className="mui-date-picker"
            />
          </div>
        </div>
        <div className="copy-planner-dialog__date-row">
          <p language={lang}>{`${t("projects.end_date")}`}</p>

          <div style={{ position: "relative", display: "flex" }}>
            <MuiDatePicker
              variant="inline"
              format={"d MMM, yyyy"}
              value={endDate}
              onChange={(date) => setEndDate(date)}
              sx={{ width: "150px", input: { padding: "0px" } }}
              className="mui-date-picker"
            />
          </div>
        </div>

        <div className="copy-planner-subblock">
          <p className="copy-planner-subblock__title">{t("planning.select_data")}</p>
          <div style={{ display: "flex" }}>
            <TeambookRadioButton
              checked={approveType === "users"}
              onCheck={() => {
                setApproveType("users");
                setSelectedTeams([]);
              }}
            />

            <TeambookUsersPicker
              type="multiple"
              users={users}
              placeholder={t("planning.select_users")}
              onChange={(options, newValue) => {
                setApproveType("users");
                setSelectedTeams([]);
                setSelectedUsers(options.map((o) => o.id));
              }}
              value={selectedUsers.map((id) => ({
                name: users.filter((u) => u.id === id)[0].name,
                id: id,
              }))}
              maxMenuHeight={150}
              width={400}
            />
          </div>
          <div style={{ display: "flex" }}>
            <TeambookRadioButton
              checked={approveType === "teams"}
              onCheck={() => {
                setApproveType("teams");
                setSelectedUsers([]);
              }}
            />

            <TeambookTeamsPicker
              placeholder={t("planning.select_teams")}
              teams={teams}
              displayEmpty
              onChange={(options, newValue) => {
                setApproveType("teams");
                setSelectedUsers([]);
                setSelectedTeams(options.map((o) => o.id));
              }}
              value={selectedTeams.map((id) => ({
                name: teams.filter((t) => t.id === id)[0].name,
                id: id,
              }))}
              type="multiple"
              maxMenuHeight={150}
              width={400}
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton text={t("submit")} onClick={bulkApprove} id="submitBulkApprove" />
      </DialogActions>
    </Dialog>
  );
};

export default BulkApproveWindow;
