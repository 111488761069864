import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { DialogActions, DialogContent, DialogTitle, Dialog } from "@mui/material";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import Api from "../../../Api";
import ProjectSelect from "../../planner/ProjectSelect";
import { useSelectedProjectStore } from "../../../stores/planner";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";
import { InputNumber } from "../../default_components/InputNumber";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import lsKeys from "../../default_values/defaultKeys";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../../stores/profileStore";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";
import RichTextEditor from "../../default_components/rich_text_editor/RichTextEditor";

const DEFAULT_DAY_DURATION = 8;

const CreateLogWindow = ({ projects, setIsSelected, updateActuals, date, activities, tasks, setOpen }) => {
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [user] = useProfileStore((state) => [state.profile], shallow);

  const [loggedHours, setLoggedHours] = useState(account.default_booking_duration / 60);
  const [selectedActivity, setSelectedActivity] = useState();
  const [selectedOccupation, setSelectedOccupation] = useState();
  const [logNote, setLogNote] = useState("<p></p>");
  const lang = localStorage.getItem(lsKeys.LANGUAGE);

  const { selectedProject, setSelectedProject, selectedTimeOff, setSelectedTimeOff, projectsType, setProjectsType } =
    useSelectedProjectStore();

  const { t } = useTranslation();

  const closeWindow = () => {
    setIsSelected(false);
    setOpen(false);
    setSelectedActivity(null);
    setSelectedOccupation(null);
    setLoggedHours(4);
  };

  const createLogging = () => {
    Api.ActualLogs.create({
      user_id: user.id,
      project_id: (projectsType === "time_off" ? selectedTimeOff : selectedProject).id,
      duration: loggedHours * 60,
      date: date,
      task_id: selectedOccupation,
      payroll_item_id: selectedActivity,
      note: logNote,
    }).then(() => {
      updateActuals();
      closeWindow();
    });
  };

  const selectDefaultProjects = () => {
    setProjectsType("default");
    setLoggedHours(account.default_booking_duration / 60);

    let selectedProject = projects.filter((project) => project.active && project.kind !== "time_off")[0];

    if (selectedProject === null) {
      setSelectedProject(selectedProject);
    }
  };

  const selectTimeOffProjects = () => {
    setProjectsType("time_off");
    setLoggedHours(DEFAULT_DAY_DURATION);

    let selectedProject = projects.filter((project) => project.active && project.kind === "time_off")[0];

    if (selectedTimeOff === null) {
      setSelectedTimeOff(selectedProject);
    }
  };

  const getActivitiesOptions = () => {
    return activities.map((act) => ({
      value: act.id,
      label: act.name,
    }));
  };

  const getTasksOptions = () => {
    return tasks
      .filter(
        (t) =>
          t.project_id === null || t.project_id === (projectsType === "default" ? selectedProject : selectedTimeOff)?.id
      )
      .map((act) => ({
        value: act.id,
        label: act.name,
      }));
  };

  return (
    <Dialog className="tb-default-dialog time-logging__dialog" open={true} onClose={closeWindow}>
      <DialogTitle>
        <div className="time-logging__title-text">
          <p className="title">
            {t("actuals.new_log")}: <u>{date.setLocale(lang).toFormat("d MMMM")}</u>
          </p>
        </div>

        <TeambookIcon name={icons.CROSS} onClick={closeWindow} />
      </DialogTitle>

      <DialogContent>
        <div className="time-logging-window__project-selector">
          <ProjectSelect
            projects={projects.filter((pr) => pr.active)}
            projectsType={projectsType}
            creationUsers={[user.id]}
            selectDefaultProjects={selectDefaultProjects}
            selectTimeOffProjects={selectTimeOffProjects}
          />
        </div>

        <div className="time-logging-window__duration">
          <p style={{ margin: 0 }} className="actuals__duration-text-modal">
            {t("planning.duration_hours")}
          </p>

          <InputNumber
            id={"duration"}
            className="actuals__duration-input-modal"
            name="estimated"
            setValue={setLoggedHours}
            step={0.5}
            min={0}
            max={24}
            value={loggedHours}
            roundValue={{
              isRoundValue: true,
              roundStep: 4,
            }}
          />
        </div>

        <div className="time-logging-window__project-selector">
          <p className="title">{t("actuals.service")}</p>

          <TeambookReactSelect
            isSearchable={true}
            defaultValue={null}
            options={getTasksOptions()}
            onChange={(task) => {
              setSelectedOccupation(task.value);
            }}
            height={44}
            maxMenuHeight={120}
          />
        </div>

        <div className="time-logging-window__project-selector">
          <WhiteTooltip title={t("actuals.payroll_info")}>
            <p className="title">{t("actuals.payroll")}</p>
          </WhiteTooltip>

          <TeambookReactSelect
            isSearchable={true}
            defaultValue={null}
            options={getActivitiesOptions()}
            onChange={(activity) => {
              setSelectedActivity(activity.value);
            }}
            height={44}
            maxMenuHeight={120}
          />
        </div>

        <div className="time-logging-window__notes">
          <RichTextEditor onChange={setLogNote} placeholder={t("planning.comment")} editorHeight={72} />
        </div>
      </DialogContent>

      <DialogActions>
        <TeambookOutlinedButton text={t("cancel")} style={{ width: "50%" }} onClick={() => closeWindow()} />

        <TeambookBlueButton
          className="time-logging-window__time-confirm"
          text={t("save")}
          onClick={() => createLogging()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateLogWindow;
