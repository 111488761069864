import { DateTime } from "luxon";
import { getCellWidthForZoom, GetDaysInZoom } from "../../../../lib/DateUtils";
import { useDateStore, useZoomStore } from "../../../../stores/planner";
import { shallow } from "zustand/shallow";
import { isLastDayOfMonth } from "date-fns";
import { DAY_VIEW_WIDTH } from "../../../../lib/PlannerUtils";
import lsKeys from "../../../default_values/defaultKeys";
import { PlannerContext } from "../../context/PlannerContext";
import { useContext } from "react";

export const MonthSeparator = ({ page = "default", zIndex, height }) => {
  const [date] = useDateStore((state) => [state.date], shallow);
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const { weekendsHidden, groupClients } = useContext(PlannerContext);

  const daysInZoom = GetDaysInZoom(zoom);

  let lastDaysInMonths = [];

  let dateOffset = 0;

  const pageOffset = {
    default: 119,
    grouped: groupClients ? 209 : 159,
  };

  Array.from({ length: daysInZoom }).forEach((value, index) => {
    let newDate = DateTime.fromJSDate(date).plus({ days: index });

    if (weekendsHidden && [6, 7].includes(newDate.weekday)) {
      dateOffset += 10;
    } else {
      dateOffset += getCellWidthForZoom(zoom);
    }

    if (isLastDayOfMonth(newDate.toJSDate())) {
      lastDaysInMonths.push({ day: newDate, dateOffset: dateOffset });
    }
  });

  return lastDaysInMonths.map(({ day, dateOffset }) => (
    <div
      className="month-separator-line"
      style={{
        zIndex: zIndex && zIndex,
        height: height || "100%",
        left: pageOffset[page] + dateOffset + ([6, 7].includes(day.weekday) ? 1 : 0),
      }}
    />
  ));
};
