import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../Api";
import { useTranslation } from "react-i18next";
import UpdateBookingsRequest from "../requests/UpdateBookingsRequest";
import { updateValueWithId } from "../../../lib/PlannerUtils";
import { useBookingsStore, useDateStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";
import { DialogTitle } from "@mui/material";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";
import Cross from "../../default_images/cross.svg";

const RepetitionEditQuestionDialog = ({
  open,
  setRepetitionQuestionDialogOpened,
  setBookings,
  closeEditWindow,
  teams,
  teamId,
  usersRef,
  editingData,
  setProjects,
  plannerDispatcher,
  projects,
  updateBooking,
  filteredUsers,
}) => {
  const { t } = useTranslation();

  const [date] = useDateStore((state) => [state.date], shallow);
  const [bookings] = useBookingsStore((state) => [state.bookings], shallow);

  const bookingsEditing = [];

  const getAllIndexes = (arr, userId) => {
    let indexes = [];
    arr.forEach((user, i) => {
      if (user.id === userId) {
        indexes.push(i);
      }
    });

    return indexes;
  };

  const handleClose = () => {
    setRepetitionQuestionDialogOpened(false);
  };

  const handleNo = () => {
    updateRepBookings(false);
  };

  const handleYes = () => {
    updateRepBookings(true);
  };

  const updateRepBookings = (alsoRep) => {
    UpdateBookingsRequest(
      editingData.projectId,
      editingData.duration,
      editingData.tentative,
      editingData.location,
      editingData.startTime,
      editingData.comment,
      editingData.bookingsToUpdate,
      bookings,
      setBookings,
      closeEditWindow,
      alsoRep,
      editingData.usesBudget,
      editingData.task_id,
      editingData.repetition,
      date
    ).then((response) => {
      let projectIds = [editingData.projectId];
      let bookingsToUpdate = editingData.bookingsToUpdate
        .map((booking) => getAllIndexes(filteredUsers, booking.user_id))
        .flat();

      if (editingData.repetition) {
        bookingsToUpdate = response.data.bookings
          .map((booking) => getAllIndexes(filteredUsers, booking.user_id))
          .flat();
      }

      plannerDispatcher.afterCreationBookingAssignment(bookingsToUpdate);

      bookingsEditing.forEach((booking) => {
        if (!projectIds.includes(booking.project_id)) {
          projectIds.push(booking.project_id);
        }
      });

      projectIds.forEach((projectId) => {
        Api.Projects.get(projectId).then((response) => {
          setProjects(updateValueWithId(projectId, projects, response.data));
        });
      });

      setRepetitionQuestionDialogOpened(false);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="deactivation-dialog"
      fullWidth={true}
      maxWidth={"sm"}
      className="tb-default-dialog"
    >
      <DialogTitle>
        <p>{t("update")}</p>
        <img onClick={handleClose} src={Cross} />
      </DialogTitle>
      <DialogContent style={{ margin: "20px 0px" }}>
        <p>{t("planning.update_all_repetitions")}</p>
      </DialogContent>
      <DialogActions>
        <TeambookOutlinedButton onClick={handleYes} text={t("yes")} />
        <TeambookBlueButton onClick={handleNo} text={t("no")} />
      </DialogActions>
    </Dialog>
  );
};

export default RepetitionEditQuestionDialog;
