import React, { useState } from "react";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { useTranslation } from "react-i18next";
import TeambookScheduleComponent from "../../default_components/schedule/TeambookScheduleComponent";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../stores/notificationStore";
import TeambookRadioButton from "../../default_components/TeambookRadioButton";

const ScheduleComponent = ({ submitForm }) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [schedule, setSchedule] = useState(account.schedule);
  const [firstWeekDay, setFirstWeekDay] = useState(account.first_week_day);

  const submitValues = () => {
    if (schedule.map((day) => day[0] + day[2]).reduce((ps, a) => ps + a, 0) === 0) {
      setInAppNotification(t("notifications.settings.schedule.incorrect_hours"));
    } else {
      submitForm({
        accountName: account.name,
        defaultBookingTime: account.default_booking_duration / 60,
        timeZone: account.time_zone,
        firstWeekDay: firstWeekDay,
        schedule: schedule,
        dateFormat: account.date_format,
      });

      setInAppNotification(t("notifications.settings.schedule.schedule_update"));
    }
  };

  return (
    <div className="account-schedule">
      <div className="settings__edit-value">
        <div>
          <p className="settings__edit-header__text">{t("settings.general_info.first_day_of_the_week")}</p>

          {[
            { label: t("settings.general_info.monday"), value: "Monday" },
            { label: t("settings.general_info.sunday"), value: "Sunday" },
          ].map((day) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <TeambookRadioButton onCheck={() => setFirstWeekDay(day.value)} checked={firstWeekDay === day.value} />
                <p onClick={() => setFirstWeekDay(day.value)} style={{ marginBottom: 0, userSelect: "none" }}>
                  {day.label}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <TeambookScheduleComponent schedule={schedule} setSchedule={setSchedule} kind={"edit"} />

      <p style={{ fontSize: "16px", textAlign: "left", marginTop: "20px" }}>{t("settings.schedule.important")}</p>

      <TeambookBlueButton
        onClick={submitValues}
        className="settings__submit"
        variant="contained"
        style={{ position: "absolute", right: "50px", bottom: "49px" }}
        text={t("save")}
      >
        {t("save")}
      </TeambookBlueButton>
    </div>
  );
};

export default ScheduleComponent;
