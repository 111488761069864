import React, { useState, useEffect, useRef } from "react";
import Api from "../../../Api";
import Header from "../../header/Header";
import PlannerControlBar from "../default_components/PlannerControlBar";
import NoMatch from "../../default_components/NoMatch";
import { useNavigate } from "react-router-dom";
import {
  GetWeekMonday,
  ToDateString,
  OffsetDays,
  getNumberOfDays,
  GetStartDateForPlannerDate,
  getCellWidthForZoom,
} from "../../../lib/DateUtils";
import "../planner.scss";
import EditWindow from "../default_components/planner_right_side_modal/EditWindow";
import CreateWindow from "../default_components/planner_right_side_modal/CreateWindow";
import MoveBookingRequest from "../requests/MoveBookingRequest";
import CreateBookingsRequest from "../requests/CreateBookingsRequest";
import UpdateBookingsRequest from "../requests/UpdateBookingsRequest";
import DeleteBookingsRequest from "../requests/DeleteBookingsRequest";
import AddUserDialog from "../modals/AddUserDialog";
import GetBookingsRequest from "../requests/GetBookingsRequest";
import filterUsers from "../lib/filterUsers";
import RepetitionDeleteQuestionDialog from "../modals/RepetitionDeleteQuestionDialog";
import OnboardingComponent from "../onboarding/OnboardingComponent";
import LoadingComponent from "../../default_components/LoadingComponent";
import PlannerDispatcher from "../lib/PlannerDispatcher";
import TeamChangedActions from "../lib/TeamChangedActions";
import RepetitionEditQuestionDialog from "../modals/RepetitionEditQuestionDialog";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import NoTeamsPlanner from "../../default_components/NoTeamsPlanner";
import RepetitionReachedModal from "../modals/RepetitionReachedModal";
import GetTeamsRequest from "../requests/GetTeamsRequest";
import ActionCable from "actioncable";
import GetActiveProjects from "../requests/GetActiveProjects";
import TeamSelect from "../default_components/TeamSelect";
import BookingTooltip from "../default_components/BookingTooltip";
import {
  GROUPED_PLANNER_USER_WIDTH,
  getPlannerOffsetX,
  getPlannerOffsetY,
  updateValueWithId,
  usersMaxScheduleTime,
} from "../../../lib/PlannerUtils";
import DidYouKnow from "../default_components/DidYouKnow";
import MoveQuestionDialog from "../modals/MoveQuestionDialog";
import TooltipChanger from "../lib/TooltipChanger";
import PlanningBlocked from "../../default_components/PlanningBlocked";
import MultipleSelectHelp from "../default_components/MultipleSelectHelp";
import ProjectGroup from "./components/ProjectGroup";
import GroupedUserRow from "./components/GroupedUserRow";
import MilestoneCreation from "./components/MilestoneCreation";
import MilestoneEditing from "./components/MilestoneEditing";
import AddUserGroupedDialog from "./components/AddUserGroupedDialog";
import moment from "moment";
import BookingRightClick from "../default_components/BookingRightClick";
import {
  useBookingsCreatingStore,
  useBookingsEditingStore,
  useBookingsStore,
  useCopyBookingsStore,
  useShiftPressedStore,
  useDateStore,
  useMilestonesStore,
  useSelectedProjectStore,
  useTeambookFilter,
  useZoomStore,
  useFilteredUsersStore,
} from "../../../stores/planner";
import { filterProjects } from "../lib/filterProjects";
import { PlannerContext } from "../context/PlannerContext";
import lsKeys from "../../default_values/defaultKeys";
import orderedProjects from "../lib/orderProjects";
import { GroupedPlannerGrid, VirtualizedGroupedPlanner } from "./GroupedPlannerGrid";
import $ from "jquery";
import { shallow } from "zustand/shallow";
import { UserFeedbackBanner } from "../../default_components/UserFeedbackBanner";
import { SelectBookingsArea } from "../users_planner/components/SelectBookingsArea";
import socketHandler from "../users_planner/socket/socketHandler";
import { DateTime } from "luxon";
import { WeekendInfo } from "../default_components/WeekendInfo";
import { useProfileStore } from "../../../stores/profileStore";
import { useAccountStore } from "../../../stores/accountStore";
import AddProjectDialog from "../modals/AddProjectDialog";
import { useNotificationStore } from "../../../stores/notificationStore";
import PlannerRightClick from "../default_components/PlannerRightClick";
import { SwitchToPaidPlanBanner } from "../../default_components/SwitchToPaidPlanBanner";
import TeambookDialogYesNo from "../../default_components/TeambookDialogYesNo";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import GroupedPlannerActions from "../lib/basic_planner_actions/GroupedPlannerActions";
import { analyzeCreateResponse } from "../lib/request_helpers/createRequestHelpers";
import PlannerInfoModal from "../default_components/PlannerInfoModal";

const LAST_TEAMS_ZOOM_KEY = "lastTeamsZoom";
const WEEKENDS_HIDDEN_KEY = "weekendsHidden";
const TOOLTIP_HIDDEN = "tooltipHidden";
const USER_ORDER = "groupedPlannerUserOrder";
const BOOKING_FORMAT = "bookingFormat";
const CONTROL_KEY = 17;
const APPLE_META_KEY = 91;

const formatMapping = {
  0: 5,
  1: 10,
  2: 15,
};

const PersistedState = {
  getLastTeamId: () => localStorage.getItem(lsKeys.LAST_TEAMID_KEY) || undefined,

  setLastTeamId: (id) => {
    if (!parseInt(id)) {
      localStorage.removeItem(lsKeys.LAST_TEAMID_KEY);
    } else {
      localStorage.setItem(lsKeys.LAST_TEAMID_KEY, id);
    }
  },

  getAllTeamZoom: () => new Map(JSON.parse(localStorage.getItem(LAST_TEAMS_ZOOM_KEY))),

  saveAllTeamZoom: (map) => localStorage.setItem(LAST_TEAMS_ZOOM_KEY, JSON.stringify(Array.from(map))),

  getTeamZoom: (teamId) => PersistedState.getAllTeamZoom().get(teamId),

  setTeamZoom: (teamId, zoom) => {
    const m = PersistedState.getAllTeamZoom();

    m.set(teamId.toString(), parseInt(zoom));

    PersistedState.saveAllTeamZoom(m);
  },
};

let bookingMovingHash = {};
let users = null;
let plannerDispatcher = new PlannerDispatcher();
let SERVER_ADDRESS;

let bookingsEditingClicks = [];
let dropOffsetX = 0;
let dropOffsetY = 0;
let ctrlPressed = false;
let milestoneMouseDowned = false;
let groupedProjectUsers = [];
let shownProjects = [];
let milestoneStartVariable = null;

switch (process.env.REACT_APP_ENV) {
  case "production":
    SERVER_ADDRESS = "https://web.teambookapp.com";
    break;
  case "staging":
    SERVER_ADDRESS = "https://web.teambooktest.com";
    break;
  default:
    SERVER_ADDRESS = "http://localhost:3001";
    break;
}

const cable = ActionCable.createConsumer(`${SERVER_ADDRESS}/api/cable`);
const plannerActions = new GroupedPlannerActions();

const GroupedPlanner = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const default_zoom = localStorage.getItem("default_zoom");

  const {
    creationOrder: milestoneCreationOrder,
    setCreationOrder: setMilestoneCreationOrder,
    milestoneIndex,
    setMilestoneIndex,
    milestoneStartDate,
    setMilestoneStartDate,
    milestoneEndDate,
    setMilestoneEndDate,
    milestoneProject,
    setMilestoneProject,
  } = useMilestonesStore();

  const [setShiftPressed, setStartX, setStartY, setStartSelected] = useShiftPressedStore(
    (state) => [state.setShiftPressed, state.setStartX, state.setStartY, state.setStartSelected],
    shallow
  );

  const [
    setCreateWindowOpened,
    addBookingToCreating,
    setCreationBookingDuration,
    clearBookingsFromCreate,
    setBookingsCreating,
    creationBookingDuration,
    bookingsCreating,
  ] = useBookingsCreatingStore(
    (state) => [
      state.setCreateWindowOpened,
      state.addBooking,
      state.setDuration,
      state.clearBookings,
      state.setBookings,
      state.bookingsDuration,
      state.bookingsCreating,
    ],
    shallow
  );

  const [setSelectedProject, setSelectedTimeOff, setProjectsType] = useSelectedProjectStore(
    (state) => [state.setSelectedProject, state.setSelectedTimeOff, state.setProjectsType],
    shallow
  );

  const [addBookingToEdit, clearBookingsFromEdit, setBookingsEditing, setEditWindowOpened, removeBookingFromEditing] =
    useBookingsEditingStore(
      (state) => [
        state.addBooking,
        state.clearBookings,
        state.setBookings,
        state.setEditWindowOpened,
        state.removeBooking,
      ],
      shallow
    );

  const [bookings, setBookings] = useBookingsStore((state) => [state.bookings, state.setBookings], shallow);

  const [zoom, setZoom] = useZoomStore((state) => [state.zoom, state.setZoom], shallow);
  const [date, setDate] = useDateStore((state) => [state.date, state.setDate], shallow);

  const { filterValues, setFilterValue, filterType } = useTeambookFilter();

  const [teams, setTeams] = useState();
  const [teamId, setTeamId] = useState();
  const { filteredUsers, setFilteredUsers } = useFilteredUsersStore();

  const [projects, setProjects] = useState();
  const [clients, setClients] = useState([]);
  const [tags, setTags] = useState([]);
  const [loadingBookings, setLoadingBookings] = useState(false);
  const [filters, setFilters] = useState({});
  const [weekendsHidden, setWeekendsHidden] = useState(!(localStorage.getItem(WEEKENDS_HIDDEN_KEY) === "false"));
  const [weekendStart, setWeekendStart] = useState(6);
  const [addProjectWindowOpened, setAddProjectWindowOpened] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [emptyFiltered, setEmptyFiltered] = useState(false);
  const [removeUser, setRemoveUser] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [repetitionQuestionDialogOpened, setRepetitionQuestionDialogOpened] = useState(false);
  const [repetitionEditQuestionDialogOpened, setRepetitionEditQuestionDialogOpened] = useState(false);
  const [onboardingOpened, setOnboardingOpened] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [userSwapId, setUserSwapId] = useState(null);
  const [userRows, setUserRows] = useState([]);
  const [editingData, setEditingData] = useState({});
  const [allUsers, setAllUsers] = useState();
  const [modalRepetitionReachedOpen, setModalRepetitionReachedOpen] = useState(false);
  const [activeProjects, setActiveProjects] = useState([]);
  const [teamNotFound, setTeamNotFound] = useState(false);
  const [emptyPlanner, setEmptyPlanner] = useState(false);
  const [alphabeticFilteredProjects, setAlphabeticFilteredProjects] = useState();
  const [selectTeamsOpened, setSelectTeamsOpened] = useState(false);
  const [moveQuestionDialogOpen, setMoveQuestionDialogOpen] = useState(false);
  const [bookingToMove, setBookingToMove] = useState({});
  const [disableTooltip, setDisableTooltip] = useState(localStorage.getItem(TOOLTIP_HIDDEN) === "true");
  const [userOrder, setUserOrder] = useState(localStorage.getItem(USER_ORDER));
  const [bookingFormat, setBookingFormat] = useState(localStorage.getItem(BOOKING_FORMAT) || 2);

  const [milestoneCreationOpen, setMilestoneCreationOpen] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [editingMilestone, setEditingMilestone] = useState();
  const [addUserGroupOpened, setAddUserGroupOpened] = useState(false);
  const [addUserProject, setAddUserProject] = useState();
  const [usersAdded, setUsersAdded] = useState([]);
  const [groupClients, setGroupClients] = useState(
    localStorage.getItem(lsKeys.GROUP_CLIENTS_PLANNER) === "true" || false
  );
  const [automaticMilestones, setAutomaticMilestones] = useState([]);
  const [taskArray, setTaskArray] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [addedProjects, setAddedProjects] = useState([]);
  const [addUserWindowOpened, setAddUserWindowOpened] = useState(false);
  const [deleteRepetitionModalOpen, setDeleteRepetitionModalOpen] = useState(false);

  const usersRef = useRef([]);
  const tooltipRef = useRef([]);
  const bookingRightClickMenuRef = useRef({});
  const didYouKnowRef = useRef({});
  const firstPlannerDate = GetStartDateForPlannerDate(date, zoom);
  const plannerRightClickRef = useRef();
  const createBookingsRef = useRef([]);
  const multipleSelectRef = useRef(null);
  const weekendInfoRef = useRef(null);

  const [profile] = useProfileStore((state) => [state.profile], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);

  if (zoom === 1) {
    setZoom(14);
  }

  const role = localStorage.getItem("tb-role") || "regular";

  const applyFilteredUsers = (users) => {
    setFilteredUsers(
      users.filter((user) => {
        const userDuration = user.schedule.map((schedule) => schedule[0] + schedule[2]);

        return userDuration.reduce((pr, cv) => pr + cv, 0) > 0;
      })
    );
  };

  window.onfocus = function () {
    setShiftPressed(false);
  };

  useEffect(() => {
    setShiftPressed(false);
    closeEditWindow();
    closeCreationWindow();
  }, []);

  useEffect(() => {
    if (account) {
      if (cable.subscriptions.subscriptions.length === 0 && Array.from(bookings.keys()).length > 1) {
        cable.subscriptions.create(
          { channel: "BookingsChannel", account_id: account.id },
          {
            received: (bookingsData) => handleSocketBookings(bookingsData),
          }
        );
      }
    }
  }, [account, bookings, profile]);

  // Fetch data
  useEffect(() => {
    if (!teamId) {
      GetTeamsRequest(setTeams, true);
      GetActiveProjects(setActiveProjects);
      Api.Tasks.get().then((res) => setTaskArray(res.data));
      Api.Projects.business_units().then((res) => {
        setBusinessUnits(res.data.business_units);
      });
    } else {
      TeamChangedActions(setClients, setTags, setOnboardingOpened, setAllUsers, setMilestones);
    }
  }, [teamId]);

  useEffect(() => {
    let users = teams?.find((team) => team.id === teamId)?.team_users;

    if (users?.length > 0) {
      GetBookingsRequest(setLoadingBookings, users, setProjects, date, zoom, setBookings);

      changeOrder(localStorage.getItem(USER_ORDER));
    } else if (users?.length === 0) {
      setProjects([]);
      setBookings([]);
    }
  }, [teams, teamId, zoom, date]);

  useEffect(() => {
    tooltipRef.current = [0];

    localStorage.setItem(lsKeys.LAST_PLANNER_TYPE, "grouped_planners");

    window.addEventListener("dragstart", startDrag, true);
    window.addEventListener("drop", changeDropLocation, true);
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Shift") {
        setShiftPressed(true);
      }
    });

    document.addEventListener("keyup", (e) => {
      if (e.key === "Shift") {
        if (!useShiftPressedStore.getState().startSelected) {
          setShiftPressed(false);
        }
      }
    });
  }, []);

  const closeAddUserWindow = () => {
    setAddUserWindowOpened(false);
  };

  const startDrag = (e) => {
    dropOffsetX = getPlannerOffsetX(e) - 35;
    dropOffsetY = getPlannerOffsetY(e, zoom);
  };

  const changeDropLocation = (e) => {
    e.preventDefault();
    dropOffsetX = getPlannerOffsetX(e) - 35;
    dropOffsetY = getPlannerOffsetY(e, zoom);
  };

  // Set current team id
  useEffect(() => {
    if (!teams) return;

    const savedLastId = localStorage.getItem(lsKeys.LAST_TEAMID_KEY) || teams[0]?.id;
    const firstInTeams = teams.keys().next().value;

    let id = props.id || (teams.some((team) => team.id === +savedLastId) ? savedLastId : undefined) || firstInTeams;

    if (!teams.some((team) => team.id === +id)) {
      id = null;
      setTeamNotFound(true);
    } else if (teams.find((team) => team.id === +id).team_users.length === 0) {
      setEmptyPlanner(true);
    }

    setTeamId(+id);

    setZoom(+default_zoom || PersistedState.getTeamZoom(id) || 28);
  }, [teams, props.id]);

  // Set current date
  useEffect(() => {
    if (date || !zoom) return;

    const d = new Date(props.date || new Date());

    let offsetDate = GetWeekMonday(OffsetDays(d, -14));

    if (zoom === 1) {
      offsetDate = new Date();
    } else if (zoom === 90) {
      offsetDate = GetWeekMonday(OffsetDays(d, -28));
    }

    setDate(!isNaN(d) ? offsetDate : undefined);
  }, [props.date, zoom]);

  // Save last opened team id
  useEffect(() => {
    if (teamId && teams.some((team) => team.id === teamId)) {
      PersistedState.setLastTeamId(teamId);
    }
  }, [teamId]);

  // Save team zoom
  useEffect(() => {
    if (zoom && teamId && teams.some((team) => team.id === teamId)) {
      PersistedState.setTeamZoom(teamId, +default_zoom || zoom);
    }
  }, [zoom]);

  // Make path
  useEffect(() => {
    if (!teamId || !date) return;

    navigate(`/grouped_planners/${teamId}/${ToDateString(GetWeekMonday(date))}`, { replace: true });
  }, [teamId, date]);

  useEffect(() => {
    if (filterValues.length > 0) {
      filterProjects(filterValues, clients, bookings, activeProjects, setFilteredProjects, t);
    } else {
      setFilteredProjects([]);
      setEmptyFiltered(false);
    }
  }, [filterValues, teamId, clients, bookings, projects, filterType, teams]);

  useEffect(() => {
    if (useSelectedProjectStore.getState().selectedProject === null) {
      if (activeProjects?.length > 0) {
        let project =
          activeProjects.filter((project) => project.kind !== "time_off" && project.active === true)[0] || null;

        setSelectedProject(project);
      }
    }
  }, [projects, activeProjects]);

  useEffect(() => {
    if (!projects || !teams || !teamId) {
      return;
    }

    if (userOrder === "a" || userOrder === "z") {
      if (filterValues.length > 0) {
        setAlphabeticFilteredProjects(
          sortAlphabetically(userOrder, "name", [
            ...addedProjects.filter((pr) => !projects.map((proj) => proj.id).includes(pr.id)),
            ...projects,
          ])
        );
      } else {
        setAlphabeticFilteredProjects(
          sortAlphabetically(userOrder, "name", [
            ...addedProjects.filter((pr) => !projects.map((proj) => proj.id).includes(pr.id)),
            ...projects,
          ])
        );
      }
    } else {
      setAlphabeticFilteredProjects([
        ...addedProjects.filter((pr) => !projects.map((proj) => proj.id).includes(pr.id)),
        ...projects,
      ]);
    }
  }, [userOrder, teams, teamId, filterValues, projects, addedProjects]);

  useEffect(() => {
    let dated_milestones = [];

    const findFreeOrder = (project) => {
      const milestoneOverlap = milestones.find((milestone) => {
        if (
          (project.id === milestone.project_id &&
            new Date(milestone.start_date).getTime() <= new Date(project.start_date).getTime() &&
            new Date(milestone.end_date).getTime() >= new Date(project.start_date).getTime()) ||
          (new Date(milestone.start_date).getTime() <= new Date(project.end_date).getTime() &&
            new Date(milestone.end_date).getTime() >= new Date(project.end_date).getTime())
        ) {
          return true;
        }
      });
      if (milestoneOverlap) {
        return milestoneOverlap.order + 1;
      } else {
        return 0;
      }
    };
    if (activeProjects) {
      activeProjects.forEach((project) => {
        if (project.start_date) {
          dated_milestones.push({
            start_date: project.start_date,
            end_date: project.start_date,
            project_id: project.id,
            tooltip: t("planning.project_start_date"),
            order: findFreeOrder(project),
          });

          dated_milestones.push({
            start_date: project.end_date,
            end_date: project.end_date,
            project_id: project.id,
            tooltip: t("planning.project_end_date"),
            order: findFreeOrder(project),
          });
        }
      });

      setAutomaticMilestones(dated_milestones.filter((milestone) => milestone.order === 0 || milestone.order === 1));
    }
  }, [milestones, activeProjects]);

  useEffect(() => {
    let rows = [];

    if (teams) {
      team = teams.find((team) => team.id === teamId);
    }

    if (filteredUsers.length === 0 && team?.team_users?.length && !emptyFiltered && team && profile) {
      if (role === "self_planner") {
        applyFilteredUsers([
          team.team_users.find((user) => user.id === profile.id),
          ...team.team_users.filter((user) => user.id !== profile.id),
        ]);
      } else if (team.team_users.length !== 0) {
        applyFilteredUsers(team.team_users);
      } else {
        setEmptyPlanner(true);
      }
    } else if (removeUser && filteredUsers.length > 0) {
      applyFilteredUsers(team.team_users);
      setRemoveUser(false);
      usersRef.current = [];
    } else if (addUser && filteredUsers.length > 0) {
      applyFilteredUsers(team.team_users);
      setAddUser(false);
    } else if (projects && teams && teamId && alphabeticFilteredProjects && profile) {
      users = filteredUsers.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)).flat();

      usersRef.current = [];
      createBookingsRef.current = [];

      let topOffset = 0;

      const bookingsArray = [...bookings];

      groupedProjectUsers = [];

      shownProjects = alphabeticFilteredProjects.filter((proj) => proj.kind !== "time_off" && proj.active);

      if (groupClients) {
        const clientsArray = clients
          .sort((c1, c2) => {
            if (c1.name > c2.name) {
              return -1;
            } else if (c1.name < c2.name) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((client) => client.id);

        shownProjects = shownProjects.sort((pr1, pr2) => {
          if (!pr1.client_id) {
            return 1;
          } else if (!pr2.client_id) {
            return -1;
          } else if (
            clientsArray.findIndex((number) => number === pr1.client_id) <
            clientsArray.findIndex((number) => number === pr2.client_id)
          ) {
            return 1;
          } else {
            return -1;
          }
        });
      }

      if (filteredProjects.length > 0) {
        shownProjects = shownProjects.filter((project) => filteredProjects.map((p) => p.id).includes(project.id));
      }

      let clientId = null;

      for (let i = 0; i < shownProjects.length; i++) {
        rows.push(
          <ProjectGroup
            project={shownProjects[i]}
            weekendsHidden={weekendsHidden}
            changeTooltip={changeTooltip}
            projectMouseDowned={projectMouseDowned}
            projectMouseMoved={projectMouseMoved}
            projectMouseUped={projectMouseUped}
            index={i}
            milestones={milestones.filter((milestone) => milestone.project_id === shownProjects[i].id)}
            openEditMilestone={openEditMilestone}
            openAddUserToGroup={openAddUserToGroup}
            style={{
              top: 0,
              left: 0,
            }}
            automaticMilestones={automaticMilestones.filter(
              (milestone) => milestone.project_id === shownProjects[i].id
            )}
            milestoneIndex={milestoneIndex}
            milestoneStartDate={milestoneStartDate}
            milestoneEndDate={milestoneEndDate}
            groupedProjectUsers={groupedProjectUsers}
            bookingFormat={bookingFormat}
            milestoneProject={milestoneProject}
            groupClients={groupClients}
            showClientDivider={clientId !== shownProjects[i]?.client_id}
            setMilestones={setMilestones}
          />
        );

        clientId = shownProjects[i]?.client_id;

        let userIds = bookingsArray
          .filter((booking) => booking.project_id === shownProjects[i].id)
          .map((booking) => booking.user_id)
          .filter((value, index, self) => self.indexOf(value) === index);

        let addedToProjectUserIds = usersAdded
          .filter((array) => array[0] === shownProjects[i].id)
          .map((data) => data[1]);

        let projectUsers = filteredUsers.filter((user) => {
          return userIds.includes(user.id);
        });

        if (addedToProjectUserIds.length > 0) {
          projectUsers.push(
            ...filteredUsers.filter((user) => addedToProjectUserIds.includes(user.id) && !userIds.includes(user.id))
          );
        }

        groupedProjectUsers.push(projectUsers);
        topOffset += 50;

        for (let j = 0; j < projectUsers.length; j++) {
          rows.push(
            <GroupedUserRow
              key={`user-row-${projectUsers[j].id}-${projectUsers.length}-${groupedProjectUsers.flat().length}`}
              index={getGroupedUserIndex(i, j)}
              userIndex={j}
              user={projectUsers[j]}
              setTeams={setTeams}
              setRemoveUser={setRemoveUser}
              mouseDowned={mouseDowned}
              mouseUped={mouseUped}
              weekendsHidden={weekendsHidden}
              deleteBookingsRequest={deleteBookingsRequest}
              ref={(el) => (usersRef.current[getGroupedUserIndex(i, j)] = el)}
              setUserSwapId={setUserSwapId}
              userSwapId={userSwapId}
              swapUsersRequest={swapUsersRequest}
              firstPlannerDate={firstPlannerDate}
              activeProjects={activeProjects}
              changeTooltip={changeTooltip}
              topOffset={0}
              mouseMoved={mouseMoved}
              selectBooking={selectBooking}
              addBookingToCreation={addBookingToCreation}
              tooltipRef={tooltipRef}
              filteredUsers={groupedProjectUsers.flat()}
              bookingFormat={bookingFormat}
              teams={teams}
              project={shownProjects[i]}
              usersAdded={usersAdded}
              changePlannerRightClickRef={changePlannerRightClickRef}
              changeContextMenu={changeContextMenu}
              updateBookingsRequest={updateBookingsRequest}
              groupedProjectUsers={groupedProjectUsers}
              moveBookingRequest={moveBookingRequest}
              virtualProps={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "fit-content",
                height: calculateHeight(projectUsers[j]),
                transform: `translateY(${topOffset}px)`,
              }}
              groupClients={groupClients}
              clients={clients}
              tasks={taskArray}
              weekendInfoRef={weekendInfoRef}
            />
          );

          topOffset += calculateTopOffset(projectUsers[j]);
        }
      }
    }

    if (alphabeticFilteredProjects) {
      setUserRows(rows);
    }

    plannerDispatcher = new PlannerDispatcher(usersRef, groupedProjectUsers.flat());
  }, [
    teams,
    teamId,
    projects,
    activeProjects,
    filteredUsers,
    userSwapId,
    weekendsHidden,
    disableTooltip,
    bookingFormat,
    filteredProjects,
    milestones,
    automaticMilestones,
    usersAdded,
    alphabeticFilteredProjects,
    profile,
    zoom,
    groupClients,
  ]);

  const handleSocketBookings = (bookingsData) => {
    const allBookings = useBookingsStore.getState().bookings;

    return socketHandler({
      socketData: bookingsData,
      date: date,
      zoom: zoom,
      bookings: allBookings,
      setBookings: setBookings,
      plannerDispatcher: plannerDispatcher,
      getUserIndex: getUserIndex,
      usersRef: usersRef,
      teams: teams,
      teamId: teamId,
      projects: projects,
      activeProjects: activeProjects,
      setProjects: setProjects,
      setActiveProjects: setActiveProjects,
      userId: profile?.id,
    });
  };

  const addProjectsToScreen = (projects) => {
    setAddedProjects([...addedProjects, ...projects.filter((pr) => projects.map((proj) => proj.id).includes(pr.id))]);
    setAddProjectWindowOpened(false);
  };

  const calculateFilteredUsers = () => {
    applyFilteredUsers(
      filterUsers(
        [],
        teams?.find((team) => team.id === teamId)?.team_users,
        setEmptyFiltered,
        clients,
        bookings,
        tags,
        projects,
        filterType,
        t
      )
    );
  };

  const calculateHeight = (projectUser) => {
    return `${calculateTopOffset(projectUser)}px`;
  };

  const calculateTopOffset = (projectUser) => {
    const userSchedule = projectUser.schedule.map((schedule) => schedule[0] + schedule[2]);

    return (Math.max(...userSchedule) / 60) * formatMapping[bookingFormat] + 17;
  };

  const getAllBookingsFromRefs = () => {
    return bookingsCreating;
  };

  const openAddUserToGroup = (project) => {
    setAddUserGroupOpened(true);
    setAddUserProject(project);
  };

  const addUsersToGroup = (newUsers) => {
    setUsersAdded([...usersAdded, ...newUsers.map((user) => [addUserProject.id, user.value])]);

    setAddUserGroupOpened(false);
  };

  const closeAddUserGroupWindow = () => {
    setAddUserGroupOpened(false);
  };

  const sortAlphabetically = (type, field, array) => {
    let filteredArray = [...array];

    if (type === "a") {
      filteredArray.sort((a, b) => (a[field].toUpperCase() > b[field].toUpperCase() ? 1 : -1));
    } else if (type === "z") {
      filteredArray.sort((a, b) => (a[field].toUpperCase() > b[field].toUpperCase() ? -1 : 1));
    }

    return filteredArray;
  };

  const swapUsersRequest = (secondUserId) => {
    Api.Teams.swap_users(teamId, userSwapId, secondUserId)
      .then((response) => {
        setTeams([...teams.filter((t) => t.id !== response.data.id), response.data]);
        team = teams.find((team) => team.id === teamId);
        applyFilteredUsers(response.data.team_users);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openAddProjectWindow = () => {
    setAddProjectWindowOpened(true);
  };

  const changeDate = (date) => {
    setDate(GetWeekMonday(OffsetDays(date, -14)));
    closeEditWindow();
    closeCreationWindow();
  };

  const changeTeam = (team_id) => {
    setTeamId(team_id);
    closeEditWindow();
    closeCreationWindow();
  };

  const openEditMilestone = (milestone) => {
    setEditingMilestone(milestone);
  };

  const planningBlocked = (account) => {
    return account?.active_projects > account?.subscription.projects_available;
  };

  const changeWeekendsHidden = (newState) => {
    setWeekendsHidden(newState);
    localStorage.setItem(WEEKENDS_HIDDEN_KEY, newState.toString());
  };

  const changeTooltipDisabling = (newState) => {
    setDisableTooltip(newState);
    localStorage.setItem(TOOLTIP_HIDDEN, newState.toString());
  };

  const changeOrder = (newOrder) => {
    setUserOrder(newOrder);
    localStorage.setItem(USER_ORDER, newOrder);
  };

  const getUserIndex = (userId) => {
    let indexes = [];
    groupedProjectUsers.flat().forEach((user, i) => {
      if (user.id === userId) {
        indexes.push(i);
      }
    });

    return indexes;
  };

  const closeCreationWindow = () => {
    clearBookingsFromCreate();
    createBookingsRef.current.forEach((ref) => {
      ref?.updateBookings([]);
    });
    setCreateWindowOpened(false);
  };

  const openCreationWindow = () => {
    if (
      activeProjects.filter((pr) => pr.id === useSelectedProjectStore.getState().selectedProject?.id)[0] === undefined
    ) {
      setSelectedProject(orderedProjects(projects)[0]);
    }

    if (!useShiftPressedStore.getState().shiftPressed) {
      setCreateWindowOpened(true);
    }
  };

  const changeTooltip = (newTooltip) => {
    TooltipChanger(newTooltip);
  };

  const changeContextMenu = (newMenu) => {
    bookingRightClickMenuRef.current.setOpen(newMenu.open);
    bookingRightClickMenuRef.current.setPosX(newMenu.posX);
    bookingRightClickMenuRef.current.setPosY(newMenu.posY);
    bookingRightClickMenuRef.current.setBooking(newMenu.booking);
  };

  const changePlannerRightClickRef = (newMenu) => {
    plannerRightClickRef.current.setOpen(newMenu.open);
    plannerRightClickRef.current.setPosX(newMenu.posX);
    plannerRightClickRef.current.setPosY(newMenu.posY);
    plannerRightClickRef.current.setCopyUserId(newMenu.userId);
    plannerRightClickRef.current.setCopyUserIndex(newMenu.index);

    let calculatedOffsetX = 0;
    let dateIteration = 0;

    let offsetX = newMenu.posX + $("#planner-scroll-list").scrollLeft();

    const dayWidth = getCellWidthForZoom(zoom);
    const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);

    if (groupClients) {
      offsetX -= 50;
    }

    while (calculatedOffsetX < offsetX - 30 - GROUPED_PLANNER_USER_WIDTH) {
      calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
      dateIteration++;
    }

    let copyDate = OffsetDays(date, dateIteration - 1);

    plannerRightClickRef.current.setCopyDate(copyDate);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === CONTROL_KEY || e.keyCode === APPLE_META_KEY) {
      ctrlPressed = true;
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === CONTROL_KEY || e.keyCode === APPLE_META_KEY) {
      ctrlPressed = false;
    }
  };

  const projectRowOffset = (offsetY) => {
    let startHeight = 0;
    let offset = 0;

    groupedProjectUsers.forEach((group, index) => {
      startHeight += 50;

      group.forEach((user) => {
        startHeight += (usersMaxScheduleTime(user) / 60) * formatMapping[bookingFormat] + 17;

        if (offsetY < startHeight && offset === 0) {
          offset = (index + 1) * 50;
        }
      });
    });

    return offset;
  };

  const projectMouseDowned = (offsetX, offsetY, index, project) => {
    if (groupClients) {
      offsetX -= 50;
    }

    const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);
    const dayWidth = getCellWidthForZoom(zoom);

    let calculatedOffsetX = 0;
    let dateIteration = 0;

    offsetX += $("#planner-scroll-list").scrollLeft();
    offsetX -= 35;

    while (calculatedOffsetX < offsetX) {
      calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
      dateIteration++;
    }

    milestoneStartVariable = OffsetDays(date, dateIteration - 1);

    setMilestoneStartDate(DateTime.fromJSDate(milestoneStartVariable));
    setMilestoneEndDate(DateTime.fromJSDate(milestoneStartVariable));
    setMilestoneCreationOrder(offsetY < 25 ? 0 : 1);
    setMilestoneIndex(index);
    setMilestoneProject(project);
    milestoneMouseDowned = true;
  };

  const bookingById = (id) => useBookingsStore.getState().bookings.find((b) => b.id === id);

  const projectMouseMoved = (offsetX, projectId) => {
    if (groupClients) {
      offsetX -= 50;
    }

    let all_milestones = [...automaticMilestones, ...milestones];

    if (milestoneMouseDowned) {
      offsetX -= 35;
      const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);
      const dayWidth = getCellWidthForZoom(zoom);

      let calculatedOffsetX = 0;
      let dateIteration = 0;

      offsetX += $("#planner-scroll-list").scrollLeft();

      while (calculatedOffsetX < offsetX) {
        calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
        dateIteration++;
      }

      if (moment.utc(date).subtract(1, "day").toDate() !== milestoneEndDate) {
        let finalDate = moment.utc(milestoneStartVariable).format("YYYY-MM-DD");
        const milestoneCreationOrder = useMilestonesStore.getState().creationOrder;

        let startDates = all_milestones
          .filter((milestone) => milestone.project_id === projectId)
          .filter((milestone) => milestone.order === milestoneCreationOrder)
          .map((milestone) => milestone.start_date);

        if (
          moment
            .utc(date)
            .add(dateIteration - 1, "day")
            .toDate() < milestoneStartVariable
        ) {
          setMilestoneEndDate(DateTime.fromJSDate(milestoneStartVariable));
          return;
        }

        while (
          finalDate !==
          moment
            .utc(date)
            .add(dateIteration - 1, "day")
            .format("YYYY-MM-DD")
        ) {
          if (startDates.includes(moment.utc(finalDate).add(1, "day").format("YYYY-MM-DD"))) {
            break;
          }

          finalDate = moment.utc(finalDate).add(1, "day").format("YYYY-MM-DD");
        }

        setMilestoneEndDate(DateTime.fromISO(finalDate));
      }
    }
  };

  const projectMouseUped = () => {
    milestoneMouseDowned = false;
    setMilestoneCreationOpen(true);
  };

  const mouseDowned = (offsetX, offsetY) => {
    plannerActions.mouseDowned(
      offsetX,
      offsetY,
      groupedProjectUsers,
      multipleSelectRef,
      weekendsHidden,
      bookingFormat,
      groupClients,
      projectRowOffset
    );
  };

  const mouseMoved = (offsetX, offsetY, e) => {
    plannerActions.mouseMoved(
      e,
      offsetX,
      offsetY,
      users,
      weekendsHidden,
      multipleSelectRef,
      bookingFormat,
      groupClients,
      groupedProjectUsers,
      zoom,
      projectRowOffset,
      closeEditWindow,
      shownProjects,
      activeProjects,
      projects
    );
  };

  const mouseUped = (offsetX, offsetY, users, e) => {
    plannerActions.mouseUped(
      offsetX,
      offsetY,
      users,
      e,
      multipleSelectRef,
      selectBooking,
      plannerDispatcher,
      usersRef,
      teams,
      teamId,
      closeEditWindow,
      openCreationWindow,
      groupClients,
      setSelectedProject,
      groupedProjectUsers,
      bookingFormat,
      shownProjects,
      activeProjects,
      projects,
      setProjectsType
    );
  };

  const addBookingToCreation = (userIndex, date) => {
    addBookingToCreating([userIndex, date]);
    setCreationBookingDuration(account?.default_booking_duration / 60);
    closeEditWindow();
    openCreationWindow();
  };

  const getGroupedUserIndex = (i, j) => {
    let finalIndex = j;

    groupedProjectUsers
      .map((array) => array.length)
      .forEach((length, index) => {
        if (index < i) {
          finalIndex += length;
        }
      });

    return finalIndex;
  };

  const selectBooking = (booking) => {
    if (useCopyBookingsStore.getState().copyModeActive) {
      // setCopyBookingId(booking.id);
    } else if (
      ctrlPressed &&
      useBookingsEditingStore.getState().bookingsEditing.some((editingBooking) => editingBooking.id === booking.id)
    ) {
      if (useBookingsEditingStore.getState().bookingsEditing.length === 1) {
        clearBookingsFromEdit();
        setEditWindowOpened(false);
      } else {
        removeBookingFromEditing(booking.id);
      }
    } else if (ctrlPressed || useShiftPressedStore.getState().shiftPressed) {
      addBookingToEdit(booking);
      setEditWindowOpened(true);
    } else {
      clearBookingsFromEdit();
      addBookingToEdit(booking);
      setEditWindowOpened(true);
      closeCreationWindow();
    }

    const project = projects.filter((pr) => pr.id === booking.project_id)[0];

    if (project) {
      if (project.kind === "time_off") {
        setSelectedTimeOff(project);
      } else {
        setSelectedProject(project);
      }
    } else {
      setSelectedProject(projects[0]);
    }
  };

  const deleteBookingsRequest = async (deletionBookings) => {
    if (deletionBookings.length > 1) {
      setInAppNotification(
        t("notifications.planning.bookings_deleted", {
          bookingsAmount: deletionBookings.length,
        })
      );
    }

    let skipQuestion = true;
    setBookingsEditing(deletionBookings);

    deletionBookings.forEach((booking) => {
      if (booking.repetition) {
        setRepetitionQuestionDialogOpened(true);
        skipQuestion = false;
      }
    });

    if (skipQuestion) {
      //TODO: fix booking deletion hashes shit
      DeleteBookingsRequest(
        deletionBookings,
        date,
        zoom,
        setBookings,
        closeCreationWindow,
        bookings,
        setEditWindowOpened,
        false,
        closeEditWindow,
        teams,
        teamId,
        usersRef,
        groupedProjectUsers.flat(),
        setProjects,
        setActiveProjects,
        projects,
        activeProjects
      );
    }
    TooltipChanger(tooltipRef.current[0]);
  };

  const deleteRepetitionRequest = () => {
    Api.Bookings.delete_repetition(useBookingsEditingStore.getState().bookingsEditing[0].id).then((res) => {
      const deletedBookingIds = res.data.deleted;
      const bookingLeft = res.data.booking_left;

      let newBookings = bookings.filter((booking) => !deletedBookingIds.includes(booking.id));

      res.data.repetition_bookings.forEach((booking) => {
        newBookings[newBookings.findIndex((b) => b.id === booking.id)] = booking;
      });

      newBookings[newBookings.findIndex((b) => b.id === bookingLeft.id)] = bookingLeft;

      setBookings(newBookings);

      const userWithBookingsUpdated = groupedProjectUsers
        .flat()
        .map((user, i) => (res.data.user_ids.includes(user.id) ? i : null))
        .filter((u) => u !== null);

      plannerDispatcher.afterCreationBookingAssignment(userWithBookingsUpdated);

      Api.Projects.get(bookingLeft.project_id).then((response) => {
        if (response.data.estimated) {
          setProjects(updateValueWithId(bookingLeft.project_id, projects, response.data));
          setActiveProjects(updateValueWithId(bookingLeft.project_id, activeProjects, response.data));
        }
      });

      closeEditWindow();
    });
  };

  const deleteRepetitionClick = () => {
    setDeleteRepetitionModalOpen(true);
  };

  const repetitionChanged = (repetition, bookingIds, booking) => {
    if (bookingIds.length > 1) return false;

    if (repetition.on === false) {
      deleteRepetitionClick();
      return false;
    }

    const bookingRepetition = booking.repetition[0];

    if (repetition.type === bookingRepetition.kind) {
      if (
        repetition.ends_after &&
        repetition.ends_after === bookingRepetition.ends_after &&
        repetition.every === bookingRepetition.every
      ) {
        return false;
      }

      if (repetition.ends_on && repetition.ends_on === bookingRepetition.ends_on) {
        return false;
      }
    }

    return true;
  };

  const updateBookingsRequest = (
    duration,
    tentative,
    location,
    startTime,
    comment,
    usesBudget,
    bookingIds,
    bookingProject,
    task,
    repetition
  ) => {
    let skipQuestion = true;
    let bookingsToEdit;
    let projectOfEditingBooking;

    if (bookingIds) {
      bookingsToEdit = bookings.filter((b) => bookingIds.includes(b.id));
      projectOfEditingBooking = bookingProject;
    } else {
      bookingsToEdit = useBookingsEditingStore.getState().bookingsEditing;

      projectOfEditingBooking =
        useSelectedProjectStore.getState().projectsType === "default"
          ? useSelectedProjectStore.getState().selectedProject
          : useSelectedProjectStore.getState().selectedTimeOff;
    }

    bookingsToEdit.forEach((booking) => {
      if (booking.repetition) {
        setRepetitionEditQuestionDialogOpened(true);
        skipQuestion = false;

        let oldEditData = {};

        oldEditData.projectId = projectOfEditingBooking.id;
        oldEditData.duration = duration;
        oldEditData.tentative = tentative;
        oldEditData.location = location;
        oldEditData.startTime = startTime;
        oldEditData.comment = comment;
        oldEditData.bookingsToUpdate = bookingsToEdit;
        oldEditData.usesBudget = usesBudget;
        oldEditData.task_id = task;

        if (repetition && repetitionChanged(repetition, bookingsToEdit, booking)) {
          oldEditData.repetition = repetition;
        }

        setEditingData(oldEditData);
      }
    });

    if (skipQuestion) {
      UpdateBookingsRequest(
        projectOfEditingBooking.id,
        duration,
        tentative,
        location,
        startTime,
        comment,
        bookingsToEdit,
        useBookingsStore.getState().bookings,
        setBookings,
        closeEditWindow,
        false,
        usesBudget,
        task,
        repetition,
        date,
        zoom
      ).then((res) => {
        let bookingsToUpdate = bookingsToEdit;

        if (repetition) {
          bookingsToUpdate = res.data.bookings;
        }

        let projectIds = [projectOfEditingBooking.id];

        let updateIndexes = bookingsToUpdate.map((booking) => getUserIndex(booking.user_id)).flat();

        let userWithBookingsUpdated = groupedProjectUsers
          .flat()
          .filter((user, index) => updateIndexes.includes(index))
          .map((u) => u.id);

        userWithBookingsUpdated = groupedProjectUsers
          .flat()
          .map((user, i) => (userWithBookingsUpdated.includes(user.id) ? i : null))
          .filter((u) => u !== null);

        plannerDispatcher.afterCreationBookingAssignment(userWithBookingsUpdated);

        bookingsToUpdate.map((booking) => {
          if (!projectIds.includes(booking.project_id)) {
            projectIds.push(booking.project_id);
          }
        });

        projectIds.map((projectId) => {
          Api.Projects.get(projectId).then((response) => {
            if (response.data.estimated) {
              setProjects(updateValueWithId(projectId, projects, response.data));
              setActiveProjects(updateValueWithId(projectId, activeProjects, response.data));
            }
          });
        });

        analyzeCreateResponse(
          res,
          bookingsToUpdate.map((booking) => getUserIndex(booking.user_id)),
          plannerDispatcher
        );
      });
    }
  };

  const createBookingsRequest = (
    project,
    duration,
    tentative,
    startTime,
    comment,
    location,
    repetition,
    usesBudget,
    task,
    weekendCreating = false
  ) => {
    const bookingsToCreate = useBookingsCreatingStore.getState().bookingsCreating;

    if (!project.active) {
      setInAppNotification(t("notifications.planning.deactivated_creating"));
      return;
    }

    if (creationBookingDuration > 0 && creationBookingDuration < 24 && bookingsToCreate.length > 0) {
      let creationIndexes = bookingsToCreate.map((options) => options[0]);
      let userWithBookingsCreated = groupedProjectUsers
        .flat()
        .filter((user, index) => creationIndexes.includes(index))
        .map((u) => u.id);
      userWithBookingsCreated = groupedProjectUsers
        .flat()
        .map((user, i) => (userWithBookingsCreated.includes(user.id) ? i : null))
        .filter((u) => u !== null);

      CreateBookingsRequest(
        project,
        duration,
        tentative,
        startTime,
        comment,
        date,
        zoom,
        bookings,
        setBookings,
        weekendsHidden,
        repetition,
        groupedProjectUsers.flat(),
        teamId,
        location,
        bookingsToCreate,
        creationBookingDuration,
        usesBudget,
        task,
        weekendCreating,
        userWithBookingsCreated
      ).then((response) => {
        if (response.data.response.status === "limit_reached") {
          setModalRepetitionReachedOpen(true);
        }

        if (!!repetition.repetitionType) {
          setInAppNotification(t("notifications.planning.repetition"));
        } else if (response.data.response.bookings_amount > 1) {
          setInAppNotification(
            t("notifications.planning.bookings_created", {
              bookingsAmount: response.data.response.bookings_amount,
            })
          );
        }

        plannerDispatcher.afterCreationBookingAssignment(userWithBookingsCreated);

        clearBookingsFromCreate();
        closeCreationWindow();
        closeEditWindow();

        if (role === "self_planner") {
          let userIds = filteredUsers.map((user) => user.id);

          let usersCreating = Array.from(new Set(bookingsToCreate.map((b) => userIds[b[0]])));

          if (usersCreating.length > 1 || !usersCreating.includes(profile.id)) {
            setInAppNotification(t("notifications.planning.self_planner_creating_error"));
          }
        }

        Api.Projects.get(project.id).then((response) => {
          if (response.data.estimated) {
            setProjects(updateValueWithId(project.id, projects, response.data));
            setActiveProjects(updateValueWithId(project.id, activeProjects, response.data));
          }
        });

        analyzeCreateResponse(response, userWithBookingsCreated, plannerDispatcher);
      });
    }
  };

  const moveBookingRequest = (hash) => {
    const bookingsEditing = useBookingsEditingStore.getState().bookingsEditing;

    if (bookingsEditingClicks.length > 1) {
      setMoveQuestionDialogOpen(true);
    } else {
      MoveBookingRequest(
        date,
        zoom,
        useBookingsStore.getState().bookings,
        setBookings,
        bookingsEditing,
        users,
        closeEditWindow,
        teams,
        teamId,
        usersRef,
        groupedProjectUsers.flat(),
        hash
      ).then((userIdsToUpdateRow) => {
        let userIndexesToUpdate = groupedProjectUsers
          .flat()
          .map((user, i) => (userIdsToUpdateRow.includes(user.id) ? i : null))
          .filter((u) => u !== null);

        plannerDispatcher.afterCreationBookingAssignment(userIndexesToUpdate);
      });
    }
  };

  const closeEditWindow = () => {
    setEditWindowOpened(false);
    setBookingsEditing([]);
    bookingsEditingClicks = [];

    let elements = document.getElementsByClassName("blurred");

    Array.from(elements).forEach((element) => {
      element.classList.remove("blurred");
    });
  };

  const noBookingsTeam = () =>
    teams?.find((team) => team.id === teamId)?.team_users.length === 0 &&
    userRows?.length === 0 &&
    filterValues.length === 0;

  const zoomLoaded = zoom;
  const isLoaded = !!account && !!teams && !!teamId && !loadingBookings && !!bookings && !!projects && !!profile;
  const isFound = teamId && date;
  let team = null;

  if (redirect) {
    return <Navigate to="/login" />;
  }

  if (teamNotFound) {
    return <Navigate to="/team_not_found" />;
  }

  return (
    <PlannerContext.Provider
      value={{
        team: teams?.find((team) => team.id === teamId),
        filteredUsers: filteredUsers,
        projects: projects,
        clients: clients,
        users: allUsers,
        tags: tags,
        bookings: bookings,
        plannerDate: date,
        weekendsHidden: weekendsHidden,
        groupClients: groupClients,
      }}
    >
      <div
        style={{
          cursor: useCopyBookingsStore.getState().copyModeActive
            ? "url('../default_components/copy-white.svg')"
            : "unset",
        }}
        className="grouped-planner-page"
        onMouseUp={(e) => mouseUped(0, 0, [], e)}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
      >
        <Header didYouKnowRef={didYouKnowRef} setOnboardingOpened={setOnboardingOpened} page={"planning"} />

        {!disableTooltip && <BookingTooltip ref={(el) => (tooltipRef.current[0] = el)} />}

        {zoomLoaded && teams?.length > 0 && (!!projects || emptyPlanner) && (
          <>
            <PlannerControlBar
              teams={teams}
              teamId={teamId}
              account={account}
              changeDate={changeDate}
              filters={filters}
              setFilters={setFilters}
              calculateFilteredUsers={calculateFilteredUsers}
              plannerView={t("planning.select_by_grouped")}
              filterFields={[
                t("planning.filter_projects"),
                t("planning.filter_clients"),
                t("planning.filter_advanced"),
                t("projects.business_unit"),
              ]}
              role={role}
              setSelectTeamsOpened={setSelectTeamsOpened}
              changeTooltipDisabling={changeTooltipDisabling}
              bookingFormat={bookingFormat}
              setBookingFormat={setBookingFormat}
              disableTooltip={disableTooltip}
              weekendsHidden={weekendsHidden}
              changeWeekendsHidden={changeWeekendsHidden}
              plannerDispatcher={plannerDispatcher}
              setBookings={setBookings}
              usersRef={usersRef}
              setEditWindowOpened={setEditWindowOpened}
              closeEditWindow={closeEditWindow}
              groupedProjectUsers={groupedProjectUsers.flat()}
              groupClients={groupClients}
              setGroupClients={setGroupClients}
              activeProjects={activeProjects}
              businessUnits={businessUnits}
              openAddProjectWindow={openAddProjectWindow}
              filterPage={"groupedPlanner"}
            />
          </>
        )}

        {!isLoaded && !emptyPlanner && <LoadingComponent />}

        {teams && teams?.length < 1 && <NoTeamsPlanner />}

        {isLoaded && !isFound && !(!teams || teams?.length < 1) && <NoMatch />}

        {/*{filteredUsers.length === 0 && filterValues.length > 0 && <NoBookingsFiltered />}*/}

        {noBookingsTeam() && (
          <div style={{ top: 240 }} className="empty-filtered no-users-planner">
            <div className="empty-filtered-text">
              <p className="no-users-planner__text">{t("no_projects_planner")}</p>
              <TeambookBlueButton onClick={openAddProjectWindow} text={t("planning.capacity.add_project")} />
            </div>
            <TeambookIcon color="blue" name={icons.EMPTY_FILTER_IMAGE} />
          </div>
        )}

        {isFound && (isLoaded || emptyPlanner) && userRows?.length !== 0 && (
          <>
            {teams.length === 0 && <p>{t("planning.no_teams")}</p>}

            {teams.length > 0 && (
              <>
                <div className="grouped-planner-grid" id="planner-grid">
                  {planningBlocked(account) ? (
                    <PlanningBlocked />
                  ) : (
                    <GroupedPlannerGrid
                      overscan={20}
                      rows={userRows}
                      weekendsHidden={weekendsHidden}
                      weekendStart={weekendStart}
                      userOrder={userOrder}
                      changeOrder={changeOrder}
                      users={users}
                      cellSize={formatMapping[bookingFormat]}
                      multipleSelect={
                        <SelectBookingsArea
                          ref={multipleSelectRef}
                          mouseMoved={(e) =>
                            mouseMoved(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e)
                          }
                        />
                      }
                      groupClients={groupClients}
                    />
                  )}

                  <EditWindow
                    projects={activeProjects}
                    closeEditWindow={closeEditWindow}
                    deleteBookingsRequest={deleteBookingsRequest}
                    updateBookingsRequest={updateBookingsRequest}
                    taskArray={taskArray}
                    setTaskArray={setTaskArray}
                    deleteRepetitionClick={deleteRepetitionClick}
                    orderedPlannerUsers={filteredUsers}
                    createBookingsRequest={createBookingsRequest}
                    createBookingsRef={createBookingsRef}
                  />

                  <CreateWindow
                    projects={activeProjects}
                    closeCreationWindow={closeCreationWindow}
                    createBookingsRequest={createBookingsRequest}
                    createBookingsRef={createBookingsRef}
                    taskArray={taskArray}
                    setTaskArray={setTaskArray}
                    userList={groupedProjectUsers.flat()}
                    creationBookings={getAllBookingsFromRefs}
                    orderedPlannerUsers={groupedProjectUsers.flat()}
                  />
                </div>
              </>
            )}
          </>
        )}

        {isLoaded && <DidYouKnow ref={didYouKnowRef} />}

        <MoveQuestionDialog
          open={moveQuestionDialogOpen}
          bookingToMove={bookingToMove}
          setOpen={setMoveQuestionDialogOpen}
          bookingMovingHash={bookingMovingHash}
          setBookings={setBookings}
          bookingsEditingClicks={bookingsEditingClicks}
          users={users}
          closeEditWindow={closeEditWindow}
          teams={teams}
          teamId={teamId}
          usersRef={usersRef}
          filteredUsers={groupedProjectUsers.flat()}
          getUserIndex={getUserIndex}
          plannerDispatcher={plannerDispatcher}
        />

        {addProjectWindowOpened && (
          <AddProjectDialog
            handleClose={() => setAddProjectWindowOpened(false)}
            allProjects={activeProjects.filter((pr) => pr.kind !== "time_off" && pr.active === true)}
            visibleProjectIds={shownProjects.map((p) => p.id)}
            addProjects={addProjectsToScreen}
          />
        )}

        <RepetitionReachedModal
          isOpen={modalRepetitionReachedOpen}
          close={() => setModalRepetitionReachedOpen(false)}
        />

        <RepetitionDeleteQuestionDialog
          open={repetitionQuestionDialogOpened}
          setRepetitionQuestionDialogOpened={setRepetitionQuestionDialogOpened}
          setBookings={setBookings}
          closeCreationWindow={closeCreationWindow}
          setEditWindowOpened={setEditWindowOpened}
          closeEditWindow={closeEditWindow}
          teams={teams}
          teamId={teamId}
          usersRef={usersRef}
          filteredUsers={groupedProjectUsers.flat()}
          setProjects={setProjects}
          setActiveProjects={setActiveProjects}
          activeProjects={activeProjects}
        />

        <RepetitionEditQuestionDialog
          filteredUsers={groupedProjectUsers.flat()}
          open={repetitionEditQuestionDialogOpened}
          setRepetitionQuestionDialogOpened={setRepetitionEditQuestionDialogOpened}
          setBookings={setBookings}
          closeCreationWindow={closeCreationWindow}
          setEditWindowOpened={setEditWindowOpened}
          closeEditWindow={closeEditWindow}
          teams={teams}
          teamId={teamId}
          usersRef={usersRef}
          editingData={editingData}
          setProjects={setActiveProjects}
          plannerDispatcher={plannerDispatcher}
          projects={activeProjects}
        />

        <OnboardingComponent
          setOnboardingOpened={setOnboardingOpened}
          onboardingOpened={onboardingOpened}
          role={role}
          page={"planning"}
        />

        <MultipleSelectHelp />

        {!["contractor", "self_planner", "regular"].includes(role) && milestoneEndDate && (
          <MilestoneCreation
            project={milestoneProject}
            startDate={milestoneStartDate}
            setStartDate={setMilestoneStartDate}
            endDate={milestoneEndDate}
            setEndDate={setMilestoneEndDate}
            dialogOpened={milestoneCreationOpen}
            closeDialog={() => {
              setMilestoneCreationOpen(false);
              setMilestoneIndex(null);
            }}
            setMilestones={setMilestones}
          />
        )}

        {addUserGroupOpened && (
          <AddUserGroupedDialog
            addUsers={addUsersToGroup}
            handleClose={closeAddUserGroupWindow}
            project={addUserProject}
            teamId={teamId}
            teams={teams}
            allUsers={allUsers}
            calculateFilteredUsers={calculateFilteredUsers}
            setTeams={setTeams}
          />
        )}

        {editingMilestone && (
          <MilestoneEditing
            milestone={editingMilestone}
            setEditingMilestone={setEditingMilestone}
            setMilestones={setMilestones}
          />
        )}

        <TeambookDialogYesNo
          open={deleteRepetitionModalOpen}
          setOpen={setDeleteRepetitionModalOpen}
          question={t("planning.delete_repetition_question")}
          questionTitle={t("planning.delete_repetition_question_title")}
          yesCallback={deleteRepetitionRequest}
          noCallback={() => setDeleteRepetitionModalOpen(false)}
        />

        <WeekendInfo
          selectBooking={selectBooking}
          addBookingToCreation={addBookingToCreation}
          deleteBookingRequests={deleteBookingsRequest}
          ref={weekendInfoRef}
        />

        <PlannerRightClick
          ref={plannerRightClickRef}
          plannerDispatcher={plannerDispatcher}
          teams={teams}
          usersRef={usersRef}
        />

        <BookingRightClick
          deleteBookingsRequest={deleteBookingsRequest}
          ref={bookingRightClickMenuRef}
          setBookingsEditing={setBookingsEditing}
          closeEditWindow={closeEditWindow}
          changeTooltip={changeTooltip}
          updateBookingsRequest={updateBookingsRequest}
        />

        {teams && (
          <AddUserDialog
            setAddUser={setAddUser}
            open={addUserWindowOpened}
            handleClose={closeAddUserWindow}
            teamId={teamId}
            teams={teams}
            setTeams={setTeams}
          />
        )}

        <PlannerInfoModal />

        {profile && <UserFeedbackBanner />}
        {profile && <SwitchToPaidPlanBanner />}
      </div>
    </PlannerContext.Provider>
  );
};

export default GroupedPlanner;
