import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import StaffingRow from "./StaffingRow";
import PercentageRow from "./PercentageRow";
import { recalculateCapacityArray } from "../lib/recalculateCapacityArray";
import StaffingTotalRow from "./StaffingTotalRow";
import AddUsersDialog from "./AddUsersDialog";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import lsKeys from "../../default_values/defaultKeys";
import SpreadDialog from "../users_planning/SpreadDialog";
import YearSeparator from "../default_components/YearSeparator";

const usersWithData = (users, reservations) => {
  return users.filter((u) =>
    reservations
      .filter((ur) => ur.days_reserved > 0)
      .map((ur) => ur.user_id)
      .includes(u.id)
  );
};

const ProjectsBlock = ({
  project,
  date,
  capacityReservations,
  setCapacityReservations,
  prReservations,
  setPrReservations,
  users,
  jumpToUser,
  selectedViewType,
  projects,
}) => {
  const userRole = localStorage.getItem("tb-role") || "regular";
  const fullAccessRoles = ["admin", "planner"];
  const role = localStorage.getItem(lsKeys.ROLE);

  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);
  const [usersOpen, setUsersOpen] = useState(false);
  const [addUserOpened, setAddUserOpened] = useState(false);
  const [addedUsers, setAddedUsers] = useState([]);
  const [projectReservations, setProjectReservations] = useState([]);
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [spreadOpened, setSpreadOpened] = useState(false);

  useEffect(() => {
    setProjectReservations(prReservations.filter((cr) => cr.project_id === project.id));
  }, [prReservations]);

  useEffect(() => {
    updateCalculations(projectReservations.filter((cr) => cr.project_id === project.id));
  }, [projectReservations]);

  const handleFocus = (event) => event.target.select();

  const updateProjectReservations = () => {
    Api.CapacityReservations.get_project_reservations({
      project_ids: [project.id],
      start_date: date.toISODate(),
      end_date: date.plus({ years: 2 }).toISODate(),
    }).then((res) => {
      setProjectReservations([...res.data]);
    });
  };

  const addUsers = (users) => {
    setAddedUsers([...addedUsers, ...users]);
  };

  const updateCalculations = (reservations) => {
    setCalculatedDurations(recalculateCapacityArray(date, reservations, selectedViewType));
  };

  const projectStatusIcon = (project) => {
    switch (project.status) {
      case t("projects.on_going"):
        return icons.ON_GOING;
      case t("projects.to_be_confirmed"):
        return icons.QUESTION_SYMBOL;
      case t("projects.done"):
        return icons.CHECK;
    }
  };

  const switchUsersOpen = () => {
    setUsersOpen(!usersOpen);
  };

  const shownUsers = () => {
    const usersInCapacity = usersWithData(
      users,
      capacityReservations.filter((ur) => ur.project_id === project.id)
    );

    return [...usersInCapacity.filter((u) => !addedUsers.map((_u) => _u.id).includes(u.id)), ...addedUsers];
  };

  const updateReservation = (newValue, index) => {
    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      return;
    }

    let newCalculated = [...calculatedDurations];

    newCalculated[index] = newValue;

    Api.CapacityReservations.create_project_reservations({
      project_id: project.id,
      days_reserved: newValue,
      date: date.plus({ months: index }).toISODate(),
    }).then(() => {
      updateProjectReservations(index);
    });

    setCalculatedDurations(newCalculated);
  };

  const openSpreadMenu = () => {
    setSpreadOpened(true);
  };

  return (
    <div className="users-block">
      <div className="projects-block__headline">
        <div className="users-block__headline__user-name">
          <div className="flex">
            <div style={{ background: project.color }} className="project-list__project-color" />

            <p>{project.name}</p>
          </div>
          <div className="projects-block__headline-actions">
            {project.status && (
              <TeambookIcon
                style={{ cursor: "unset" }}
                name={projectStatusIcon(project)}
                tooltipTitle={project.status}
              />
            )}

            {["admin", "planner"].includes(userRole) && (
              <TeambookIcon
                color="darkblue_new"
                id="add-user-to-group"
                name={icons.ADD}
                onClick={() => setAddUserOpened(true)}
                tooltipTitle={t("planning.capacity.add_user")}
              />
            )}

            {fullAccessRoles.includes(role) && (
              <TeambookIcon
                color="darkblue_new"
                name={icons.SPREAD_NEW}
                onClick={openSpreadMenu}
                title={t("planning.spread")}
              />
            )}
          </div>
        </div>

        {[...Array(selectedViewType)].map((x, i, arr) => (
          <div
            style={{ borderRight: arr.length - 1 === i ? "1px solid var(--stroke)" : "", position: "relative" }}
            className="table-cell"
          >
            {fullAccessRoles.includes(role) ? (
              <input
                onChange={(event) => updateReservation(event.target.value >= 0 ? event.target.value : 0, i)}
                onFocus={handleFocus}
                onWheel={(e) => e.target.blur()}
                value={parseInt(calculatedDurations[i]) || 0}
                style={{ width: "100%", height: "100%" }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => e.preventDefault()}
              />
            ) : (
              <p>{parseInt(calculatedDurations[i])}</p>
            )}

            {date.plus({ months: i }).month === 12 && i !== selectedViewType - 1 && <YearSeparator />}
          </div>
        ))}
      </div>

      <StaffingTotalRow
        switchUsersOpen={switchUsersOpen}
        usersOpen={usersOpen}
        userReservations={capacityReservations.filter((cr) => cr.project_id === project.id)}
        date={date}
        selectedViewType={selectedViewType}
        project={project}
        users={users}
        addedUsers={addedUsers}
      />

      {usersOpen && (
        <StaffingRow
          date={date}
          userReservations={capacityReservations}
          setCapacityReservations={setCapacityReservations}
          users={users}
          jumpToUser={jumpToUser}
          project={project}
          selectedViewType={selectedViewType}
          addedUsers={addedUsers}
        />
      )}

      <PercentageRow
        date={date}
        userReservations={capacityReservations.filter((cr) => cr.project_id === project.id)}
        calculatedDurations={calculatedDurations}
        selectedViewType={selectedViewType}
        isLastProject={project.id === projects[projects.length - 1].id}
      />

      {addUserOpened && (
        <AddUsersDialog
          setDialogOpened={setAddUserOpened}
          users={users.filter(
            (user) =>
              !shownUsers()
                .map((u) => u.id)
                .includes(user.id)
          )}
          addUsers={addUsers}
        />
      )}

      {spreadOpened && (
        <SpreadDialog
          setDialogOpened={setSpreadOpened}
          date={date}
          project={project}
          updateReservations={updateProjectReservations}
        />
      )}
    </div>
  );
};

export default ProjectsBlock;
