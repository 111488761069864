import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { calculateRatioHeatStyles } from "../lib/calculateHeatStyles";
import { getCorrectTextLocale } from "../index";
import YearSeparator from "../default_components/YearSeparator";

const PercentageRow = ({ date, userReservations, calculatedDurations, selectedViewType, isLastProject }) => {
  const { t } = useTranslation();
  const [ratioArray, setRationArray] = useState([]);

  useEffect(() => {
    let newArray = [];

    [...Array(selectedViewType)].forEach((x, i) => {
      newArray.push(calculateRatio(i));
    });

    setRationArray(newArray);
  }, [calculatedDurations, userReservations]);

  const calculateRatio = (i) => {
    const calculatedDate = date.plus({ months: i }).toISODate();

    const userHours = userReservations
      .filter((cr) => DateTime.fromISO(cr.date).toISODate() === calculatedDate)
      .map((cr) => cr.days_reserved)
      .reduce((ps, a) => ps + a, 0);

    const projectHours = calculatedDurations[i];

    let resultingRation = "0%";

    if (projectHours === 0 && userHours !== 0) {
      resultingRation = "INF";
    } else if (userHours / projectHours >= 0) {
      resultingRation = `${((userHours / projectHours) * 100).toFixed(0)}%`;
    }

    return resultingRation;
  };

  return (
    <div className="flex project-row">
      <div style={{ borderBottomLeftRadius: isLastProject ? 7 : 0 }} className="users-block__project-info greyed">
        <p className="col-md-5 font-italic">Ratio</p>
      </div>

      {[...Array(selectedViewType)].map((x, i, arr) => {
        const styleBg = calculateRatioHeatStyles(parseInt(ratioArray[i]));
        const toolTipMessage = getCorrectTextLocale(styleBg, t);

        return (
          <div
            style={{
              borderBottomRightRadius: isLastProject && arr.length - 1 === i && 7,
            }}
            className={`table-cell font-italic greyed ${styleBg}`}
            title={toolTipMessage}
          >
            <p>{ratioArray[i]}</p>

            {date.plus({ months: i }).month === 12 && i !== selectedViewType - 1 && <YearSeparator />}
          </div>
        );
      })}
    </div>
  );
};

export default PercentageRow;
