import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { useBookingsStore, usePlannerInfoModal } from "../../../stores/planner";
import React, { useEffect, useState } from "react";
import Api from "../../../Api";

const PlannerInfoModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [discardedBookings, setDiscardedBookings] = useState([]);

  useEffect(() => {
    setModalOpen(usePlannerInfoModal.getState().infoModalOpened);
    setModalType(usePlannerInfoModal.getState().infoModalData[0]?.type);
  }, [usePlannerInfoModal.getState().infoModalOpened]);

  const closeInfoModal = () => {
    if (modalType === "not_enough_space" && discardedBookings.length > 0) {
      Api.Bookings.delete(
        discardedBookings.map((b) => b.bookingId),
        false
      )
        .then((response) => {
          const newBookings = useBookingsStore
            .getState()
            .bookings.filter((booking) => response.data.deleted_bookings.map((b) => b.id).indexOf(booking.id) === -1);

          useBookingsStore.getState().setBookings(newBookings);

          const dispatcher = discardedBookings[0].dispatcher;
          const indexes = discardedBookings[0].indexes;

          dispatcher.afterCreationBookingAssignment(indexes);
        })
        .finally(() => {
          goToNextInfoMessage();
        });
    } else {
      goToNextInfoMessage();
    }
  };

  const goToNextInfoMessage = () => {
    if (usePlannerInfoModal.getState().infoModalData.filter((data) => data.type !== modalType).length > 0) {
      console.log("WWW");
      usePlannerInfoModal
        .getState()
        .setInfoModalData(usePlannerInfoModal.getState().infoModalData.filter((data) => data.type !== modalType));
      setModalType(usePlannerInfoModal.getState().infoModalData.filter((data) => data.type !== modalType)[0]?.type);
    } else {
      setModalOpen(false);
      usePlannerInfoModal.getState().setInfoModalOpened(false);
      setDiscardedBookings([]);
    }
  };

  const discardBooking = (bookingId, indexes, dispatcher) => {
    setDiscardedBookings([...discardedBookings, { bookingId, indexes, dispatcher }]);
  };

  const data = () => {
    return usePlannerInfoModal.getState().infoModalData.filter((data) => data.type === modalType);
  };

  const infoModalTitle = {
    skipped: "Some dates were skipped during creation",
    not_enough_space: "Overtime created!",
  };

  const dialogContents = {
    skipped: (
      <div>
        {data().map((skippedBooking, index) => {
          return (
            <p key={index}>
              <b>{skippedBooking.userName}</b> - {skippedBooking.message}
            </p>
          );
        })}
      </div>
    ),
    not_enough_space: (
      <div className="planner-info-modal__not-enough">
        {data().map((skippedBooking, index) => {
          return (
            <div className="planner-info-modal__not-enough-element">
              <p key={index} className="planner-info-modal__not-enough__text">
                <b>{skippedBooking.userName}</b> - {skippedBooking.message}
              </p>

              <TeambookBlueButton
                text={"Discard"}
                onClick={() =>
                  discardBooking(
                    skippedBooking.bookingId,
                    skippedBooking.indexesWithBookingsCreated,
                    skippedBooking.plannerDispatcher
                  )
                }
                disabled={discardedBookings.filter((b) => b.bookingId === skippedBooking.bookingId).length > 0}
              />
            </div>
          );
        })}
      </div>
    ),
  };

  return (
    <Dialog open={modalOpen} className="tb-default-dialog" onClose={closeInfoModal}>
      <DialogTitle>
        <p>{infoModalTitle[modalType]}</p>
      </DialogTitle>

      <DialogContent>
        <div>{dialogContents[modalType]}</div>
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton onClick={closeInfoModal} text={"OK"} />
      </DialogActions>
    </Dialog>
  );
};

export default PlannerInfoModal;
