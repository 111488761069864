import { DateTime } from "luxon";

export const calculateHeatStyles = (account, user, date, value) => {
  if (value === 0) return "black-bg";

  if (!account || !user) return "";

  const daysDuration = calculateDaysInMonth(date, user, account);

  if (daysDuration < value) return "red-bg";

  if (daysDuration > value) return "yellow-bg";

  if (daysDuration === value) return "grey-bg";

  return "";
};

export const calculateProjectHeatStyles = (user, date, value, userValue) => {
  if (value === 0) return "";

  if (userValue > value) return "red-bg";

  if (userValue < value) return "yellow-bg";

  return "";
};

export const calculateRatioHeatStyles = (ratio) => {
  if (!ratio) return "";

  if (ratio > 100) return "red-bg";

  if (ratio < 100) return "yellow-bg";

  return "";
};

export const calculateDaysInMonth = (date, user, account) => {
  if (!account) return 0;

  let daysCount = date.endOf("month").day;

  const startDateAhead =
    user.start_date && date.startOf("month").ts <= DateTime.fromISO(user?.start_date).startOf("month").ts;
  const endDateBehind =
    user.end_date && date.startOf("month").ts >= DateTime.fromISO(user?.end_date).startOf("month").ts;

  if (user.custom_working_hours) {
    const workingDays = user.schedule.reduce((prev, curr) => prev + (curr[0] + curr[2] > 0 ? 1 : 0), 0);
    const averageHoursPerDay = user.schedule.reduce((prev, curr) => prev + curr[0] + curr[2], 0) / 60 / workingDays;

    if (!startDateAhead && !endDateBehind) {
      return user.custom_working_hours / averageHoursPerDay;
    }
  }

  if (user.start_date && DateTime.fromISO(user?.start_date).startOf("month").ts === date.startOf("month").ts) {
    daysCount = date.endOf("month").day - DateTime.fromISO(user.start_date).day;
  }

  if (user.end_date && DateTime.fromISO(user?.end_date).startOf("month").ts === date.startOf("month").ts) {
    daysCount = DateTime.fromISO(user.end_date).day;
  }

  if (user.start_date && date.startOf("month").ts < DateTime.fromISO(user?.start_date).startOf("month").ts) {
    daysCount = 0;
  }

  if (user.end_date && date.startOf("month").ts > DateTime.fromISO(user?.end_date).startOf("month").ts) {
    daysCount = 0;
  }

  return [...Array(daysCount)].reduce((prev, _, index) => {
    const userSchedule = user.schedule[(date.weekday + index) % 7][0] + user.schedule[(date.weekday + index) % 7][2];

    const accountSchedule =
      account.schedule[(date.weekday + index) % 7][0] + account.schedule[(date.weekday + index) % 7][2];

    if (userSchedule && accountSchedule) {
      prev += userSchedule / accountSchedule;
    }

    return prev;
  }, 0);
};
