import React from "react";
import "./projection.scss";
import { SliceStringWithDots } from "../../lib/StringFormat";
import ProjectionUserBookings from "./ProjectionUserBookings";

const ProjectionUserRows = ({ users, bookings, date, isClientSharing }) => {
  return users.map((user, key) => (
    <div className={`projection__user-row ${isClientSharing && key === 0 && "first-row"}`}>
      <div className="projection__user-column">
        <p>
          {SliceStringWithDots(user.first_name, 9)} {SliceStringWithDots(user.last_name, 14)}
        </p>
      </div>

      <ProjectionUserBookings user={user} bookings={bookings} date={date} isClientSharing={isClientSharing} />
    </div>
  ));
};

export default ProjectionUserRows;
