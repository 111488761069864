import { useTranslation } from "react-i18next";
import { DaysOfTheWeek, getWeekNumber, MonthsOfTheYear, ToDateString } from "../../../lib/DateUtils";
import { WhiteTooltip } from "../../default_components/Tooltips";
import React, { useState } from "react";
import { useZoomStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";

const DateCell = ({ date, allRowsCreation, displayAsRedText, weekendsHidden, isLastCell }) => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  let dateText = `${DaysOfTheWeek()[date.getDay()]} ${date.getDate()}`;
  const today = new Date();

  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  if (zoom === 90) {
    dateText = `${date.getDate()}`;
  }

  const handleClose = () => {
    setTooltipOpen(false);
  };

  const handleOpen = (e) => {
    setTooltipOpen(window.innerWidth - e.clientX > 330);
  };

  if (ToDateString(date) === ToDateString(today)) {
    return (
      <WhiteTooltip title={`${t("today")}, ${t("planning.calendar_week")} - ${getWeekNumber(date)} `} placement="top">
        <div
          className={`date-cell-header-${zoom} date-row__date-cell__today`}
          onClick={() => allRowsCreation(date)}
          id={`date-header-${ToDateString(date)}`}
        >
          <p className="date-row__date-text__today">{dateText}</p>

          {/* {monthName(zoom, date, t)} */}
        </div>
      </WhiteTooltip>
    );
  } else if (date < today) {
    return (
      <WhiteTooltip
        title={`${MonthsOfTheYear(t)[date.getMonth()]}, ${t("planning.calendar_week")} - ${getWeekNumber(date)}`}
        placement="top"
      >
        <div
          className={`date-cell-header-${zoom} date-row__date-cell__past`}
          onClick={() => allRowsCreation(date)}
          id={`date-header-${ToDateString(date)}`}
          style={{
            borderRight:
              date.getDay() === 0
                ? "1px solid var(--stroke)"
                : date.getDay() === 5 && !weekendsHidden && "1px solid var(--stroke)",
            backgroundColor: [6, 0].includes(date.getDay()) && "var(--background-2)",
          }}
        >
          <p
            className="date-row__date-text"
            style={{
              color: displayAsRedText ? "var(--text-1)" : "var(--text-13)",
            }}
          >
            {dateText}
          </p>

          <div className="planner-overlap" />
          {/* {monthName(zoom, date, t)} */}
        </div>
      </WhiteTooltip>
    );
  }

  return (
    <div
      className={`date-cell-header-${zoom} date-row__date-cell`}
      id={`date-header-${ToDateString(date)}`}
      style={{
        borderRight:
          date.getDay() === 0
            ? "1px solid var(--stroke)"
            : date.getDay() === 5 && !weekendsHidden && "1px solid var(--stroke)",

        backgroundColor: [6, 0].includes(date.getDay()) && "var(--background-2)",
      }}
    >
      <WhiteTooltip
        title={`${MonthsOfTheYear(t)[date.getMonth()]}, ${t("planning.calendar_week")} - ${getWeekNumber(date)}`}
        open={tooltipOpen}
        onClose={() => handleClose()}
        onOpen={(e) => handleOpen(e)}
        placement="top"
      >
        <p className="date-row__date-text" onClick={() => allRowsCreation(date)}>
          {dateText}
        </p>
      </WhiteTooltip>

      {/* {monthName(zoom, date, t)} */}
    </div>
  );
};

export default DateCell;
