import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTeambookFilter } from "../../../../../stores/planner";
import UsersProfileImage from "../../../../default_components/UsersProfileImage";

const SatOverviewPage = ({ stats }) => {
  const { t } = useTranslation();
  const [userRows, setUserRows] = useState([]);
  const { filterValues } = useTeambookFilter();
  //"name, capacity, billable, tentative, non-bill, holiday, time_off, free, availability"

  useEffect(() => {
    let newRows = stats?.users
      ?.filter((user) => filterValues.length === 0 || userIsFiltered(user))
      .map((user) => (
        <div className="overview-table__data">
          <div className="overview-table__entity-name">
            <UsersProfileImage userImage={user?.avatars ? user.avatars["100"] : null} size={24} user={user} />
            {user.name}
          </div>
          <div className="overview-table__entity-capacity">{user.capacity}</div>
          <div className="overview-table__entity-billable">{user.billable}</div>
          <div className="overview-table__entity-tentative">{user.tentative}</div>
          <div className="overview-table__entity-non-billable">{user.non_billable}</div>
          <div className="overview-table__entity-billable-tentative">{user.holiday}</div>
          <div className="overview-table__entity-time-off">{user.time_off}</div>
          <div className="overview-table__entity-time-off-tentative">{user.free}</div>
          <div className="overview-table__entity-availability">{user.availability}</div>
        </div>
      ));

    if (filterValues.length === 0) {
      newRows?.push(
        <div className="overview-table__data">
          <div style={{ justifyContent: "center", fontSize: 20 }} className="overview-table__entity-name">
            {t("dashboard.totals")}
          </div>
          <div className="overview-table__entity-capacity">
            {stats?.users?.map((user) => user.capacity).reduce((ps, a) => ps + a, 0)}
          </div>
          <div className="overview-table__entity-billable">
            {stats?.users?.map((user) => user.billable).reduce((ps, a) => ps + a, 0)}
          </div>
          <div className="overview-table__entity-tentative">
            {stats?.users?.map((user) => user.tentative).reduce((ps, a) => ps + a, 0)}
          </div>
          <div className="overview-table__entity-non-billable">
            {stats?.users?.map((user) => user.non_billable).reduce((ps, a) => ps + a, 0)}
          </div>
          <div className="overview-table__entity-billable-tentative">
            {stats?.users?.map((user) => user.holiday).reduce((ps, a) => ps + a, 0)}
          </div>
          <div className="overview-table__entity-time-off">
            {stats?.users?.map((user) => user.time_off).reduce((ps, a) => ps + a, 0)}
          </div>
          <div className="overview-table__entity-time-off-tentative">
            {stats?.users?.map((user) => user.free).reduce((ps, a) => ps + a, 0)}
          </div>
          <div className="overview-table__entity-availability">
            {stats?.users?.map((user) => user.availability).reduce((ps, a) => ps + a, 0)}
          </div>
        </div>
      );
    }

    setUserRows(newRows);
  }, [stats, filterValues]);

  const userIsFiltered = (user) => {
    const filteredUsers = filterValues.filter((value) => value.type === t("planning.filter_users"));

    if (filteredUsers.length > 0) {
      return filteredUsers.map((r) => r.id).includes(user.id);
    } else {
      return true;
    }
  };

  return (
    <div className="reporting__overview-page">
      <div className="overview-row">
        <div className="side-columns" style={{ borderLeft: "1px solid var(--stroke)" }}>
          <p></p>
        </div>
        <div className="data-columns">
          <p>Capacity</p>
        </div>
        <div className="data-columns">
          <p>Billable</p>
        </div>
        <div className="data-columns">
          <p>Tentative</p>
        </div>
        <div className="data-columns">
          <p>Non Billable</p>
        </div>
        <div className="data-columns">
          <p>Off</p>
        </div>
        <div className="data-columns">
          <p>Other Time-Off</p>
        </div>
        <div className="data-columns">
          <p>Free</p>
        </div>
        <div className="side-columns">
          <p>Check</p>
        </div>
      </div>

      {userRows}
    </div>
  );
};

export default SatOverviewPage;
