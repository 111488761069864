import React, { useContext } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import Cross from "../../default_images/cross.svg";
import { useTranslation } from "react-i18next";
import MoveBookingRequest from "../requests/MoveBookingRequest";
import { PlannerContext } from "../context/PlannerContext";
import { useBookingsStore, useDateStore, useZoomStore } from "../../../stores/planner";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";
import { shallow } from "zustand/shallow";

const MoveQuestionDialog = ({
  open,
  setOpen,
  bookingMovingHash,
  bookingsEditingClicks,
  users,
  closeEditWindow,
  teams,
  teamId,
  usersRef,
  getUserIndex,
  plannerDispatcher,
}) => {
  const { t } = useTranslation();
  const { filteredUsers } = useContext(PlannerContext);
  const { bookings, setBookings } = useBookingsStore();
  const [date] = useDateStore((state) => [state.date], shallow);
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const moveBookingRequest = (booking) => {
    MoveBookingRequest(
      date,
      zoom,
      bookingMovingHash,
      bookings,
      setBookings,
      bookingsEditingClicks,
      users,
      closeEditWindow,
      teams,
      teamId,
      usersRef,
      filteredUsers,
      booking
    ).then((userIdsToUpdateRow) => {
      CancelMove(false);
      plannerDispatcher.afterCreationBookingAssignment(userIdsToUpdateRow.map((userId) => getUserIndex(userId)));
    });
  };

  const CancelMove = () => {
    setOpen(false);
  };

  return (
    <Dialog className="tb-default-dialog" open={open} onClose={CancelMove}>
      <DialogTitle>
        <p>{t("planning.move_from")}</p>
        <img src={Cross} onClick={CancelMove} />
      </DialogTitle>
      <DialogContent style={{ margin: "20px 0px" }}>
        <p>{`${t("planning.move_dialog").split("_")[0]} ${bookingsEditingClicks.length} ${
          t("planning.move_dialog").split("_")[1]
        }`}</p>
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton onClick={moveBookingRequest} text={t("submit")} />
        <TeambookOutlinedButton onClick={CancelMove} text={t("close")} />
      </DialogActions>
    </Dialog>
  );
};

export default MoveQuestionDialog;
