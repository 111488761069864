import { WhiteTooltip } from "../default_components/Tooltips";
import lsKeys from "../default_values/defaultKeys";

const AWS_URL = "https://d3pvelbecxpve1.cloudfront.net/assets";

export const TeambookIcon = ({
  name,
  color = "default",
  onClick = () => {},
  tooltipTitle,
  placement,
  style = {},
  size,
  theme = localStorage.getItem(lsKeys.THEME),
  ...props
}) => {
  const getTheme = () => theme || "light";

  const colorMapping = {
    light: {
      default: "dark_gray",
      alternative_default: "blue",
      blue: "blue",
      gray: "dark_gray",
      green: "green",
      red: "red",
      grouped_planner_add: "gray",
      onboarding_icon: "blue",
      icon_hover: "blue",
      darkblue: "blue",
      darkblue_new: "dark_blue",
      white: "white",
      grouped_add_user: "dark_gray",
    },
    dark: {
      default: "gray",
      alternative_default: "gray",
      blue: "dark_blue",
      gray: "gray",
      green: "green",
      red: "red",
      grouped_planner_add: "dark_gray",
      onboarding_icon: "gray",
      icon_hover: "white",
      darkblue: "gray",
      darkblue_new: "gray",
      white: "white",
      grouped_add_user: "white",
    },
  };

  const IMG_URL = `${AWS_URL}/${colorMapping[getTheme()][color]}/${name}.svg`;

  if (!tooltipTitle) {
    return (
      <img
        style={{
          ...style,
        }}
        src={IMG_URL}
        alt={name}
        onClick={onClick}
        {...props}
      />
    );
  }

  return (
    <WhiteTooltip maxWidth={400} title={tooltipTitle} placement={placement}>
      <img
        src={IMG_URL}
        style={{
          ...style,
        }}
        alt={name}
        onClick={onClick}
        {...props}
      />
    </WhiteTooltip>
  );
};
