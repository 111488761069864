import React from "react";
import { useTranslation } from "react-i18next";
import XLSExport from "../../../lib/XLSExport";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";

const ExportDataButton = ({
  fromDate,
  toDate,
  selectedProjects,
  selectedUsers,
  selectedBillability,
  selectedTeams,
  setEmptyOpened,
  exportType,
}) => {
  const { t } = useTranslation();

  const exportToCSV = () => {
    switch (exportType) {
      case "actuals":
        XLSExport.ExportActuals({
          team_ids: selectedTeams,
          start_date: fromDate,
          end_date: toDate,
          projects: selectedProjects,
          users: selectedUsers,
          billability: selectedBillability.value,
          setEmptyOpened: setEmptyOpened,
        });
        return;
      case "bookings":
        XLSExport.ExportBookings({
          team_ids: selectedTeams,
          start_date: fromDate,
          end_date: toDate,
          projects: selectedProjects,
          users: selectedUsers,
          billability: selectedBillability.value,
          setEmptyOpened: setEmptyOpened,
        });
        return;
      case "capacity":
        XLSExport.ExportCapacity({
          from: fromDate.startOf("month"),
          to: toDate,
          projects: selectedProjects,
          users: selectedUsers,
          billability: selectedBillability.value,
        });
        return;
      case "all":
        XLSExport.ExportBookingsAndLogs({
          team_ids: selectedTeams,
          start_date: fromDate,
          end_date: toDate,
          projects: selectedProjects,
          users: selectedUsers,
          billability: selectedBillability.value,
          setEmptyOpened: setEmptyOpened,
        });
        return;
    }
  };

  return <TeambookBlueButton text={t("download")} onClick={exportToCSV} color="primary" />;
};

export default ExportDataButton;
