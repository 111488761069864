import React from "react";
import "../../planner.scss";
import { DAY_VIEW_WIDTH, DAY_VIEW_START_HOUR } from "../../../../lib/PlannerUtils";
import { useBookingsCreatingStore } from "../../../../stores/planner";
import { shallow } from "zustand/shallow";

const DayViewRow = ({ user, mouseClickDayView, hour, index, isLastUser }) => {
  const [setDayViewStartTime] = useBookingsCreatingStore((state) => [state.setDayViewStartTime], shallow);

  const calculateStyle = () => {
    return {
      width: `${DAY_VIEW_WIDTH}px`,
      borderRight: hour !== 15 && "1px solid var(--stroke)",
      borderBottom: "1px solid var(--stroke)",
      borderBottomRightRadius: isLastUser && hour === 15 ? 7 : 0,
    };
  };

  return (
    <div
      style={calculateStyle()}
      onClick={() => {
        mouseClickDayView(index, hour);
        setDayViewStartTime(`${hour + DAY_VIEW_START_HOUR < 10 ? "0" : ""}${hour + DAY_VIEW_START_HOUR}:00` || "09:00");
      }}
    ></div>
  );
};

export default DayViewRow;
