import React, { useEffect, useState } from "react";
import SeparatorRow from "./SeparatorRow";
import { useTranslation } from "react-i18next";
import TagsBlock from "./TagsBlock";
import moment from "moment";
import { recalculateCapacityArray } from "../../../../../capacity_planning/lib/recalculateCapacityArray";
import { calculateTagsFilters } from "../../../../../capacity_planning/lib/calculateFilters";
import Api from "../../../../../../Api";
import XLSExport from "../../../../../../lib/XLSExport";
import { useTeambookFilter } from "../../../../../../stores/planner";
import DatesRow from "../../DatesRow";
import TagsSeparator from "./TagsSeparator";
import { DateTime } from "luxon";
import { useAccountStore } from "../../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const ReportingTags = ({ date, users, tags, projects, selectedViewType, setSplitBy }) => {
  const { filterValues, filterType } = useTeambookFilter();
  const { t } = useTranslation();

  const [nonTimeOffProjects, setNonTimeOffProjects] = useState([]);
  const [timeOffs, setTimeOffs] = useState([]);
  const [capacityReservations, setCapacityReservations] = useState([]);
  const [showedTags, setShowedTags] = useState(tags);
  const [showedProjects, setShowedProjects] = useState(projects);
  const [showPercentage, setShowPercentage] = useState(false);
  const [percentageFormat, setPercentageFormat] = useState("all");
  const [sortOrder, setSortOrder] = useState("a");
  const [sortedDate, setSortedDate] = useState(DateTime.now());
  const [sortType, setSortType] = useState("alphabetical"); //alphabetical || by_month

  useEffect(() => {
    Api.CapacityReservations.get({
      user_ids: showedTags.map((t) => t.tag_users).flat(),
      start_date: date.toISODate(),
      end_date: date.plus({ years: 2 }).toISODate(),
    }).then((res) => {
      setCapacityReservations(res.data);
    });
  }, [date, showedTags, projects]);

  const getTagReservation = (tag) => {
    return capacityReservations
      .filter((cr) => {
        const isoDate = DateTime.fromISO(cr.date);

        return (
          tag.tag_users.includes(cr.user_id) && isoDate.month === sortedDate.month && isoDate.year === sortedDate.year
        );
      })
      .reduce((prev, cur) => prev + cur.days_reserved, 0);
  };

  useEffect(() => {
    let sorted_tags = [...tags.filter((tag) => tag.tag_users.length > 0)];

    if (sortType === "alphabetical") {
      sorted_tags = [...sorted_tags].sort((a, b) => {
        if (sortOrder === "a") {
          return a.name > b.name ? 1 : -1;
        }

        if (sortOrder === "z") {
          return a.name > b.name ? -1 : 1;
        }
      });
    }

    if (sortType === "by_month") {
      sorted_tags = [...sorted_tags].sort((a, b) => {
        if (sortOrder === "a") {
          return getTagReservation(a) > getTagReservation(b) ? 1 : -1;
        }

        if (sortOrder === "z") {
          return getTagReservation(a) > getTagReservation(b) ? -1 : 1;
        }
      });
    }

    setShowedTags(sorted_tags);
  }, [tags, sortOrder, sortType, sortedDate]);

  useEffect(() => {
    setShowedTags(calculateTagsFilters(tags, filterValues, filterType, t));
  }, [filterValues, filterType, tags]);

  useEffect(() => {
    let projs = showedProjects
      .filter((p) => p.kind !== "time_off")
      .filter((p) => p.active && p.capacity_counted)
      .sort((x, y) => (x.name > y.name ? 1 : -1));
    setNonTimeOffProjects(projs);
    setTimeOffs(showedProjects.filter((p) => p.kind === "time_off").filter((p) => p.active && p.capacity_counted));
  }, [showedProjects]);

  const exportUsersData = () => {
    let exportArray = [
      ["", ...[...Array(selectedViewType)].map((x, i) => date.plus({ months: i }).toFormat("LLL yy"))],
    ];

    exportArray.push(...getExportData(showedTags, capacityReservations));

    XLSExport.exportCapacityReporting("Tags", exportArray);
  };

  const getExportData = (objects, reservations) => {
    let exportArray = [["Tags"]];

    objects.forEach((object) => {
      exportArray.push([
        object.name,
        ...summarizeCapacities(
          reservations.filter((cr) => object.tag_users.includes(cr.user_id)),
          object
        ),
      ]);
    });

    return exportArray;
  };

  const summarizeCapacities = (reservations, tag) => {
    if (showPercentage) {
      let percentageReserved = [];

      if (percentageFormat === "all") {
        percentageReserved = [...Array(selectedViewType)].map((x, i) => {
          const calculatedDate = date.plus({ months: i }).toISODate();

          return reservations
            .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
            .map((cr) => cr.days_reserved)
            .reduce((ps, a) => ps + a, 0);
        });
      } else if (percentageFormat === "billable") {
        percentageReserved = [...Array(selectedViewType)].map((x, i) => {
          const calculatedDate = date.plus({ months: i }).toISODate();

          return reservations
            .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
            .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "billable")
            .map((cr) => cr.days_reserved)
            .reduce((ps, a) => ps + a, 0);
        });
      } else if (percentageFormat === "non_billable") {
        percentageReserved = [...Array(selectedViewType)].map((x, i) => {
          const calculatedDate = date.plus({ months: i }).toISODate();

          return reservations
            .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
            .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "non_billable")
            .map((cr) => cr.days_reserved)
            .reduce((ps, a) => ps + a, 0);
        });
      } else if (percentageFormat === "time_off") {
        percentageReserved = [...Array(selectedViewType)].map((x, i) => {
          const calculatedDate = date.plus({ months: i }).toISODate();

          return reservations
            .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
            .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "time_off")
            .map((cr) => cr.days_reserved)
            .reduce((ps, a) => ps + a, 0);
        });
      }

      if (monthDuration(tag) === 0) {
        return percentageReserved.map((p) => `0%`);
      }

      return percentageReserved.map((p) => `${((p / monthDuration(tag)) * 100).toFixed(0)}%`);
    } else {
      return recalculateCapacityArray(date, reservations, selectedViewType);
    }
  };

  const monthDuration = (tag) => {
    let duration = 0;

    for (let i = 0; i < date.endOf("month").day; i += 1) {
      tag.tag_users.forEach((userId) => {
        const user = users.filter((u) => u.id === userId)[0];

        if (!user) {
          duration += 0;
        } else {
          const newDateWeekday = date.plus({ days: i }).weekday - 1;

          if (user.schedule[newDateWeekday][0] + user.schedule[newDateWeekday][2] > 0) {
            duration += 1;
          }
        }
      });
    }

    return duration;
  };

  return (
    <div className="reporting-capacity__component overflow-scrolled reporting-tags">
      <DatesRow
        date={date}
        exportData={exportUsersData}
        selectedViewType={selectedViewType}
        showPercentage={showPercentage}
        setShowPercentage={setShowPercentage}
        percentageFormat={percentageFormat}
        setPercentageFormat={setPercentageFormat}
        offsetLeft={30}
        showTotalColumn={true}
        splitBy="tags"
        type="reporting"
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortedDate={sortedDate}
        setSortedDate={setSortedDate}
        setSortType={setSortType}
        sortType={sortType}
        setSplitBy={setSplitBy}
      />

      <SeparatorRow
        sortData={true}
        subtext={t("planning.total_days")}
        name={""}
        date={date}
        selectedViewType={selectedViewType}
        reservations={capacityReservations}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
      />

      {showedTags.map((tag) => (
        <TagsBlock
          tag={tag}
          users={users}
          date={date}
          reservations={capacityReservations.filter((cr) => tag.tag_users.includes(cr.user_id))}
          projects={[...nonTimeOffProjects, ...timeOffs]}
          selectedViewType={selectedViewType}
          showPercentage={showPercentage}
          percentageFormat={percentageFormat}
        />
      ))}
    </div>
  );
};

export default ReportingTags;
