import React from "react";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import CommentIcon from "@mui/icons-material/Comment";
import HelpIcon from "@mui/icons-material/Help";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { ReactComponent as LocalBar } from "../default_images/LocalBar.svg";
import { ReactComponent as Leave } from "../default_images/Leave.svg";
import { ReactComponent as Hospital } from "../default_images/Sickness.svg";
import { ReactComponent as Training } from "../default_images/Training.svg";
import { ReactComponent as Home } from "../default_images/athome.svg";
import { ReactComponent as Plane } from "../default_images/plane.svg";
import { ReactComponent as Favourite } from "../default_images/heart.svg";
import { ReactComponent as Brightness } from "../default_images/sun.svg";
import { ReactComponent as CitiesSite } from "../default_images/clientsite_black.svg";

export const projectIconsQuantity = 9;

const getIcon = (Component, size, color, additionalStyles = {}) => (
  <Component style={{ fontSize: size, color: color || "#FFFFFF", ...additionalStyles }} />
);

export const defaultProjectIcons = (id, color, zoom) => {
  const size = !zoom ? "1.5rem" : zoom >= 90 ? "0.7rem" : "1.1rem";

  switch (id) {
    case -4:
      return getIcon(AccessTimeIcon, size, color, { margin: zoom >= 90 ? "2px 0 0 0" : "1px 0 0 0" });
    case -3:
      return getIcon(CommentIcon, size, color);
    case -2:
      return <CitiesSite style={{ width: size, height: size }} />;
    case -1:
      return getIcon(HelpIcon, size, color);
    case 1:
      return (
        <LocalBar
          strokeWidth="1px"
          style={{ width: size, height: size, fill: "transparent", stroke: color, strokeWidth: 1 }}
        />
      );
    case 2:
      return (
        <Hospital style={{ width: size, height: size, fill: "transparent", stroke: "transparent", strokeWidth: 1 }} />
      );
    case 3:
      return <Home style={{ width: size, height: size, fill: "transparent", stroke: color, strokeWidth: 1 }} />;
    case 4:
      return <Training style={{ width: size, height: size, strokeWidth: 1 }} />;
    case 5:
      return (
        <Plane style={{ width: size, height: size, fill: "transparent", stroke: "transparent", strokeWidth: 1 }} />
      );
    case 6:
      return <Favourite style={{ width: size, height: size, stroke: "transparent", color, strokeWidth: 1 }} />;
    case 7:
      return (
        <Leave style={{ width: size, height: size, fill: "transparent", stroke: "transparent", strokeWidth: 1 }} />
      );
    case 8:
      return (
        <Brightness style={{ width: size, height: size, fill: "transparent", stroke: "transparent", strokeWidth: 1 }} />
      );
    case 9:
      return getIcon(LocalCafeIcon, size, color);
    case 10:
      return getIcon(MoneyOffIcon, size, color);
    case 11:
      return <CitiesSite style={{ width: size, height: size, fill: "transparent", stroke: color, strokeWidth: 2 }} />;
    default:
      return getIcon(CancelPresentationIcon, size, color);
  }
};

export const plannerIcons = (id, color, zoom) => {
  const size = "16px";

  switch (id) {
    case -4:
      return getIcon(AccessTimeIcon, size, color, { margin: zoom >= 90 ? "2px 0 0 0" : "1px 0 0 0" });
    case -3:
      return getIcon(CommentIcon, size, color);
    case -2:
      return <CitiesSite style={{ width: size, height: size }} />;
    case -1:
      return getIcon(HelpIcon, size, color);
    case 1:
      return (
        <LocalBar
          strokeWidth="1px"
          style={{ width: size, height: size, fill: "transparent", stroke: color, strokeWidth: 1 }}
        />
      );
    case 2:
      return (
        <Hospital style={{ width: size, height: size, fill: "transparent", stroke: "transparent", strokeWidth: 1 }} />
      );
    case 3:
      return <Home style={{ width: size, height: size, fill: "transparent", stroke: color, strokeWidth: 1 }} />;
    case 4:
      return <Training style={{ width: size, height: size, strokeWidth: 1 }} />;
    case 5:
      return (
        <Plane style={{ width: size, height: size, fill: "transparent", stroke: "transparent", strokeWidth: 1 }} />
      );
    case 6:
      return <Favourite style={{ width: size, height: size, stroke: "transparent", color, strokeWidth: 1 }} />;
    case 7:
      return (
        <Leave style={{ width: size, height: size, fill: "transparent", stroke: "transparent", strokeWidth: 1 }} />
      );
    case 8:
      return (
        <Brightness style={{ width: size, height: size, fill: "transparent", stroke: "transparent", strokeWidth: 1 }} />
      );
    case 9:
      return getIcon(LocalCafeIcon, size, color);
    case 10:
      return getIcon(MoneyOffIcon, size, color);
    case 11:
      return <CitiesSite style={{ width: size, height: size, fill: "transparent", stroke: color, strokeWidth: 2 }} />;
    default:
      return getIcon(CancelPresentationIcon, size, color);
  }
};
