import React, { useState, useEffect } from "react";
import { getWeekNumber, MonthsOfTheYear } from "../../../lib/DateUtils";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import lsKeys from "../../default_values/defaultKeys";

const DateRow = ({ date }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [monthRows, setMonthRows] = useState([]);
  const weekDays = [
    t("planning.monday"),
    t("planning.tuesday"),
    t("planning.wednesday"),
    t("planning.thursday"),
    t("planning.friday"),
    t("planning.saturday"),
    t("planning.sunday"),
  ];

  const weekendsHidden = localStorage.getItem(lsKeys.WEEKENDS_HIDDEN_KEY) === "true";

  useEffect(() => {
    const newRows = [];

    const monthRow = [<div style={{ width: 125 }} />];

    for (let i = 0; i < 28; i++) {
      let newDate = date.plus({ day: i });
      let dateText = `${weekDays[newDate.weekday - 1]} ${newDate.day}`;
      const dayIsWeekend = (5 === i % 7 || (5 + 1) % 7 === i % 7) && weekendsHidden;
      const isBeginOfMonth = newDate.day === 1;

      if (newDate.day === 5 || newDate.day === 15 || newDate.day === 25) {
        monthRow.push(
          <div className="pdf-month-row__cell" style={{ width: dayIsWeekend ? 10 : 72 }}>
            <p style={{ position: "absolute" }}>{MonthsOfTheYear(t)[newDate.month - 1]}</p>
          </div>
        );
      } else {
        if (dayIsWeekend) {
          monthRow.push(
            <div
              className="pdf-month-row__cell"
              style={{
                width: isBeginOfMonth ? 19 : 20,
                borderLeft: isBeginOfMonth && "1px solid var(--stroke)",
              }}
            />
          );
        } else {
          monthRow.push(
            <div
              className="pdf-month-row__cell"
              style={{
                width: isBeginOfMonth ? 71 : 72,
                borderLeft: isBeginOfMonth && "1px solid var(--stroke)",
              }}
            />
          );
        }
      }

      if (dayIsWeekend) {
        newRows.push(<div className="date-row__weekend-cell" key={`date-cell-${newDate}`} />);
      } else {
        newRows.push(
          <div className={`date-cell-header-28 date-row__date-cell`}>
            <WhiteTooltip
              title={`${MonthsOfTheYear(t)[newDate.month - 1]}, ${t("planning.calendar_week")} - ${getWeekNumber(
                newDate.toJSDate()
              )}`}
              placement="top"
            >
              <p
                className="date-row__date-text"
                style={{
                  color: 5 === i % 7 || (5 + 1) % 7 === i % 7 ? "var(--accent-light)" : "var(--text-3)",
                }}
              >
                {dateText}
              </p>
            </WhiteTooltip>
          </div>
        );
      }
    }
    setMonthRows(monthRow);
    setRows(newRows);
  }, [date]);

  return (
    <>
      <div className="pdf-month-row">{monthRows}</div>
      <div className="date-row">
        <div className="date-row__splitter" />

        {rows}
      </div>
    </>
  );
};

export default DateRow;
