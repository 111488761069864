import { useTranslation } from "react-i18next";
import { memo, useState } from "react";
import { ClickAwayListener } from "@mui/base";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

export const TeambookGroupDropdown = memo(
  ({ options, value, onSelect, pageSplit, setReportingPage, reportingPage }) => {
    const [openedGroup, setOpenedGroup] = useState(value);
    const splitBy = pageSplit === "capacity_tag" ? "capacity_user" : pageSplit;
    const [selectedOption, setSelectedOption] = useState(
      options.find((option) => option.group_value === reportingPage)?.elements.find((value) => value.param === splitBy)
    );
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const closeDropdown = () => {
      setOpen(false);
    };

    return (
      <div
        className="reporting-type-button"
        onClick={(e) => {
          setOpen(true);
        }}
      >
        <TeambookIcon
          style={{
            width: 24,
            height: 24,
          }}
          name={selectedOption.icon}
          color="darkblue_new"
        />
        <p className="reporting-type-button__selected-option">
          {options.find((option) => option.group_value === value)?.group || t("dashboard.reporting")}
        </p>

        <TeambookIcon
          style={{
            transform: open ? "rotate(180deg)" : "unset",
          }}
          name={icons.ARROW_DOWN}
        />

        {open && (
          <ClickAwayListener
            onClickAway={() => {
              closeDropdown();
              setOpenedGroup(value);
            }}
          >
            <div className="reporting-type-dropdown">
              <div className="reporting-type-dropdown__links">
                {options.map((link, i) => {
                  const isGroupSelected = link.group_value === openedGroup;
                  return (
                    <div
                      className={`reporting-type-dropdown__link-wrapper ${
                        isGroupSelected ? "reporting-link__active" : "reporting-link__not_active"
                      }`}
                    >
                      <p
                        style={{
                          borderRadius:
                            i === 0
                              ? "10px 10px 0px 0px"
                              : i === options.length - 1 && openedGroup === null
                              ? "0px 0px 10px 10px"
                              : "unset",
                          color: "var(--text-2)",
                        }}
                        onClick={() => {
                          if (openedGroup === link.group_value) {
                            setOpenedGroup(value);
                          } else {
                            setOpenedGroup(link.group_value);
                          }
                        }}
                        className="reporting-type-dropdown__group-name"
                      >
                        {link.group}
                        <TeambookIcon name={isGroupSelected ? icons.ARROW_UP : icons.ARROW_DOWN} />
                      </p>
                      {isGroupSelected && (
                        <div className="reporting-type-dropdown__group">
                          {link.elements.map((val) => (
                            <div
                              onClick={(e) => {
                                onSelect(val.param);
                                setSelectedOption(val);
                                setReportingPage(link.group_value);
                                e.stopPropagation();
                                closeDropdown();
                              }}
                            >
                              <p
                                className={` ${
                                  val.param === splitBy && openedGroup === value
                                    ? "group-element__active"
                                    : "group-element__not_active"
                                }`}
                                style={{
                                  background: val.param === splitBy && openedGroup === value && "var(--stroke)",
                                }}
                              >
                                {val.icon && (
                                  <TeambookIcon
                                    style={{
                                      width: 18,
                                      height: 18,
                                    }}
                                    name={val.icon}
                                    color="darkblue_new"
                                  />
                                )}
                                {val.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </ClickAwayListener>
        )}
      </div>
    );
  }
);
