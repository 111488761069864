import React from "react";
import EditSettings from "./general_settings_form/EditSettings";
import TimeOffHolidaysSettings from "./time_off_holidays/TimeOffHolidaysSettings";
import ScheduleComponent from "./ScheduleComponent";
import DeleteAccountComponent from "./DeleteAccountComponent";
import SubscriptionComponent from "./subscrtiption/SubscriptionComponent";
import IntegrationComponent from "./integration/IntegrationComponent";
import TasksPayrollSettings from "./tasks_payroll/TasksPayrollSettings";
import CustomizationComponent from "./customization/CustomizationComponent";

const SettingsRightSide = ({ menuIndex, updateAccountRequest, deleteAccountRequest, users }) => {
  return (
    <div
      className="settings__right-side__container"
      style={{
        height: menuIndex === 0 ? "fit-content" : "unset",
        maxHeight: [1, 2].includes(menuIndex) ? "590px" : "700px",
      }}
    >
      {menuIndex === 0 ? <EditSettings /> : null}

      {menuIndex === 1 ? <TimeOffHolidaysSettings users={users} /> : null}

      {menuIndex === 2 ? <TasksPayrollSettings /> : null}

      {menuIndex === 3 ? <ScheduleComponent submitForm={updateAccountRequest} /> : null}

      {menuIndex === 4 ? <SubscriptionComponent /> : null}

      {menuIndex === 5 ? <IntegrationComponent /> : null}

      {menuIndex === 6 ? <CustomizationComponent /> : null}

      {menuIndex === 7 ? <DeleteAccountComponent submitForm={deleteAccountRequest} /> : null}
    </div>
  );
};

export default SettingsRightSide;
